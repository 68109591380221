import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import { adminService } from "../../../../configs/constants";

const roleURL = `${adminService}/access/getRoles`;
const applicationURL = `${adminService}/access/getApplications`;
const applicationRoles = `${adminService}/access/get-application-with-roles`;
const organizationList = `${adminService}/organization/organizationsDropDown`;
const permissionApplication = `${adminService}/access/getApplications`;
const permissionRoles = `${adminService}/access/get-application-with-roles`;

export const getpermissionApplications = () => async (dispatch) => {
  try {
    const {
      data: { result },
    } = await axios.get(`${permissionApplication}`);
    dispatch({
      type: action_types.GET_PERMISSION_APPLICATIONS,
      data: result,
    });
  } catch (e) {
    console.log({ e });
  }
};
const usersList = `${adminService}/admin/user`;
const usersApplicationRoles = `${adminService}/admin/userApplicationsAndRoles`;

export const getUsersList = (page = 1, search, userType) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(
        `${usersList}?page=${page}${userType && `&usertype=${userType}`}${
          search && `&search=${search}`
        }`
      );
      dispatch({
        type: action_types.GET_USERS,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getusersRolesApplication = (appID) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(`${usersApplicationRoles}/${appID}`);
      dispatch({
        type: action_types.GET_USERS_APPLICATION_ROLES,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getRoles = (page = 1, filterdata) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(
        `${roleURL}?perPage=50&page=${page}&${
          filterdata ? `search=${filterdata}` : ""
        }`
      );
      dispatch({
        type: action_types.GET_ROLES,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getApplication = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(applicationURL);
      dispatch({
        type: action_types.GET_APPLICATIONS,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getContentCategories = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(applicationRoles);
      dispatch({
        type: action_types.GET_APPLICATION_ROLES,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getOrganizationList = (page = 1) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(organizationList);
      dispatch({
        type: action_types.GET_ORGANIZATION_LIST,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};
