import React, { useState, useEffect } from "react";

import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import axios from "../../../../../utils/axiosInceptor";
import { getMaintenanceTypes } from "../../ducks/actions";
import { InputField, SelectField } from "../../../../atoms/FormElement";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { adminService } from "../../../../../configs/constants";
import { BsPlusCircle, BsArrowCounterclockwise } from "react-icons/bs";
import { Row, Col, Button, Form, Typography, message, Space, Spin } from "antd";

const defaultValues: any = {
  name: "",
  description: "",
  type: ""
};

const categoryType: any = [
  {
    value: 'residential',
    label: 'Residential'
  },
  {
    value: 'commercial',
    label: 'Commercial'
  },
  {
    value: 'internal',
    label: 'Internal'
  },
]
const { Paragraph } = Typography;

const AddMaintanence = (props) => {
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  const dispatch: any = useDispatch();

  useEffect(() => {
    reset();
    return () => {
      reset();
    };
  }, [props]);

  useEffect(() => {
    reset({ defaultValues });
    props?.setOnClose(false);
  }, [props?.onClose]);

  const onFinish = async (value) => {
    setLoader(true);
    setSubmited(true);
    try {
      let url = `${adminService}/maintenance/category`;
      await axios.post(
        url,
        { name: String(value.name), description: value.description,
        type : value.type.value },
        { headers: { application: 4 } }
      );
      reset();
      setLoader(false);
      setSubmited(false);
      message.success("Maintenance Type added successfully");
      dispatch(getMaintenanceTypes());
      props.setVisible(false);
    } catch (error) {
      reset();
      setLoader(false);
      setisError(false);
      setSubmited(false);
      message.error(error.response.data?.error || error.response.data.message);
    }
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          <Row gutter={24}>
            <Col span={8}>
              <SelectField
                isRequired={true}
                fieldname="type"
                label="Category type"
                control={control}
                validate={errors.type && "error"}
                validMessage={
                  errors.type && errors.type.message
                }
                // onChange={(e) => onChangeOrganzation(e)}
                iProps={{ placeholder: "Select category type"}}
                rules={{ required: "Please select category type" }}
                initValue=""
                selectOption={categoryType}
              />
            </Col>
            <Col span={8}>
              <InputField
                isRequired={true}
                fieldname="name"
                label="Add Category Title"
                control={control}
                rules={{
                  required: "Please enter category title",
                }}
                iProps={{ placeholder: "Plumbing" }}
                initValue=""
                validate={errors.name && "error"}
                validMessage={errors.name && errors.name.message}
              />
            </Col>
            
            <Col span={8}>
              <InputField
                isRequired={true}
                fieldname="description"
                label="Add Description"
                control={control}
                rules={{
                  required: "Please enter description",
                }}
                iProps={{ placeholder: "Description" }}
                initValue=""
                validate={errors.description && "error"}
                validMessage={errors.description && errors.description.message}
              />
            </Col>
          </Row>
          <Row gutter={24} justify="space-between">
            <Col>
              <Button
                className="ag-error theme_grey_btn"
                htmlType="button"
                onClick={() => {
                  reset({ defaultValues });
                  props.setVisible(false);
                }}
              >
                {<BsArrowCounterclockwise />} Back
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button theme_btn" htmlType="submit">
                {<BsPlusCircle />} {props.selected ? "Update" : "Add"}
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </Form>
  );
};

export default AddMaintanence;