import React, { useEffect, useState } from 'react';
import { Row, Col, Pagination, Card,message} from "antd";
import Grid from '../../atoms/Grid';

import { getOnlineReportListService } from './service';
import { dateByformat } from "../../../utils/dateHelper";
import { currencyFormat } from "../../../utils/helper";

import { Loader } from '../../atoms/Loader';



 const OnlinePayment = ({setExportReport,exportReport, setTotalRecord,startDate,endDate,totlaRecord,searchText ,searchingBy,orgId,paymentType}) => {



    const [page, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [paymentList, setPaymentList] = useState([]);

    const gridProps = {
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 200,
    };


    const pdcCol = [


        {
            headerName: "Oder Id",
            field: "orderId",
        },

        {
            headerName: "Bank Ref",
            field: "bankRefNo",
        },
        {
            headerName: "Payment Type",
            field: "description",
            cellRendererFramework: ({ data }) => {
                return `${convertToNormalCaseRemoveNumber(data.description)}`;
            }
        },
        {
            headerName: "Due Date",
            field: "dueDate",
            cellRendererFramework: ({ data }) => {
                return dateByformat(data.dueDate, "DD-MM-YYYY");
            },
        },
        {
            headerName: "Due Amount",
            field: "actualAmount",
            minWidth: 240,
            cellRendererFramework: ({ data }) => {
                return (<div className="annualRent">{currencyFormat(data?.actualAmount)}</div>)
            },
        },
        {
            headerName: "Property",
            field: "propertyName",
        },
        {
            headerName: "Business Unit",
            field: "businessUnitName",
            width: '240',
            // cellRendererFramework: ({ data }) => data?.propertyDetails?.unitNumber
        },
    
        {
            headerName: "Unit Code",
            field: "unitCode",
            width: '300'
        },



        {
            headerName: "Organization",
            field: "orgName",
           
        },
        {
            headerName: "Paid Date",
            field: "paymentDate",
            cellRendererFramework: ({ data }) => {
                return data?.paymentDate && dateByformat(data.paymentDate, "DD-MM-YYYY") ; //for bug 8458
            },
        },
        {
            headerName: "Gross Amount",
            field: "actualAmount",
            minWidth: 240,
            cellRendererFramework: ({ data }) => {
                return (<div className="annualRent">{currencyFormat(data?.amount)}</div>)
            },
        },
  
        {
            headerName: "Paid Amount",
            field: "totalAmount",
            minWidth: 240,
            cellRendererFramework: ({ data }) => {
      
                return (<div className="annualRent">{currencyFormat(Number(data?.totalAmount + data?.vat))}</div>)
            },
        },
        {
            headerName: "Processing Fee",
            field: "processing",
            minWidth: 240,
            cellRendererFramework: ({ data }) => {
      
                return (<div className="annualRent">{currencyFormat(Number(data?.processing))}</div>)
            },
        },
        
        {
            headerName: "Payment Status",
            field: "paymentStatus",
        },
        
    ];

    const convertToNormalCaseRemoveNumber = (input = '') => {
        // Remove numbers if the input consists of any number
        if (/^\d+$/.test(input)) {
            return '';
        }


        // Remove numbers from the input
        input = input.replace(/\d+/g, '');

        // Check if the input is in camel case
        if (input.indexOf('_') === -1 && input !== input.toLowerCase()) {
            return input.replace(/([a-z])([A-Z])/g, '$1 $2');
        }

        // Check if the input is in snake case
        if (input.indexOf('_') !== -1) {
            return input.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
        }

        return input.charAt(0).toUpperCase() + input.slice(1); // Already in normal case
    }

    const handlePagination = (pg) => {
        setPage(pg);
    };

    const getPaymetsData = async () => {
        setIsLoading(true);
        try{
        
            let payload = {
                "orgId":orgId ? orgId :'',
                "startDate":startDate ? startDate :"" ,
                "endDate":endDate ? endDate :"",
                "paymentType":paymentType ? paymentType:"",
                "orderId":searchingBy === 'orderId'  ? searchText:"",
                "bankRefNo":searchingBy === 'bankRef'  ? searchText:"",
                "unitCode":searchingBy === 'unitCode'  ? searchText:"",
                "propertyName":searchingBy === 'propertyName'  ? searchText:"",
                "page":page + 1,
                "limit":50,
                "exportData":exportReport ? exportReport:false, 
                 "totalCount":exportReport ? totlaRecord:""
            }
        const data = await getOnlineReportListService(payload);
        if (data?.code === 201 && data?.result?.data) {               
            setTotalRecord(data?.result?.totalCount);
            setPaymentList(data?.result?.data);
            setExportReport(false)
            setIsLoading(false);
        } else if(data?.code === 201 && !data?.result?.data){
            setExportReport(false);
            setIsLoading(false);
            message.success(data?.result); 
        }else{
            setIsLoading(false);
        }
       
        }catch(error){
          console.log(error)
          setIsLoading(false);
        }
        
    }


    useEffect(() => {
        getPaymetsData();
    }, [searchText,orgId,paymentType,exportReport,page,startDate])



    return (        
        <>
       {

        isLoading ?
        <Loader height={60}/>
        :

        <>
        
        <Col span={24}>
                <Pagination
                    className="ag-property-text-pagination"
                    total={totlaRecord}
                    showSizeChanger={false}
                    showTotal={(total, range) =>
                        `Showing ${range[0]}-${range[1]}-${total} payments`
                    }
                    defaultPageSize={50}
                    defaultCurrent={1}
                    current={page + 1}
                    onChange={(e) => handlePagination(e - 1)}
                />
            </Col>
            <Col span={24}>
                <Row gutter={[24, 24]} justify="end">
                    <Col span={24}>
                        <Card
                            bordered={false}
                            className="ag-nospace-body"
                        >
                            <Grid
                                // data={fakeResponse?.data}
                                data={paymentList}
                                columns={pdcCol}
                                pagination={false}
                                defaultSettings={gridProps}
                                noRowlabel="Payment"
                                fullPage={true}
                            />
                        </Card>
                    </Col>
                </Row>
            </Col>
        </>
       }
           
           
        </>
    )
}

export default React.memo(OnlinePayment);