import React, { useState, useEffect } from "react";

import { useForm } from "react-hook-form";
import axios from "../../../../utils/axiosInceptor";
import { adminService } from "../../../../configs/constants";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { InputField, InputCheckbox } from "../../../atoms/FormElement";
import { BsPlusCircle, BsArrowCounterclockwise } from "react-icons/bs";
import {
  Row,
  Col,
  Button,
  Form,
  Typography,
  message,
  Space,
  Spin,
  Checkbox,
} from "antd";
// import { CheckboxValueType } from "antd/es/checkbox/Group";

const { Paragraph } = Typography;

const defaultValues: any = {
  name: "",
  description: "",
};

export default (props) => {
  const { onUpdate, onchangeUpdate } = props;
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedPlanTypes, setSelectedPlanTypes] = useState([]);
  const [validatePlanType, setValidatePlanType] = useState(false);
  const {
    control,
    formState: { errors },
    reset,
    getValues,
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues,
  });

  const planTypes = ["Renewal", "New Lease", "Maintenance"];

  useEffect(() => {
    if (props.selected) {
      setValue("name", props.selected.name, { shouldValidate: true });
      setValue("perUnitPrice", props.selected.perUnitPrice, {
        shouldValidate: true,
      });
      setValue("perUserPrice", props.selected.perUserPrice, {
        shouldValidate: true,
      });
      setValue("isRenewal", props.selected.isRenewal);
      setValue("isNewLease", props.selected.isRenewal);
      setValue("isMaintenance", props.selected.isRenewal);
    } else {
      reset();
    }
  }, [props.selected]);

  const onFinish = (values) => {
    if (selectedPlanTypes.length === 0) {
      setValidatePlanType(true);
    } else {
      props.onSubmit();
      reset();
      setLoader(true);
      setSubmited(true);
      let postJson;

      if (props.selected?.subscriptionId) {
        postJson = {
          perUnitPrice: values.perUnitPrice,
          perUserPrice: values.perUserPrice,
          isRenewal: isPlanSelected("Renewal"),
          isNewLease: isPlanSelected("New Lease"),
          isMaintenance: isPlanSelected("Maintenance"),
        };

        const url = `${adminService}/plan/planId-${props.selected.planId}`;
        axios
          .patch(url, postJson, { headers: { application: 4 } })
          .then((response) => {
            setLoader(false);
            setSubmited(false);
            reset();
            message.success(response.data.message);
            onUpdate(!onchangeUpdate);
            props.onSubmit();
          })
          .catch((error) => {
            message.error(
              error.response.data?.error || error.response.data?.message
            );
            setLoader(false);
            setSubmited(false);
            reset();
          });
      } else {
        postJson = {
          name: values?.name.toUpperCase(),
          perUnitPrice: +values.perUnitPrice,
          perUserPrice: +values.perUserPrice,
          isRenewal: isPlanSelected("Renewal"),
          isNewLease: isPlanSelected("New Lease"),
          isMaintenance: isPlanSelected("Maintenance"),
        };

        const url = `${adminService}/plan`;
        axios
          .post(url, postJson, { headers: { application: 4 } })
          .then((response) => {
            setLoader(false);
            setSubmited(false);
            reset();
            message.success(response.data.message);
            onUpdate(!onchangeUpdate);
            props.onSubmit();
          })
          .catch((error) => {
            message.error(
              error.response.data?.error || error.response.data?.message
            );
            setLoader(false);
            setSubmited(false);
            reset();
          });
      }
    }
  };

  const onChange = (checkedValues: any) => {
    setValidatePlanType(checkedValues.length > 0 ? false : true);
    setSelectedPlanTypes(checkedValues);
  };

  const isPlanSelected = (type: string) => {
    return selectedPlanTypes.includes(type, 0);
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          <Row gutter={24}>
            <Col span={24}>
              <InputField
                isRequired={true}
                fieldname="name"
                label="Name"
                control={control}
                rules={{
                  required: "Please enter Plan name",
                }}
                iProps={{
                  placeholder: "Enter Plan name",
                  disabled: props?.selected?.subscriptionId ? true : false,
                  className: "text-uppercase",
                }}
                validate={errors.name && "error"}
                validMessage={errors.name && errors.name.message}
              />
            </Col>
            <Col span={12}>
              <InputField
                isRequired={true}
                fieldname="perUserPrice"
                label="Per User Price in AED"
                control={control}
                initValue=""
                validate={errors.perUserPrice && "error"}
                validMessage={
                  errors.perUserPrice && errors.perUserPrice.message
                }
                rules={{
                  minLength: {
                    value: 0,
                    message: "User price should be equal or greater than 0",
                  },
                  pattern: new RegExp("^[0-9]{1,10}$"),
                  required: "Please enter per user price",
                }}
                iProps={{
                  min: 0,
                  type: "number",
                  placeholder: "Enter per user price",
                  className: "apperance",
                }}
                // rules={{
                //   min: 0,
                //   required: "Please enter per user price",
                // }}
                // iProps={{
                //   min: 0,
                //   placeholder: "Enter per user price",
                //   type: "number",
                //   className: "apperance",
                // }}
              />
            </Col>

            <Col span={12}>
              <InputField
                isRequired={true}
                fieldname="perUnitPrice"
                label="Per Unit Price in AED"
                control={control}
                initValue=""
                validate={errors.perUnitPrice && "error"}
                validMessage={
                  errors.perUnitPrice && errors.perUnitPrice.message
                }
                rules={{
                  minLength: {
                    value: 0,
                    message: "Unit price should be equal or greater than 0",
                  },
                  pattern: new RegExp("^[0-9]{1,10}$"),
                  required: "Please enter per unit price",
                }}
                iProps={{
                  min: 0,
                  placeholder: "Enter per unit price",
                  type: "number",
                  disabled: false,
                  className: "apperance",
                }}
              />
            </Col>
            <Row gutter={24} className="plans_checkboxes">
              <Checkbox.Group
                options={planTypes}
                defaultValue={selectedPlanTypes}
                onChange={onChange}
              />
            </Row>
            <Row>
              {validatePlanType && (
                <div>
                  <p className="invalid-message">Select Appropriate plan type</p>
                </div>
              )}
            </Row>
          </Row>
          <Row gutter={24} justify="space-between">
            <Col>
              <Button
                className="ag-error theme_grey_btn"
                htmlType="button"
                onClick={() => {
                  !props.selected && reset();
                  props.onCancel();
                }}
              >
                {<BsArrowCounterclockwise />} Back
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button theme_btn" htmlType="submit">
                {<BsPlusCircle />} {props.selected ? "Update" : "Add"}
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </Form>
  );
};
