import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "antd";
import SectionHeader from "../../../../molecules/SectionHeader";
import PropertyList from "../../../../molecules/PropertyList";
import { getAllPropertiesList, downloadPropertyData } from "../../ducks/actions";
import moment from 'moment'
const showOption = [
  {
    value: "All",
    label: "All Properties",
  },
];

export default () => {
  const { id } = useParams();
  const dispatch:any = useDispatch();
  const [propertyCard, setPropertyCard] = useState<any>();
  const [propertiesFilter, setPropertyFilters] = useState({
    page: 0,
    limit: 6,
  })
  const [search, setSearch] = useState('')
  const [propertyData, setpropertyData] = useState([])
  const propertiesCardApi = useSelector(
    (state:any) => state.dashboard.allpropertiesList,
  );
  const fileData = useSelector((state:any) => state.dashboard.downloadFile)

  useEffect(() => {
    dispatch(getAllPropertiesList(id, propertiesFilter, search));
  }, [id, propertiesFilter]);

  useEffect(() => {
    setPropertyCard(propertiesCardApi)
  }, [propertiesCardApi])

  useEffect(() => {
    fileData && setpropertyData(fileData)
  }, [fileData])

  const generalFilter = (e) => {
    const filters = { ...propertiesFilter, page: e - 1 }
    setPropertyFilters(filters)
  }
  const onSearchHandler = (e) => {
    setSearch(e.target.value)
  }
  useEffect(() => {
    const filterData = () => {
      generalFilter(1)
    }
    const debouncer = setTimeout(() => {
      filterData();
    }, 1500);
    return () => {
      clearTimeout(debouncer);
    };
  }, [search])


  const onExport = (assetID) => {
    dispatch(downloadPropertyData(assetID))
  }
  return (
    <>
      <SectionHeader
        heading="All Properties"
        total={propertyCard?.count}
        showOption={showOption}
        search={onSearchHandler} />

      {propertyCard?.properties?.length > 0 ?
        <PropertyList
          data={propertyCard?.properties}
          count={propertyCard?.count}
          type="p"
          pagination={true}
          currentPage={propertiesFilter.page + 1}
          onpageChange={generalFilter}
          onExport={onExport}
          propertyData={propertyData}
        /> : <Row justify="center">'No Property Found'</Row>}
    </>
  );
};
