import React from "react";
import { Navigate } from "react-router-dom";
import { checkCookie, deleteCookie } from "../utils/helper";

function Protected({ children }) {
  const _clearAll = () => {
    deleteCookie("landlordUser");
    localStorage.clear();
    sessionStorage.clear();
    console.log("Clear");
  };

  const _authenticated = () => {
    const login = JSON.parse(localStorage.getItem("login-response"));
    try {
      const { exp } = login;
      if (Date.now() >= exp * 1000) {
        _clearAll();
        return false;
      }
    } catch (err) {
      _clearAll();
      return false;
    }

    return true;
  };

  if (!_authenticated()) {
    return <Navigate to="/login" replace />;
  }
  return children;
}
export default Protected;
