import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import { adminService } from "../../../../configs/constants";

const configUrl = `${adminService}/configData`;
const roleURL = `${adminService}/roles/list`;
const roleMaster = `${adminService}/roles/list`;
const applicationURL = `${adminService}/access/getApplications`;
const applicationRoles = `${adminService}/access/get-application-with-roles`;
const organizationList = `${adminService}/organization/organizationsDropDown`;
const permissionApplication = `${adminService}/roles`;
const permissionRoles = `${adminService}/access/get-application-with-roles`;
const permissionByRoleId = `${adminService}/roles/permission`;

export const getPermissionByRoleId = (roleId: string) => async (dispatch) => {
  try {
    const {
      data: { result },
    } = await axios.get(`${permissionByRoleId}/${roleId}`);
    dispatch({
      type: action_types.GET_PERMISSION_BY_ROLE_ID,
      data: result,
    });
  } catch (e) {
    console.log({ e });
  }
};

export const getpermissionApplications = () => async (dispatch) => {
  try {
    const {
      data: { result },
    } = await axios.get(`${permissionApplication}`);
    dispatch({
      type: action_types.GET_PERMISSION_APPLICATIONS,
      data: result,
    });
  } catch (e) {
    console.log({ e });
  }
};

const usersList = `${adminService}/admin/users`;
const usersApplicationRoles = `${adminService}`;

export const getUsersList = (id, page = 1, search) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(
        `${usersList}?page=${page}&limit=50&roleType=${id}${
          search && `&search=${search}`
        }`
      );
      dispatch({
        type: action_types.GET_USERS,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getusersRolesApplication = (appID) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(`${usersApplicationRoles}/${appID}`);
      dispatch({
        type: action_types.GET_USERS_APPLICATION_ROLES,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getConfigData = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(`${configUrl}?key=appPortalList`);
      dispatch({
        type: action_types.GET_CONFIG,
        data: result,
      });
    } catch (e) {}
  };
};

export const getRolesByAppName = (appName: string) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(`${roleURL}?appName=${appName}`);
      // console.log("Result--->", result);
      dispatch({
        type: action_types.GET_ROLES_BY_APP_NAME,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getRoles = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(`${roleURL}`);
      // console.log("Result--->", result);
      dispatch({
        type: action_types.GET_ROLES,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getAllRoles = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(`${roleMaster}`);
      dispatch({
        type: action_types.GET_ALL_ROLES,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getApplication = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(applicationURL);
      dispatch({
        type: action_types.GET_APPLICATIONS,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getContentCategories = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(applicationRoles);
      dispatch({
        type: action_types.GET_APPLICATION_ROLES,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getOrganizationList = (page = 1) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(organizationList);
      dispatch({
        type: action_types.GET_ORGANIZATION_LIST,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};
