export const ORGANIZATIONS = "ORGANIZATIONS";
export const ALL_ORGANIZATIONS = "ALL_ORGANIZATIONS";

// export const ORGANIZATIONS_DROP = "ORGANIZATIONS_DROP";
export const SUBSCRIPTIONS = "SUBSCRIPTIONS";
export const GET_BUSINESS_UNITS = "GET_BUSINESS_UNITS";

export const GET_NEW_TICKETS = "GET_NEW_TICKETS";

export const GET_INPROGRESS_TICKETS = "GET_INPROGRESS_TICKETS";
export const GET_COMPLETE_TICKETS = "GET_COMPLETE_TICKETS";
export const GET_NEW_TICKETS_COUNT = "GET_NEW_TICKETS_COUNT";
export const GET_INROGRESS_TICKETS_COUNT = "GET_INROGRESS_TICKETS_COUNT";
export const GET_CLOSED_TICKETS_COUNT = "GET_CLOSED_TICKETS_COUNT";
export const GET_TECHNICIANS = "GET_TECHNICIANS";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_NEWTICKET_STATUS = "GET_NEWTICKET_STATUS";
export const GET_INPRGTICKET_STATUS = "GET_INPRGTICKET_STATUS";
export const GET_CLOSED_STATUS = "GET_CLOSED_STATUS";
export const TICKETS_REVIEWS = "TICKETS_REVIEWS";
export const GET_BUSINESS_UNIT_TYPES = "GET_BUSINESS_UNIT_TYPES";
