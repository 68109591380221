import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Pagination,
  Row,
  Col,
  Input,
  Tabs,
  Button,
  Typography,
  Select,
} from "antd";
import axios from "axios";
import { SearchOutlined } from "@ant-design/icons";
import {
  getAssignedList,
  getUnAssignedList,
  getBuildings,
} from "./ducks/actions";
import Grid from "../../atoms/Grid/";
import AddBuilding from "./component/AddBuilding";
import { popupConfirm, Popup } from "../../atoms/Popup";
import DashboardLayout from "../../molecules/DashboardLayout";
import SectionHeader from "../../molecules/SectionHeader";
import AddFacilityManager from "./component/AddFacilityManager";
import { adminService } from "../../../configs/constants";

const { TabPane } = Tabs;
const { Title } = Typography;

const FacilityManager = () => {
  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [searchText, setSearchText] = useState();
  const [tabKey, setTabkey] = useState("1");
  const [page, setPage] = useState(1);
  const [IDs, setIDs] = useState(1);
  const [page2, setPage2] = useState(1);
  const [assignIDs, setAssignIDs] = useState(1);
  const [facilityRowData, setFacilityRowData] = useState({});
  const [onClose, setOnClose] = useState(false);
  const dispatch:any = useDispatch();
  const facilityList = useSelector((state:any) => state.facility.assignedList);
  const unassignedList = useSelector((state:any) => state.facility.unassignedList);
  const unassignedCount = useSelector(
    (state:any) => state.facility.unassignedCount,
  );

  const buildings = useSelector((state:any) => state.facility.buildings);
  const assignedCount = useSelector((state:any) => state.facility.assignedCount);

  const getList = () => {
    dispatch(getAssignedList());
    dispatch(getUnAssignedList());
    dispatch(getBuildings());
  };

  useEffect(() => {
    getList();
  }, []);

  const propertiesComponent = (params) => {
    console.log(params);
    return (
      <Select
        className="Sentence active-select ag-blue"
        placeholder="Select"
        style={{ width: 170 }}
        defaultActiveFirstOption={true}
        defaultValue={params?.data?.buildings[0]?.name}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Button
              type="link"
              className="fontWeight600 pl-1 ag-green"
              onClick={() => handleBuilding(params.data)}
            >
              + Add a Building
            </Button>
          </>
        )}
      >
        {
          <React.Fragment>
            {params?.data?.buildings.map((building, distinct) => {
              return (
                <Select.Option
                  key={`${distinct}`}
                  value={building?.id}
                  className="Sentence active-select"
                >
                  {building?.name}
                </Select.Option>
              );
            })}
          </React.Fragment>
        }
      </Select>
    );
  };

  const unassignCol = [
    {
      headerName: "Id",
      valueGetter: `${IDs}+node.rowIndex`,
      minWidth: 40,
      maxWidth: 80,
      pinned: "left",
    },
    { headerName: "Name", field: "name" },
    { headerName: "Email Address", field: "email" },
    { headerName: "Mobile Number", field: "mobile_no" },
    {
      headerName: "Building(s)",
      field: "buildings",
      cellRendererFramework: propertiesComponent,
    },
    {
      maxWidth: 120,
      headerName: "Status",
      field: "status",
      cellRendererFramework: statusRender,
    },
  ];
  const assignedCol = [
    {
      headerName: "Id",
      valueGetter: `${assignIDs}+node.rowIndex`,
      minWidth: 40,
      maxWidth: 80,
      pinned: "left",

    },
    { headerName: "Name", field: "name" },
    { headerName: "Email Address", field: "email" },
    { headerName: "Mobile Number", field: "mobile_no" },
    {
      headerName: "Building(s)",
      field: "buildings",
      cellRendererFramework: propertiesComponent,
    },
    {
      headerName: "Status",
      field: "status",
      maxWidth: 100,
      cellRendererFramework: statusRenderAssigned,
    },
  ];

  const fetchData = (e) => {
    if (e >= 2) {
      if (e > page) {
        setIDs((e - 1) * 50 + 1);
        setPage(e);
      } else if (e < page) {
        setIDs((e - 1) * 50 + 1);
        setPage(e);
      }
    } else {
      setIDs(e);
      setPage(e);
    }

    dispatch(getUnAssignedList(e));
  };

  const fetchPagData = (e) => {
    if (e >= 2) {
      if (e > page2) {
        setAssignIDs((e - 1) * 50 + 1);
        setPage2(e);
      } else if (e < page2) {
        setAssignIDs((e - 1) * 50 + 1);
        setPage2(e);
      }
    } else {
      setAssignIDs(e);
      setPage2(e);
    }
    dispatch(getAssignedList(e));
  };
  const handleBuilding = (data) => {
    setVisible(true);
    setTrigger(1);
    setFacilityRowData(data);
  };

  function statusRender(params) {
    const check = params.data.application_user_status[0]?.is_active;
    return (
      <Button
        type="link"
        className="p-0"
        onClick={() => approveFacilityManager(params)}
        style={{ color: check ? "#1C5D3B" : "#FB7171" }}
      >
        {check ? "Active" : "In Active"}
      </Button>
    );
  }

  function statusRenderAssigned(params) {
    const check = params.data.application_user_status[0]?.is_active;
    return (
      <Button
        type="link"
        className="p-0"
        onClick={() => approveFacilityManager(params)}
        style={{ color: check ? "#1C5D3B" : "#FB7171" }}
      >
        {check ? "Active" : "In Active"}
      </Button>
    );
  }

  const approveFacilityManager = (item: any) => {
    return popupConfirm({
      title: `Would you like to change the status of ${item.data.name} as a Facility Manager?`,
      cancelText: "Back",
      okText: "Confirm",
      okType: "danger",
      onOk() {
        const url = `${adminService}/admin/updateUserActiveStatus`;
        const check = item.data.application_user_status[0]?.is_active;
        try {
          axios
            .put(
              url,
              {
                id: item.data.application_user_status[0]?.id,
                isActive: check ? false : true,
              },
              { headers: { application: 4 } },
            )
            .then((res) => {
              getList();
            });
        } catch (e) {
          console.log(e);
        }
      },
    });
  };

  const searchField = (
    <Input
      type="text"
      onChange={(e) => onSearch(e)}
      placeholder=""
      suffix={<SearchOutlined />}
    />
  );

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };

  const popup = [
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Add New Facility Manager
        </Title>
      ),
      content: (
        <AddFacilityManager
          page={page}
          onClose={onClose}
          setOnClose={setOnClose}
          onCancel={() => setVisible(false)}
          buildings={buildings.map((index) => ({
            label: index.name,
            value: index.id,
          }))}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Assign a Building
        </Title>
      ),
      content: (
        <AddBuilding
          page={page}
          partyData={facilityRowData}
          buildings={buildings.map((index) => ({
            label: index.name,
            value: index.id,
          }))}
          id={facilityRowData}
          onCancel={() => setVisible(false)}
        />
      ),
      width: 650,
    },
  ];

  const onCancel = () => {
    setOnClose(true);
    setVisible(false);
  };
  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => onCancel(),
  };

  return (
    <>
      <DashboardLayout>
        <SectionHeader
          heading="Facility Managers"
          total={tabKey === "2" ? assignedCount : unassignedCount}
        //addNewAction={addFacility}
        />

        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Tabs
              defaultActiveKey={tabKey}
              onChange={(key) => setTabkey(key)}
              tabBarExtraContent={searchField}
              tabBarGutter={48}
              className="ag-property-status"
            >
              <TabPane tab="Unassigned" key="1">
                {unassignedList && (
                  <>
                    <Grid
                      data={unassignedList}
                      columns={unassignCol}
                      filterGrid={searchText}
                      pagination={false}
                    />
                    <Row justify="end" className="mt-1">
                      <Pagination
                        className="ag-property-text-pagination"
                        current={page}
                        total={unassignedCount && unassignedCount}
                        onChange={fetchData}
                        showSizeChanger={false}
                        pageSize={50}
                      />
                    </Row>
                  </>
                )}
              </TabPane>
              <TabPane tab="Assigned" key="2">
                {facilityList ? (
                  <>
                    <Grid
                      data={facilityList}
                      columns={assignedCol}
                      filterGrid={searchText}
                      pagination={false}
                    />
                    <Row justify="end" className="mt-1">
                      <Pagination
                        className="ag-property-text-pagination"
                        current={page2}
                        total={assignedCount && assignedCount}
                        onChange={fetchPagData}
                        showSizeChanger={false}
                        pageSize={50}
                      />
                    </Row>
                  </>
                ) : (
                  <h1>no data</h1>
                )}
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </DashboardLayout>
      <Popup {...popupProps} />
    </>
  );
};

export default FacilityManager;
