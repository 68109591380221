import React, { useState, useEffect } from "react";

import { Popup } from "../../atoms/Popup";
import AddUser from "./Components/AddUser";
import { useParams } from "react-router-dom";
import { getUsersList } from "./ducks/actions";
import { EditOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import SectionHeader from "../../molecules/SectionHeader";
import DashboardLayout from "../../molecules/DashboardLayout";
import { Col, Row, Tag, Space, Table, Button, Typography } from "antd";

const { Title } = Typography;
var _ = require("lodash");

const Users = (props) => {
  const usersList = useSelector((state: any) => state.users.usersData);
  const businessUnitTypes = useSelector(
    (state: any) => state.users.businessUnitTypes
  );

  const params = useParams();
  const dispatch: any = useDispatch();
  const [page, setPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [onClose, setOnClose] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [popUpData, setPopUpData] = useState({});
  const [updates, setUpates] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(getUsersList(page, search, params?.type));
  }, []);

  useEffect(() => {
    const filterData = () => {
      setPage(1);
      dispatch(getUsersList(page, search, params?.type));
    };

    const debouncer = setTimeout(() => {
      filterData();
    }, 1500);
    return () => {
      clearTimeout(debouncer);
    };
  }, [search]);

  const column = [
    { title: "Name", dataIndex: "name", key: "name", width: 220 },
    {
      title: "Role(s)",
      dataIndex: "roles",
      key: "role",
      width: 220,
      render: (_, { usertype }) => (
        <>
          {usertype.map((tag) => {
            return (
              <Tag className="tag-sm" key={tag}>
                {tag}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Organization",
      dataIndex: "organisationName",
      key: "org",
      width: 220,
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      width: 220,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 100,
      render: (text, record) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => updateUser(record)}
              className="p-0 ag-success"
            >
              <div className="Simple-tooltip">
                <EditOutlined />
                <section className="tt-content">Edit</section>
              </div>
            </Button>
          </Space>
        );
      },
    },
  ];

  const onAdd = () => {
    setVisible(false);
    setPopUpData({});
    dispatch(getUsersList(page, search, params?.type));
  };

  const popup = [
    {
      title: (
        <Title level={3} className="m-0">
          Add User
        </Title>
      ),
      content: (
        <AddUser
          onSubmit={onAdd}
          onCancel={() => setVisible(false)}
          onUpdate={setUpates}
          onchangeUpdate={updates}
          editMode={false}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="m-0">
          Edit User
        </Title>
      ),
      content: (
        <AddUser
          data={popUpData}
          onSubmit={onAdd}
          onCancel={() => setVisible(false)}
          onUpdate={setUpates}
          onchangeUpdate={updates}
          editMode={true}
        />
      ),
      width: 650,
    },
  ];

  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => onCancel(),
  };

  const onCancel = () => {
    setOnClose(true);
    setVisible(false);
    setPopUpData({});
  };

  const updateUser = (e) => {
    setPopUpData(e);
    setTrigger(1);
    setVisible(true);
  };

  const addNewUser = () => {
    setVisible(true);
    setTrigger(0);
  };

  const onTopSearch = (e) => {
    setSearch(e.target.value);
  };

  const _handleUsersList = (data: any) => {
    let newArray = [];
    if (params.type && data?.length > 0) {
      newArray = data?.filter((item: any) => {
        return item.usertype.includes(params.type);
      });
    } else newArray = data;

    return newArray || data;
  };

  return (
    <>
      <DashboardLayout>
        <SectionHeader
          heading="Users"
          addNewAction={addNewUser}
          search={onTopSearch}
          noborder={false}
        />

        {/* <Row justify="end" className="mt-1 mb-1"></Row> */}
        <Row gutter={20}>
          <Col span={24}>
            <Table
              className="custom-table org_table_wrap"
              columns={column}
              rowKey={(record, index) => index}
              pagination={{
                current: page,
                pageSize: usersList?.perPage ?? 10,
                showSizeChanger: false,
                className: "ag-property-text-pagination mb-1",
                position: ["topLeft"],
                onChange: (e) => {
                  setPage(e);
                  dispatch(getUsersList(e, search, params?.type));
                },
                total: usersList?.totalCount,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} Users`,
              }}
              dataSource={_handleUsersList(usersList?.data)}
              scroll={{ y: 500 }}
            />
          </Col>
        </Row>
      </DashboardLayout>
      <Popup {...popupProps} />
    </>
  );
};

export default Users;
