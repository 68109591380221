import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Typography, message, Space, Spin } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { InputField, SelectField } from "../../../atoms/FormElement";
import { adminService } from "../../../../configs/constants";
import axios from "../../../../utils/axiosInceptor";
import {
  getAssignedList,
  getUnAssignedList,
  getBuildings,
} from "../ducks/actions";

const defaultValues: any = {
  name: "",
  email_address: "",
  phone_number: "",
  properties: [],
};

const { Paragraph } = Typography;

const AddSupervisor = (props) => {
  const dispatch:any = useDispatch();
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [detail, setDetail]: any = useState()
  const { control, formState: { errors }, reset, handleSubmit, setError, setValue, watch, register } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues
  });

  useEffect(() => {
    dispatch(getBuildings());
  }, []);

  const resetForm = () => {
    reset({ defaultValues });
    props?.setOnClose(false)
  }

  useEffect(() => {
    resetForm()
  }, [props?.onClose])

  const onFinish = async (values) => {
    const { name, email_address, properties, phone_number } = values;
    if (values.properties.length == 0) {
      setError("properties", {
        type: "required",
        message: "Please select buildings"
      });

    } else {

      setLoader(true);
      setSubmited(true);

      const postJson = {
        name,
        email_address,
        phone_number,
        assets: properties.map((item) => item.value),
      };

      setDetail({
        name,
        email_address,
        phone_number: phone_number.slice(3, 3) + phone_number.slice(4),
        assets: properties
      })
      const url = `${adminService}/admin/createSupervisor`;
      try {
        await axios.post(url, postJson, { headers: { application: 4 } });
        setTimeout(() => {
          setLoader(false);
          setSubmited(false);
          reset();
          message.success("Supervisor added successfully");
          dispatch(getAssignedList(props.page));
          dispatch(getUnAssignedList(props.page));
          props.onCancel();
        }, 3000);
      } catch (e) {
        setLoader(false);
        setisError(true);
        const { response } = e;
        setErrorMessage(response?.data?.message);
      }
    }
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          <Row gutter={24}>
            <Col span={12}>
              <InputField
                isRequired={true}
                fieldname="name"
                label="Name"
                control={control}
                rules={{
                  required: "Please enter Name",
                  maxLength: {
                    value: 50,
                    message:
                      "Please enter characters less then 50",
                  },
                }}
                iProps={{ placeholder: "Ahmed Moustapha", maxLength: "50" }}
                initValue=""
                validate={errors.name && "error"}
                validMessage={errors.name && errors.name.message}
                valueGot={detail?.name}
                setValue={setValue}
              />
            </Col>
            <Col span={12}>
              <InputField
                isRequired={true}
                fieldname="email_address"
                label="Email Address"
                control={control}
                rules={{
                  pattern: {
                    value: /(?=^.{1,50}$)^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    message: "Enter valid Email Address",
                  },
                  required: "Please enter Email Address",
                }}
                iProps={{ placeholder: "ahmed.moustapha@gmail.com" }}
                initValue=""
                validate={errors.email_address && "error"}
                validMessage={
                  errors.email_address && errors.email_address.message
                }
                valueGot={detail?.email_address}
                setValue={setValue}
              />
            </Col>
            <Col span={12} className="mobNumber">
              <InputField
                isRequired={true}
                maxLength={9}
                label="Phone Number"
                fieldname="phone_number"
                control={control}
                validate={errors.phone_number && "error"}
                validMessage={
                  errors.phone_number && errors.phone_number.message
                }
                initValue=""
                rules={{
                  minLength: {
                    value: 9,
                    message:
                      "Please enter valid mobile number, 9 digits required",
                  },
                  maxLength: {
                    value: 9,
                    message:
                      "Please enter valid mobile number, 9 digits required",
                  },
                  required: "Please enter Mobile No.",
                  setValueAs: (value) => `+971${value}`,
                }}
                iProps={{
                  placeholder: "Add Mobile Number",
                  addonBefore: "+971",
                  type: "number",
                }}
                valueGot={detail?.phone_number}
                setValue={setValue}
              />
            </Col>
            <Col span={12}>
              <SelectField
                isRequired={true}
                fieldname="properties"
                label="Building(s)"
                control={control}
                iProps={{ placeholder: "Select buildings", isMulti: true }}
                rules={{ required: "Please select buildings" }}
                selectOption={props.buildings}
                validate={errors.properties && "error"}
                validMessage={errors.properties && errors.properties.message}
                valueGot={detail?.assets}
                setValue={setValue}
              />
            </Col>
          </Row>
          <Row gutter={24} justify="space-between">
            <Col>
              <Button
                className="ag-gray-button-outline"
                htmlType="button"
                onClick={() => {
                  reset({ defaultValues });
                  props.onCancel();
                }}
              >
                Back
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button" htmlType="submit">
                Add
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    reset({ defaultValues });
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </Form>
  );
};

export default AddSupervisor;
