import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Typography, message, Space, Spin } from "antd";
import { useForm } from "react-hook-form";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { InputField, SelectField } from "../../../../atoms/FormElement";
import { adminService } from "../../../../../configs/constants";
import axios from "../../../../../utils/axiosInceptor";
import {
  getBusinessUnits,
  getAllOrganizations,
} from "../../../Organizations/ducks/actions";
import { getRolesByAppName } from "../../../RoleAndAccess/ducks/actions";
import { BsPlusCircle, BsArrowCounterclockwise } from "react-icons/bs";
const { Paragraph } = Typography;

var _ = require("lodash");

const defaultValues = {
  name: "",
  email: "",
  mobile: "",
  organisation: "",
  businessUnit: [],
  roles: [],
};

const user = {
  application: "",
  role: "",
};

const AddUser = (props) => {
  const dispatch: any = useDispatch();
  const organizationList = useSelector(
    (state: any) => state.organization.getOrganizationsDrop
  );
  const businessUnitList = useSelector(
    (state: any) => state.organization.getBusinessUnitDrops
  );

  const { onUpdate, onchangeUpdate } = props;

  const rolesList = useSelector((state: any) => state.roles.rolesByAppName);

  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [formdefault, setFormdefault] = useState(defaultValues);
  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
    register,
    trigger,
    watch,
    setValue,
  } = useForm();

  const onChangeOrganzation = (e) => {
    if (e && e.value) dispatch(getBusinessUnits(e.value));
  };

  useEffect(() => {
    return () => {
      reset();
    };
  }, [props]);

  useEffect(() => {
    if (businessUnitList?.length == 0) {
      setValue("businessUnit", "");
    }
  }, [businessUnitList]);

  useEffect(() => {
    dispatch(getAllOrganizations());
  }, []);

  useEffect(() => {
    if (rolesList.length == 0)
      dispatch(getRolesByAppName("businessOwnerPortal"));
  }, [rolesList]);

  useEffect(() => {
    if (props.data) {
      const selectedOrganization = organizationList.filter(
        (item) => item.value == props.data.organisationId
      )[0]; //correct approach for selected change for others too

      const selectedRoles = rolesList.filter((item) =>
        _.includes(props.data.usertype, item.label)
      ); //correct approach for selected change for others too

      setValue("name", props.data.name, { shouldValidate: true });
      setValue("email", props.data.email, { shouldValidate: true });
      setValue("mobile", props.data.mobile, { shouldValidate: true });
      setValue("organisation", selectedOrganization, { shouldValidate: true });
      setValue("roles", selectedRoles, { shouldValidate: true });

      if (selectedOrganization) {
        dispatch(getBusinessUnits(selectedOrganization.value));
      }
    } else {
      reset();
    }
  }, [props.data, organizationList, rolesList, props]);

  const onFinish = async (values) => {
    reset();
    setLoader(true);
    setSubmited(true);

    let postJson = {};
    if (props.editMode == true && props.data) {
      postJson = {
        name: values.name,
        orgId: values.organisation.value,
        roles: _.map(values.roles, (item) => item.value),
      };

      const url = `${adminService}/admin/user/${props.data.userId}`;

      axios
        .patch(url, postJson, { headers: { application: 4 } })
        .then((response) => {
          console.log("RPEPN", response);
          let { data } = response;
          setLoader(false);
          setSubmited(false);
          reset();
          message.success(data?.message);
          onUpdate(!onchangeUpdate);
          // props.popUpClose(false);
          props.onSubmit();
        })
        .catch((error) => {
          console.log("RPEPN", error);
          message.error(error.response.data.error);
          setLoader(false);
          setSubmited(false);
          reset();
        });
    } else {
      postJson = {
        name: values.name,
        email: values.email,
        mobile: values.mobile,
        orgId: values.organisation.value,
        // businessUnit: _.map(values.businessUnit, (item) => item.value),
        roles: _.map(values.roles, (item) => item.value),
      };
      console.log("post", postJson);
      const url = `${adminService}/admin/user`;

      axios
        .post(url, postJson, { headers: { application: 4 } })
        .then((response) => {
          let { data } = response;
          setLoader(false);
          setSubmited(false);
          reset();
          message.success(data?.message);
          onUpdate(!onchangeUpdate);
          props.onSubmit();
        })
        .catch((error) => {
          message.error(error.response.data.error);
          setLoader(false);
          setSubmited(false);
          reset();
        });
    }
  };

  return (
    <>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        {loader && !isError && (
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Space
                size={24}
                className="w-100 text-center"
                direction="vertical"
              >
                <Spin size="large" className="ag-fontSize32" />
              </Space>
            </Col>
          </Row>
        )}
        {!submited ? (
          <>
            <Row gutter={24}>
              <Col span={12}>
                <InputField
                  isRequired={true}
                  fieldname="name"
                  label="Name"
                  control={control}
                  rules={{
                    required: "Please enter full name",
                  }}
                  iProps={{ placeholder: "Enter Full Name" }}
                  initValue=""
                  validate={errors.name && "error"}
                  validMessage={errors.name && errors.name.message}
                />
              </Col>
              <Col span={12}>
                <InputField
                  isRequired={true}
                  fieldname="email"
                  label="Email"
                  control={control}
                  rules={{
                    pattern: {
                      value: /(?=^.{1,50}$)^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: "Enter valid Email Address",
                    },
                    required: "Please enter Email Address",
                  }}
                  iProps={{
                    placeholder: "user@gmail.com",
                    disabled: props.editMode,
                  }}
                  initValue=""
                  validate={errors.email && "error"}
                  validMessage={errors.email && errors.email.message}
                />
              </Col>
              <Col span={12}>
                <InputField
                  isRequired={true}
                  label="Phone"
                  fieldname="mobile"
                  control={control}
                  validate={errors.mobile && "error"}
                  validMessage={errors.mobile && errors.mobile.message}
                  initValue=""
                  rules={{
                    minLength: {
                      value: 9,
                      message:
                        "Please enter valid mobile number, 9 digits required",
                    },
                    maxLength: {
                      value: 10,
                      message: "Phone number can not be more than 10 digits",
                    },
                    required: "Please enter Mobile No.",
                    setValueAs: (value) => `${value}`,
                  }}
                  iProps={{
                    placeholder: "Add Mobile Number",
                    addonBefore: "+971",
                    type: "number",
                    disabled: props.editMode,
                    className: "apperance",
                  }}
                />
              </Col>
              <Col span={12}>
                <SelectField
                  isRequired={true}
                  fieldname="organisation"
                  label="Organization"
                  control={control}
                  validate={errors.organisation && "error"}
                  validMessage={
                    errors.organisation && errors.organisation.message
                  }
                  onChange={(e) => onChangeOrganzation(e)}
                  iProps={{
                    placeholder: "Select Organization",
                    isDisabled: props.editMode,
                  }}
                  rules={{ required: "Please select organization" }}
                  initValue=""
                  selectOption={organizationList}                
                />
              </Col>
              {/* <Col span={12}>
                <SelectField
                  isRequired={true}
                  fieldname="businessUnit"
                  label="Business Units"
                  control={control}
                  validate={errors.businessUnit && "error"}
                  validMessage={
                    errors.businessUnit && errors.businessUnit.message
                  }
                  iProps={{ placeholder: "Select Business Units", isMulti: true }}
                  rules={{ required: "Please select  Business Units" }}
                  initValue=""
                  selectOption={businessUnitList}
                />
              </Col> */}
              <Col span={12}>
                <SelectField
                  isRequired={true}
                  fieldname="roles"
                  label="Roles"
                  control={control}
                  rules={{ required: "Please select role" }}
                  iProps={{ placeholder: "Select Roles", isMulti: true }}
                  initValue=""
                  selectOption={rolesList}
                  validate={errors.roles && "error"}
                  validMessage={errors.roles && errors.roles.message}
                />
              </Col>
            </Row>
            <Row gutter={24} justify="space-between">
              <Col>
                <Button
                  className="ag-gray-button-outline theme_grey_btn"
                  htmlType="button"
                  onClick={() => {
                    reset();
                    props.onCancel();
                  }}
                >
                  {<BsArrowCounterclockwise />} Back
                </Button>
              </Col>
              <Col>
                <Button className="ag-green-button theme_btn" htmlType="submit">
                  {<BsPlusCircle />}{" "}
                  {props?.editMode == false ? "Add" : "Update"}
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          isError && (
            <>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Space
                    size={24}
                    className="w-100 text-center"
                    direction="vertical"
                  >
                    <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                    <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">
                      {errorMessage}
                    </Paragraph>
                  </Space>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Button
                    className="ag-gray-button-outline theme_grey_btn"
                    htmlType="button"
                    onClick={() => {
                      reset();
                      setSubmited(false);
                      setisError(false);
                    }}
                  >
                    Back
                  </Button>
                </Col>
              </Row>
            </>
          )
        )}
      </Form>
    </>
  );
};

export default AddUser;
