import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import DashboardLayout from "../../molecules/DashboardLayout";
import SectionHeader from "../../molecules/SectionHeader";
import { Button, Col, Row, Space, Table, Typography, Spin } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { getLandlordProperties } from "./ducks/action";
import { useParams } from "react-router-dom";
import { Popup } from "../../atoms/Popup";
import EditProperty from "./EditProperty";
import RemoveProperty from "./RemoveProperty"

const { Title } = Typography;

const Properties = (props) => {
  const dispatch: any = useDispatch();

  const properties = useSelector(
    (state: any) => state.properties.getLandlordProperties
  );

  // state data
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [visible, setVisible] = useState(false);
  const [visibleRemoveItem, setVisibleRemoveItem] = useState(false);
  const [isError, setisError] = useState(false);
  const [selected, setSelected] = useState();
  const [updates, setUpates] = useState(false);
  const [loader, setLoader] = useState(false);
  const [searchBy, setSearchBy] = useState(null);

  const { id: userId } = useParams();

  const filterOptions: any = ['propertyName', 'type', 'documentsStatus', 'community'];

  const alignToNormalText = (text) => text?.charAt(0).toUpperCase() + text?.slice(1).toLowerCase()

  const getDocumentStatus = (record) => {
    if(record && properties) {
      let proeprtyDocs = record.documents.filter(obj => obj.documentName !== 'powerOfAttorney')
      let totalPropDocs = properties.userDocuments.concat(proeprtyDocs)

      if ((totalPropDocs.length) < 4 || totalPropDocs.some(doc => doc.status === 'rejected')) return 'Documents pending'
  
      if (proeprtyDocs.some(doc => doc.status !== 'approved')) return 'Verification pending'
  
      return record.documentStatus
    }
  }

  const columns: any = [
    {
      title: "DateAdded",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 220,
      render: (date: string) => dayjs(date).format("DD-MM-YYYY"),
    },

    {
      title: "Property Type",
      dataIndex: "usagesType",
      key: "usagesType",
      width: 220,
      render: (t, record) => record.unit.unitType ? alignToNormalText(record.unit.unitType) : '',
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: 220,
      render: (t, record) => `${record.address.addressLine1}, ${record.address.community}`,
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      width: 120,
      render: (t, record) => record.address.emirates,
    },
    {
      title: "status",
      dataIndex: "documentStatus",
      key: "documentStatus",
      width: 160,
      render: (t, record) => getDocumentStatus(record)
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 150,
      render: (text, record) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => onEdit(record)}
              className="p-0 ag-success"
            >
              <div className="Simple-tooltip">
                <EditOutlined />
                <section className="tt-content">Edit</section>
              </div>
            </Button>

            <Button
              type="link"
              onClick={() => removeProperty(record)}
              className="p-0 ag-success"
            >
              <div className="Simple-tooltip">
                <DeleteOutlined />
                <section className="tt-content">Remove</section>
              </div>
            </Button>

            {/* Enable disable switch */}
            {/* <div className="Simple-tooltip">
              <Switch
                className="switch"
                onChange={(e) => onChange(e, record)}
                defaultChecked={record.status === "enable" ? true : false}
              />
              <section className="tt-content">{record.status}</section>
            </div> */}
          </Space>
        );
      },
    },
  ];

  const onPropertyUpdated = () => {
    setSelected(null);
    setVisible(false);
    dispatch(getLandlordProperties(userId, search, searchBy));
  };

  const removeProperty = (record) => {

    setVisibleRemoveItem(true)
    setSelected(record);

  }

  const onPropertyRemoved = () => {
    dispatch(getLandlordProperties(userId, search, searchBy));
    setSelected(null);
    setVisibleRemoveItem(false);
  }

  const propertyRemoveContent = {
    title: (
      <Title level={3} className="m-0">
        Warning
      </Title>
    ),
    content: (
      <RemoveProperty
        onRemove={onPropertyRemoved}
        selected={selected}
        onCancel={() => {
          setVisibleRemoveItem(false)
          setSelected(null);
        }}
      />
    ),
    width: 550,
  };

  const removePropertyProps = {
    closable: true,
    visibility: visibleRemoveItem,
    title: propertyRemoveContent.title,
    content: propertyRemoveContent.content,
    width: propertyRemoveContent.width,
    onCancel: () => {
      setSelected(null);
      setVisibleRemoveItem(false)
    },
  };

  const popup = {
    title: (
      <Title level={3} className="m-0">
        Edit Property
      </Title>
    ),
    content: (
      <EditProperty
        onSubmit={onPropertyUpdated}
        selected={selected}
        userDocuments={properties.userDocuments}
        onUpdate={setUpates}
        onchangeUpdate={updates}
        // modalContent={modalContent}
        onCancel={() => { 
          
          setVisible(false)
          setSelected(null);
        }}
      />
    ),
    width: 1250,
  };

  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup.title,
    content: popup.content,
    width: popup.width,
    onCancel: () => {
      setSelected(null);
      setVisible(false)
      
      dispatch(getLandlordProperties(userId, search, searchBy));
    }
  };

  useEffect(() => {
    if (properties.length === 0) {
      dispatch(getLandlordProperties(userId));
    }
  }, []);

  const onEdit = (record) => {
    setVisible(true);
    setSelected(record);
  };

  const triggerSearchBy = (filteredValue) => {
    setSearchBy(filteredValue.label)
  }

  const onTopSearch = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    const filterData = () => {
      setPage(1);
      
      dispatch(getLandlordProperties(userId, search, searchBy));
    };

    const debouncer = setTimeout(() => {
      filterData();
    }, 1500);
    return () => {
      clearTimeout(debouncer);
    };
  }, [search]);

  useEffect(() => {
    setSearch('')
    const filterData = () => {
      setPage(1);
      
      dispatch(getLandlordProperties(userId));
    };

    const debouncer = setTimeout(() => {
      filterData();
    }, 500);
    return () => {
      clearTimeout(debouncer);
    };
  }, [searchBy]);

  return (
    <>
      <DashboardLayout>
        {/* spinner section  */}
        {loader && !isError && (
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Space size={24} className="w-100 text-center" direction="vertical">
                <Spin size="large" className="ag-fontSize32" />
              </Space>
            </Col>
          </Row>
        )}{" "}
        <SectionHeader heading="Properties" back={"/individual-landlord"} searchValue={search} search={onTopSearch} showOption={filterOptions} handleShow={triggerSearchBy}/>
        <Row gutter={24}>
          <Col xl={24}>
            <Table
              className="custom-table org_table_wrap"
              columns={columns}
              pagination={{
                current: properties?.currentPage,
                pageSize: properties?.perPage,
                showSizeChanger: false,
                className: "ag-property-text-pagination mb-1",
                position: ["topLeft"],
                onChange: (e) => {
                  setPage(e);
                  dispatch(getLandlordProperties(userId));
                },
                total: properties?.totalCount,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} Landlords`,
              }}
              dataSource={properties?.data}
              scroll={{ y: 500 }}
              rowKey={(item) => item._id}
            ></Table>
          </Col>
        </Row>
      </DashboardLayout>
      <Popup {...popupProps} />
      <Popup {...removePropertyProps} />
    </>
  );
};

export default Properties;
