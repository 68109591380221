// import { Dispatch } from "redux";
// import {
//   AuthActionLoginInit,
//   AuthActionLoginSuccess,
//   AuthActionLoginError,
//   AuthActionUpdateValue,
//   AuthActionLogoutInit,
//   AuthActionLogoutSuccess,
//   AuthActionLogoutError,
// } from "./types";

// import {
//   LOGIN_INIT,
//   LOGIN_SUCCESS,
//   LOGIN_FAILURE,
//   UPDATE_VALUE,
//   LOGOUT_INIT, LOGOUT_SUCCESS, LOGOUT_FAILURE
// } from "./constants";

// import { login, logout } from "./services";

// function dispatchAuthLoginInit(): AuthActionLoginInit {
//   return {
//     type: LOGIN_INIT,
//   };
// }

// function dispatchAuthLoginSuccess(data: any): AuthActionLoginSuccess {
//   return {
//     type: LOGIN_SUCCESS,
//     data,
//   };
// }

// function dispatchAuthLoginFailure(data: any): AuthActionLoginError {
//   return {
//     type: LOGIN_FAILURE,
//     data,
//   };
// }

// function dispatchAuthLogoutInit(): AuthActionLogoutInit {
//   return {
//     type: LOGOUT_INIT,
//   };
// }

// function dispatchAuthLogoutSuccess(data: any): AuthActionLogoutSuccess {
//   return {
//     type: LOGOUT_SUCCESS,
//     data,
//   };
// }

// function dispatchAuthLogoutFailure(data: any): AuthActionLogoutError {
//   return {
//     type: LOGOUT_FAILURE,
//     data,
//   };
// }

// export function updateFormValues(
//   key: string,
//   value: string,
// ): AuthActionUpdateValue {
//   return {
//     type: UPDATE_VALUE,
//     key,
//     value,
//   };
// }

// export function actionLogin(params) {
//   return (dispatch: Dispatch) => {
//     dispatch(dispatchAuthLoginInit());
//     return login(params)
//       .then((data) => {
//         return dispatch(dispatchAuthLoginSuccess(data));
//       })
//       .catch((e: Error) => {
//         return dispatch(dispatchAuthLoginFailure(e));
//       });
//   };
// }

// export function actionLogout(params) {
//   const { history } = params;
//   return (dispatch: Dispatch) => {
//     dispatch(dispatchAuthLogoutInit());
//     return logout()
//       .then(async (data) => {
//         await dispatch(dispatchAuthLogoutSuccess(data));
//         history("/login");
//         return true;
//       })
//       .catch((e: Error) => {
//         return dispatch(dispatchAuthLogoutFailure(e));
//       });
//   };
// }
import axios from "../../../../utils/axiosInceptor";
import { CONFIG_DATA, LOGOUT_SUCCESS } from "./constants";
import { adminService } from "../../../../configs/constants";

const configData = `${adminService}/configData`;

export const getConfigData = (key: string) => async (dispatch) => {
  try {
    const {
      data: { result },
    } = await axios.get(`${configData}?key=${key}`);
    dispatch({
      type: CONFIG_DATA,
      data: result,
    });
  } catch (e) {
    console.log({ e });
  }
};

export const onLogout = () => {
  return (dispatch) => {
    // Your code here...
    dispatch({ type: LOGOUT_SUCCESS });
  };
};
