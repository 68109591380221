import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Typography,
  Space,
  Spin,
  message
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { SelectField } from "../../../../atoms/FormElement";
import { getBuildings, getAssignedList, getUnAssignedList } from "../../ducks/actions";
import axios from "../../../../../utils/axiosInceptor";
import { adminService } from "../../../../../configs/constants";



const { Paragraph } = Typography;

const AddBuilding = props => {
  const defaultValues: any = {
    //properties: props?.partyData?.buildings.map((index) => index.name),
  };

  const dispatch:any = useDispatch();
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const { control, formState: { errors }, setValue, reset, handleSubmit } = useForm({ defaultValues });
  const defaultBuiladSelected = [];
  const preSelectedBuildings = props?.partyData?.buildings?.map((index) => {
    return defaultBuiladSelected.push({ 'label': index.name, 'value': index.id });
  });

  useEffect(() => {
    dispatch(getBuildings());
  }, []);

  const onFinish = async (values) => {
    setLoader(true);
    setSubmited(true);


    let delbuidling = [];
    let newbuilding = [];
    let oldbuilding = [];

    if (props.id?.buildings?.length > 0) {
      //console.log("values",props.id?.buildings);
      props.id?.buildings?.forEach((item) => {
        let x = values?.properties.find((y) => y.value == item.id);
        //console.log("x", x);
        //console.log("values?.properties", values?.properties);
        if (x) {
          oldbuilding.push(x.value);
        } else {
          delbuidling.push(item.id);
        }
      });
    }
    if (values.properties?.length > 0) {
      values.properties.map((item) => {
        let x = props.id?.buildings?.find((y) => y.id == item.value);
        if (!x) {
          newbuilding.push(item.value);
        }
      })
    }
    console.log("values.properties", values.properties);
    console.log("oldbuilding", oldbuilding);
    console.log("delbuidling", delbuidling);
    console.log("newbuilding", newbuilding);

    const propertieIds = values.properties.map((index) => {
      return index.value;
    });

    const assets = propertieIds.filter(ar => !defaultBuiladSelected.find(rm => (rm.value === ar)))

    try {
      let url = `${adminService}/admin/assignBuildingToSupervisor`
      let postData = {
        partyId: String(props.partyData.id),
        assetsIdToAdd: newbuilding,
        assetsIdToDelete: delbuidling
      }

      await axios.post(url, postData, { headers: { application: 4 } });
      setTimeout(() => {
        setLoader(false);
        setSubmited(false);
        message.success("Building added successfully");
        dispatch(getBuildings());
        dispatch(getAssignedList(props.page))
        dispatch(getUnAssignedList(props.page))
        props.onCancel()
      }, 3000);
    }
    catch (e) {
      setLoader(false);
      setisError(true);
      const { response } = e;
      setErrorMessage(response?.data?.message);
    }
  }

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {loader && !isError &&
        (<Row gutter={[24, 24]} >
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
            </Space>
          </Col>
        </Row>)}
      {!submited ?
        (<>

          <Row gutter={24}>
            <Col span={24}>
              <SelectField
                isRequired={true}
                fieldname="properties"
                label="Assign a building"
                control={control}
                iProps={{ placeholder: 'Select buildings', isMulti: true }}
                rules={{ required: "Please select buildings" }}
                valueGot={defaultBuiladSelected}
                setValue={setValue}
                selectOption={props.buildings}
                validate={errors.properties && "error"}
                validMessage={errors.properties && errors.properties.message}
              />
            </Col>
          </Row>
          <Row gutter={24} justify="space-between">
            <Col>
              <Button className="ag-gray-button-outline" htmlType="button" onClick={() => { reset(); props.onCancel() }}>Back</Button>
            </Col>
            <Col>
              <Button className="ag-green-button" htmlType="submit">Add</Button>
            </Col>
          </Row>
        </>
        )
        :
        isError &&
        (<>
          <Row gutter={[24, 24]} >
            <Col span={24}>
              <Space size={24} className="w-100 text-center" direction="vertical">
                <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                <Paragraph className="ag-error fontWeight600 mb-0">{errorMessage}</Paragraph>
              </Space>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}><Button className="ag-gray-button-outline" htmlType="button" onClick={() => { setSubmited(false); setisError(false) }}>Back</Button></Col>
          </Row>
        </>)
      }
    </Form>
  );
};

export default AddBuilding;