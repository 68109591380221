import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import DashboardLayout from '../../../molecules/DashboardLayout'
import { Col, Row } from 'antd'
import BreadCrumb from '../../../atoms/BreadCrumb'
import SectionHeader from '../../../molecules/SectionHeader'
import TicketsModule from '../../../molecules/Tickets'
const showOption = {
  value: "All",
  label: "All Technicians",
}
const categories = {
  value: "All",
  label: "All Categories",
};
const defaultNewStatusType = {
  value: "All",
  label: "All New Tickets",
}
const defaultInProgressStatusType = {
  value: "All",
  label: "All In Progress Tickets",
}
const defaultCompletedStatusType = {
  value: "All",
  label: "All Closed Tickets",
}
const PropertyTickets = props => {

  const [technicians, setTechnicians] = useState([showOption]);
  const [ticketsType, setTicketType] = useState([categories]);
  const [technicianId, setTechnicianId] = useState();
  const [categoryName, setCategoryName] = useState();
  const [newStatusType, setNewStatusType] = useState([defaultNewStatusType]);
  const [completedStatusType, setCompletedtatusType] = useState([defaultCompletedStatusType]);
  const [progressStatusType, setProgressStatusType] = useState([defaultInProgressStatusType]);
  const propertyID = useSelector(
    (state:any) => state.dashboard.getPropertyID,
  );
  const breadcrumbList: any = [
    {
      label: "Landloard Managment",
      route: "/landlord-management",
    },
    {
      label: "Properties",
      route: `/all-properties/${propertyID}`,
    },
    {
      label: "Tickets",
      route: "/tickets",
    },
  ];

  const handleShowTechnician = (value) => {
    setTechnicianId(value?.value == 'All' ? null : value?.value)
  }
  const handleShowCategory = (value) => {
    setCategoryName(value?.value == 'All' ? null : value?.value)
  }


  return (
    <DashboardLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <BreadCrumb list={breadcrumbList} />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <SectionHeader heading='Tickets' total={0}
            showOption={technicians}
            handleShow={handleShowTechnician}
            handleView={handleShowCategory}
            search={(e) => { }}
            exportAction={(e) => { }}
            viewOption={ticketsType}
            viewOptionLabel="Category"
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={8}>
          <TicketsModule
            // ticketlist={ticketNew}
            category="New"
            count={0}
          // onCount={() => countUpdate("new")}
          // viewTicket={(e) => viewTicket(e, 'new')}
          // showStatusOption={newStatusType}
          // handleStatusChange={handleNewStatusChange}
          // notifyCaseId={notifyCaseId}
          />
        </Col>
        <Col span={8}>
          <TicketsModule
            // ticketlist={ticketNew}
            category="In Progress"
            count={0}
          // onCount={() => countUpdate("new")}
          // viewTicket={(e) => viewTicket(e, 'new')}
          // showStatusOption={newStatusType}
          // handleStatusChange={handleNewStatusChange}
          // notifyCaseId={notifyCaseId}
          />
        </Col>
        <Col span={8}>
          <TicketsModule
            // ticketlist={ticketNew}
            category="Closed"
            count={0}
          // onCount={() => countUpdate("new")}
          // viewTicket={(e) => viewTicket(e, 'new')}
          // showStatusOption={newStatusType}
          // handleStatusChange={handleNewStatusChange}
          // notifyCaseId={notifyCaseId}
          />
        </Col>
      </Row>
    </DashboardLayout>
  );
};

export default PropertyTickets;
