import {
  TICKETS_REVIEWS,
  GET_CLOSED_STATUS,
  GET_INPRGTICKET_STATUS,
  GET_NEWTICKET_STATUS,
  GET_CATEGORIES,
  GET_TECHNICIANS,
  ORGANIZATIONS,
  SUBSCRIPTIONS,
  GET_NEW_TICKETS,
  GET_INPROGRESS_TICKETS,
  GET_COMPLETE_TICKETS,
  GET_NEW_TICKETS_COUNT,
  GET_INROGRESS_TICKETS_COUNT,
  GET_CLOSED_TICKETS_COUNT,
  ALL_ORGANIZATIONS,
  GET_BUSINESS_UNITS,
  GET_BUSINESS_UNIT_TYPES,
} from "./constants";

var _ = require("lodash");

const initialState = {
  getOrganizations: [],
  getAllOrganizations: [],

  getOrganizationsDrop: [],
  getBusinessUnits: [],
  getSubscriptions: [],

  orgNewTickets: [],
  orgInprogressTickets: [],
  orgCompleteTickets: [],
  orgNewTicketsCount: 0,
  orgInprogressTicketsCount: 0,
  orgCompleteTicketsCount: 0,
  getTechnicians: [],
  getCategories: [],
  getNewStatus: [],
  getInPrgStatus: [],
  getClosedStatus: [],
  businessUnitTypes: [],
};
export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case GET_BUSINESS_UNIT_TYPES:
      return { ...state, businessUnitTypes: data?.[0]?.businessUnitTypes };
    case ORGANIZATIONS:
      return { ...state, getOrganizations: data };
    case ALL_ORGANIZATIONS:
      return {
        ...state,
        getAllOrganizations: data,
        getOrganizationsDrop: _.map(data, (item) => ({
          value: item.orgId,
          label: item.name,
          numberOfUsers: item.numberOfUsers,
          numberOfUnits: item.numberOfUnits,
        })),
      };
    // case ORGANIZATIONS_DROP: return { ...state, getAllOrganizations: data }
    case SUBSCRIPTIONS:
      return { ...state, getSubscriptions: data };

    case GET_NEW_TICKETS:
      return { ...state, orgNewTickets: data };
    case GET_BUSINESS_UNITS:
      return {
        ...state,
        getBusinessUnits: data,
        getBusinessUnitDrops: _.map(data, (item) => ({
          value: item.businessUnitId,
          label: item.businessUnitName,
        })),
      };
    case GET_INPROGRESS_TICKETS:
      return { ...state, orgInprogressTickets: data };
    case GET_COMPLETE_TICKETS:
      return { ...state, orgCompleteTickets: data };
    case GET_NEW_TICKETS_COUNT:
      return { ...state, orgNewTicketsCount: data };
    case GET_INROGRESS_TICKETS_COUNT:
      return { ...state, orgInprogressTicketsCount: data };
    case GET_CLOSED_TICKETS_COUNT:
      return { ...state, orgCompleteTicketsCount: data };
    case GET_TECHNICIANS:
      return { ...state, getTechnicians: data };
    case GET_CATEGORIES:
      return { ...state, getCategories: data };
    case GET_NEWTICKET_STATUS:
      return { ...state, getNewStatus: data };
    case GET_INPRGTICKET_STATUS:
      return { ...state, getInPrgStatus: data };
    case GET_CLOSED_STATUS:
      return { ...state, getClosedStatus: data };
    case TICKETS_REVIEWS:
      return { ...state, getTicketReviews: data };
    default:
      return state;
  }
};
