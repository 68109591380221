import React, { useState, useEffect } from "react";

import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import axios from "../../../../utils/axiosInceptor";
import { useSelector, useDispatch } from "react-redux";
import { adminService } from "../../../../configs/constants";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { DateField, SelectField } from "../../../atoms/FormElement";
import {
  BsPlusCircle,
  BsArrowCounterclockwise,
  BsFillNodeMinusFill,
  BsFillNodePlusFill,
} from "react-icons/bs";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { getAllServicePrivider } from "../../ServiceProvider/ducks/actions";
import { Row, Col, Button, Form, Typography, message, Space, Spin } from "antd";

const { Paragraph } = Typography;

const defaultValues: any = {
  name: "",
};

var _ = require("lodash");

export default (props) => {
  const dispatch: any = useDispatch();

  const { onUpdate, onchangeUpdate } = props;
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [endDate, setEndDate] = useState(dayjs());
  const [startDate, setStartDate] = useState(dayjs());
  const [spList, setSpList] = useState({
    organization: props.selected?.orgId,
    serviceProviders: [
      {
        endDate: null,
        startDate: null,
        serviceProvider: null,
      },
    ],
  });

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues,
  });

  const subscription = useSelector(
    (state: any) => state.subscription.getAllSubscriptions
  );

  const organizations = useSelector(
    (state: any) => state.organization.getOrganizationsDrop
  );

  const serviceProviders = useSelector(
    (state: any) => state.serviceprovider.serviceProviderDropDown
  );

  useEffect(() => {
    dispatch(getAllServicePrivider());
  }, []);

  useEffect(() => {
    if (props.selected) {
      const selectedOrganization = organizations.filter(
        (item) => item.value === props.selected.orgId
      )[0];

      const selectedSP = serviceProviders.filter(
        (item) => item.value === props.selected.serviceProviderId
      )[0];

      setValue("spId", selectedSP);
      setValue("name", selectedOrganization);
      setValue("startDate", dayjs(props.selected.startDate));

      if (props.selected.endDate)
        setValue("endDate", dayjs(props.selected.endDate));

      spList.organization = selectedOrganization;
      spList.serviceProviders[0].serviceProvider = selectedSP;
      spList.serviceProviders[0].endDate = dayjs(props.selected.endDate);
      spList.serviceProviders[0].startDate = dayjs(props.selected.startDate);

      setSpList(spList);
    } else reset();

    return () => {
      reset();
    };
  }, [props.selected, subscription, props]);

  const onFinish = (values) => {
    props.onSubmit();
    reset();
    setLoader(true);
    setSubmited(true);
    if (props.selected?.orgId) {
      const url = `${adminService}/maintenance/serviceprovider/assign`;

      axios
        .post(url, spList, { headers: { application: 4 } })
        .then((response) => {
          let { data } = response;
          setLoader(false);
          setSubmited(false);
          reset();
          message.success(data?.message);
          onUpdate(!onchangeUpdate);
          props.onSubmit();
        })
        .catch((error) => {
          message.error(
            error.response.data?.error || error.response.data?.message
          );
          setLoader(false);
          setSubmited(false);
          reset();
        });
    }
  };

  const _alterServiceProvider = () => {
    let data: any = [...serviceProviders];

    if (spList.serviceProviders.length > 0) {
      for (let key in spList.serviceProviders) {
        for (let spKey in data) {
          if (
            data[spKey].value ===
            spList.serviceProviders[key].serviceProvider?.value
          ) {
            data.splice(spKey, 1);
            break;
          }
        }
      }
    }

    return data;
  };

  const _handleOnChange = (name: string, value: string, index: number) => {
    const spListCopy = { ...spList };

    switch (name) {
      case "SERVICE_PROVIDER":
        spListCopy.serviceProviders[index].serviceProvider = value;
        break;
      case "START_DATE":
        spListCopy.serviceProviders[index].startDate = value;
        break;
      case "END_DATE":
        spListCopy.serviceProviders[index].endDate = value;
        break;
      default:
        break;
    }

    setSpList(spListCopy);
  };

  const _addMoreRow = (index: number) => {
    let spCopy = { ...spList };
    if (
      spCopy.serviceProviders[index].serviceProvider &&
      spCopy.serviceProviders[index].startDate
    ) {
      spCopy.serviceProviders = [
        ...spCopy.serviceProviders,
        {
          endDate: null,
          startDate: null,
          serviceProvider: null,
        },
      ];

      setSpList(spCopy);
    } else message.warning("Please fill all details before adding new row.");
  };

  const _removeRow = (index: number) => {
    let spCopy = { ...spList };
    spCopy.serviceProviders.splice(index, 1);
    setSpList(spCopy);
  };

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit(onFinish)}
      className="bu_o_form"
    >
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          <Row gutter={24}>
            <Col span={6}>
              <SelectField
                isRequired={true}
                fieldname="name"
                control={control}
                label="Organization"
                selectOption={organizations}
                validate={errors.name && "error"}
                validMessage={errors.name && errors.name.message}
                rules={{
                  required: "Please select organization",
                }}
                iProps={{
                  isDisabled: true,
                  placeholder: "Select organization",
                }}
              />
            </Col>

            <Col span={6}>
              <SelectField
                fieldname="spId"
                isRequired={true}
                control={control}
                label="Service Provider"
                validate={errors.spId && "error"}
                validMessage={errors.spId && errors.spId.message}
                selectOption={_alterServiceProvider()}
                rules={{
                  required: "Please select service provider",
                }}
                iProps={{
                  isDisabled: true,
                  placeholder: "Select",
                }}
              />
            </Col>

            <Col span={6}>
              <DateField
                isRequired={true}
                control={control}
                label="Start Date"
                fieldname="startDate"
                validate={errors.startDate && "error"}
                rules={{
                  required: "Please enter start date",
                }}
                validMessage={errors.startDate && errors.startDate.message}
                iProps={{
                  format: "DD-MM-YYYY",
                  placeholder: "DD-MM-YYYY",
                  // value: spList.serviceProviders[index].startDate,
                }}
                onChange={(e) => {
                  // _handleOnChange("START_DATE", e, index);
                  if (endDate && endDate < e) {
                    setEndDate(e);
                    setValue("endDate", null);
                  }
                  if (e < endDate) {
                    setEndDate(dayjs());
                    setValue("endDate", dayjs());
                  }
                  setStartDate(e);
                  setValue("startDate", e);
                }}
              />
            </Col>

            <Col span={6}>
              <DateField
                label="End Date"
                control={control}
                className="enddate"
                fieldname="endDate"
                onChange={(e) => {
                  setEndDate(e);
                  // _handleOnChange("END_DATE", e, index);
                }}
                iProps={{
                  format: "DD-MM-YYYY",
                  placeholder: "DD-MM-YYYY",
                  // value: spList.serviceProviders[index].endDate,
                  disabledDate: (current) => current <= dayjs(startDate),
                }}
              />
            </Col>
          </Row>

          <Row gutter={24} justify="space-between">
            <Col>
              <Button
                className="ag-error theme_grey_btn"
                htmlType="button"
                onClick={() => {
                  !props.selected && reset();
                  props.onCancel();
                }}
              >
                {<BsArrowCounterclockwise />} Back
              </Button>
            </Col>

            <Col>
              <Button className="ag-green-button theme_btn" htmlType="submit">
                {<BsPlusCircle />} Update
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </Form>
  );
};
