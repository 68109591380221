export const GET_ROLES = "GET_ROLES";
export const GET_ROLES_BY_APP_NAME = "GET_ROLES_BY_APP_NAME";
export const GET_CONFIG = "GET_CONFIG";
export const GET_ALL_ROLES = "GET_ALL_ROLES";
export const GET_APPLICATIONS = "GET_APPLICATIONS";
export const GET_APPLICATION_ROLES = "GET_APPLICATION_ROLES";
export const GET_ORGANIZATION_LIST = "GET_ORGANIZATION_LIST";
export const GET_PERMISSION_APPLICATIONS = "GET_PERMISSION_APPLICATIONS";
export const GET_USERS = "GET_USERS";
export const GET_USERS_APPLICATION_ROLES = "GET_USERS_APPLICATION_ROLES";
export const GET_PERMISSION_BY_ROLE_ID = "GET_PERMISSION_BY_ROLE_ID";
export const UPDATE_ROLE = "UPDATE_ROLE";
