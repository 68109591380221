import * as action_types from "./constants";

var _ = require("lodash");

const initialState: any = {
  rolesData: [],
  rolesDrop: [],
  appData: {},
  applicationData: [],
  organizationData: [],
  permissionData: [],
  usersData: [],
  configData: [],
  userApplicationRoles: [],
  permissionsByRoleId: {},
  rolesByAppName: [],
};

export default (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case action_types.GET_PERMISSION_BY_ROLE_ID:
      return { ...state, permissionsByRoleId: data };
    case action_types.GET_CONFIG:
      return {
        ...state,
        // configData: data[0].appPortalList,
        configData: Object.entries(data[0]?.appPortalList).map(
          ([label, value]) => ({ label, value })
        ),
      };
    case action_types.GET_ROLES_BY_APP_NAME:
      return {
        ...state,
        rolesByAppName: _.map(data, (item) => ({
          value: item.roleId,
          label: item.roleName,
        })),
      };
    case action_types.GET_ROLES:
      return { ...state, rolesData: data };
    case action_types.GET_ALL_ROLES:
      return {
        ...state,
        rolesData: data,
        rolesDrop: _.map(data, (item) => ({
          value: item.roleId,
          label: item.roleName,
        })),
      };
    case action_types.GET_APPLICATIONS:
      return { ...state, appData: data };
    case action_types.GET_APPLICATION_ROLES:
      return { ...state, applicationData: data };
    case action_types.GET_ORGANIZATION_LIST:
      return { ...state, organizationData: data };
    case action_types.GET_PERMISSION_APPLICATIONS:
      return { ...state, permissionData: data };
    case action_types.GET_USERS:
      return { ...state, usersData: data };
    case action_types.GET_USERS_APPLICATION_ROLES:
      return { ...state, userApplicationRoles: data };
    default:
      return state;
  }
};
