import React, { useState, useEffect } from "react";
import { Radio, Col, Row, Table, Typography, message, Card } from "antd";
import { useDispatch, useSelector } from "react-redux";
import DashboardLayout from "../../../molecules/DashboardLayout";
import Checkbox from "antd/lib/checkbox/Checkbox";
import {
  getPermissionByRoleId,
  getpermissionApplications,
} from "../ducks/actions";
import { updateRolePermission } from "../ducks/services";
import { Collapse } from "antd";

const RolePermissions = (props) => {
  const { Panel } = Collapse;
  const { Title } = Typography;
  const [roletype, setRoletype] = useState<any>("");
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [permissions, setPermissions] = useState<any>([]);
  const dispatch: any = useDispatch();
  const [activeKye, setActiveKey] = useState("");
  const [mainIndex, setMainIndex] = useState("");
  const [application, setApplications] = useState([]);

  const accessRoles = useSelector(
    (state: any) => state.roles.permissionData?.data
  );

  const permissonsByRoleId = useSelector(
    (state: any) => state.roles.permissionsByRoleId
  );

  const PERMISSIONS = [
    {
      appName: "BO Portal",
      permissions: [
        {
          moduleName: "Organization",
          operations: [
            {
              create: true,
              delete: false,
              read: false,
              update: false,
            },
          ],
        },
        {
          moduleName: "Plans",
          operations: [
            {
              create: true,
              delete: true,
              read: false,
              update: false,
            },
          ],
        },
        {
          moduleName: "Users",
          operations: [
            {
              create: true,
              delete: true,
              read: true,
              update: false,
            },
          ],
        },
        {
          moduleName: "Roles",
          operations: [
            {
              create: true,
              delete: true,
              read: true,
              update: true,
            },
          ],
        },
        {
          moduleName: "Permission",
          operations: [
            {
              create: false,
              delete: false,
              read: false,
              update: false,
            },
          ],
        },
      ],
    },
  ];

  useEffect(() => {
    setPermissions(permissonsByRoleId?.applications);
  }, [permissonsByRoleId]);

  useEffect(() => {
    // setPermissions(PERMISSIONS);
    dispatch(getpermissionApplications());
  }, []);

  let appInfo = { iIndex: 0, rIndex: 0 };

  const mainColumns: any = [
    Table.EXPAND_COLUMN,
    {
      // title: "App Name",
      dataIndex: "moduleName",
      key: "moduleName",
      width: 220,
    },
  ];

  const columns: any = [
    {
      title: "Create",
      dataIndex: "create",
      key: "create",
      render: (value, record, rowIndex) => {
        return (
          <Checkbox
            key={record}
            checked={value}
            onChange={onSubModuleChangeHandler(rowIndex, "create", value)}
          />
        );
      },
    },
    {
      title: "Read",
      dataIndex: "read",
      key: "read",
      render: (value, record, rowIndex) => {
        return (
          <Checkbox
            key={rowIndex}
            checked={value}
            onChange={onSubModuleChangeHandler(rowIndex, "read", value)}
          />
        );
      },
    },
    {
      title: "Update",
      dataIndex: "update",
      key: "update",
      render: (value, record, rowIndex) => {
        return (
          <Checkbox
            key={rowIndex}
            checked={value}
            onChange={onSubModuleChangeHandler(rowIndex, "update", value)}
          />
        );
      },
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      render: (value, record, rowIndex) => {
        return (
          <Checkbox
            key={rowIndex}
            checked={value}
            onChange={onSubModuleChangeHandler(rowIndex, "delete", value)}
          />
        );
      },
    },
  ];

  const _alterPermissions = (apps) => {
    const permissionsCopy = [...permissions];

    if (apps && apps.length > 0) {
      for (let key in permissionsCopy) {
        for (let appKey in apps) {
          if (
            permissionsCopy[key].appName.replaceAll(" ", "").toLowerCase() ===
            apps[appKey].appName.replaceAll(" ", "").toLowerCase()
          ) {
            permissionsCopy[key].permissions = apps[appKey].permissions;
          }
        }
      }
      setPermissions(permissionsCopy);
    } else setPermissions(PERMISSIONS);
  };

  const onRoleChangeHandler = (rid) => {
    dispatch(getPermissionByRoleId(rid?.roleId));
    // setApplications(rid.permission?.applications);
    setRoletype(rid);
    // _alterPermissions(rid.permission?.applications);
  };

  const onSubModuleChangeHandler = (rowIndex, columnKey, value) => (event) => {
    const appCopy = [...permissions];
    appCopy[mainIndex].permissions[activeKye].operations[rowIndex][columnKey] =
      !value;
    setPermissions(appCopy);
    const postData = {
      applications: appCopy,
    };

    console.log("Roletyp--->", roletype);

    updateRolePermission(roletype?.roleId, postData)
      .then((response) => {
        dispatch(getpermissionApplications());
        message.success(response?.data?.message);
      })
      .then(() => {})
      .catch((error) => {
        message.error(error.response.data.error || error.response.data.message);
      });
  };

  const _onPanalChange = (index, parentIndex) => {
    setMainIndex(parentIndex);
    setActiveKey(index);
  };

  return (
    <DashboardLayout>
      <Col className="permissions_wrap">
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Title level={2} className="m-0">
              Permissions
            </Title>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Card title={<Title level={3}>Roles</Title>}>
              <Radio.Group
                buttonStyle="solid"
                size={"large"}
                value={roletype.roleId}
              >
                <Row gutter={[24, 24]}>
                  {accessRoles &&
                    accessRoles?.map((value, key) => {
                      return (
                        <Col key={key}>
                          <Radio.Button
                            key={key}
                            value={value.roleId}
                            onClick={() => onRoleChangeHandler(value)}
                          >
                            {value.name}
                          </Radio.Button>
                        </Col>
                      );
                    })}
                </Row>
              </Radio.Group>
            </Card>
          </Col>
        </Row>

        <Row gutter={[24, 24]}>
          <Col span={24}>
            {permissions &&
              permissions.map((item, index) => {
                return (
                  <Card
                    title={
                      <Title level={3} key={index}>
                        {item?.appDisplayName}
                      </Title>
                    }
                  >
                    <Row gutter={24}>
                      <Col span={24}>
                        <Collapse
                          accordion
                          expandIconPosition="right"
                          className="ag-collapse ag-border-card"
                          onChange={(e) => _onPanalChange(e, index)}
                        >
                          {item?.permissions &&
                            item?.permissions.map((per, index) => {
                              return (
                                <Panel header={per?.moduleName} key={index}>
                                  <Row gutter={[24, 24]}>
                                    <Col span={24}>
                                      <Table
                                        columns={columns}
                                        pagination={false}
                                        scroll={{ y: 500 }}
                                        dataSource={per.operations}
                                      />
                                    </Col>
                                  </Row>
                                </Panel>
                              );
                            })}
                        </Collapse>
                      </Col>
                    </Row>
                  </Card>
                );
              })}
          </Col>
        </Row>
      </Col>
    </DashboardLayout>
  );
};

export default RolePermissions;
