import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { getRoles } from "../../ducks/actions";
import axios from "../../../../../utils/axiosInceptor";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { adminService } from "../../../../../configs/constants";
import { BsPlusCircle, BsArrowCounterclockwise } from "react-icons/bs";
import { InputField, SelectField } from "../../../../atoms/FormElement";
import { Row, Col, Button, Form, Typography, message, Space, Spin } from "antd";

var _ = require("lodash");
const { Paragraph } = Typography;

const defaultValues: any = {
  roleName: "",
};

const AddRole = (props) => {
  const configData = useSelector((state: any) => state.roles.configData);
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const {
    control,
    formState: { errors },
    reset,
    setValue,
    handleSubmit,
  } = useForm({ defaultValues });

  console.log("Props--->", props);

  useEffect(() => {
    if (props.selected) {
      setValue("roleName", props.selected.roleName);
      setValue("appName", selectedApps, { shouldValidate: true });
    }
  }, [props.selected]);

  const onFinish = async (values) => {
    setLoader(true);
    setSubmited(true);
    const { roleName, appName } = values;
    if (props.editMode) {
      const postJson = {
        appName: appName.map((item) => item.label),
      };

      const url = `${adminService}/roles/update/${props.selected.roleId}`;
      try {
        await axios.patch(url, postJson);
        setTimeout(() => {
          setLoader(false);
          setSubmited(false);
          getRoles();
          reset();
          message.success("Role updated successfully");
          props.onSubmit();
        }, 3000);
      } catch (e) {
        console.log("Error--->", e);
        setLoader(false);
        setisError(true);
        const { response } = e;
        setErrorMessage(response?.data?.message);
      }
    } else {
      const postJson = {
        roleName,
        appName: appName.map((item) => item.label),
      };

      const url = `${adminService}/roles`;
      try {
        await axios.post(url, postJson, { headers: { application: 4 } });
        setTimeout(() => {
          setLoader(false);
          setSubmited(false);
          getRoles();
          reset();
          message.success("Role added successfully");
          props.onSubmit();
        }, 3000);
      } catch (e) {
        setLoader(false);
        setisError(true);
        const { response } = e;
        setErrorMessage(response?.data?.message);
      }
    }
  };

  const resetForm = () => {
    reset({ defaultValues });
    props?.setOnClose(false);
  };

  const selectedApps = configData.filter((item) =>
    _.includes(props?.selected?.appName, item.label)
  );

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          <Row gutter={24}>
            <Col span={12}>
              <InputField
                label="Role Name"
                isRequired={true}
                control={control}
                fieldname="roleName"
                validate={errors.roleName && "error"}
                validMessage={errors.roleName && errors.roleName.message}
                iProps={{
                  disabled: props.editMode,
                  placeholder: "Enter role name",
                }}
                rules={{
                  required: "Please enter role name",
                  pattern: {
                    // value: /^[a-zA-Z._^%$#!~@,-]+$/,
                    value: /^[a-zA-Z]+$/,
                    message:
                      "Role name should not contain space or numeric values",
                  },
                }}
              />
            </Col>
            <Col span={12}>
              <SelectField
                label="App Name"
                isRequired={true}
                control={control}
                fieldname="appName"
                selectOption={configData}
                validate={errors.appName && "error"}
                rules={{ required: "Please select role" }}
                validMessage={errors.appName && errors.appName.message}
                iProps={{ placeholder: "Select App Name", isMulti: true }}
              />
            </Col>
          </Row>

          <Row gutter={24} justify="space-between">
            <Col>
              <Button
                className="ag-gray-button-outline theme_grey_btn"
                htmlType="button"
                onClick={() => {
                  reset({ defaultValues });
                  props.onCancel();
                }}
              >
                {<BsArrowCounterclockwise />} Back
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button theme_btn" htmlType="submit">
                {<BsPlusCircle />} {props.selected ? "Update" : "Add"}
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </Form>
  );
};

export default AddRole;
