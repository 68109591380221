import * as action_types from "./constants";

const initialState: any = {
  maintenanceTypes: [],
  maintenanceSubTypes: [],
};

export default (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case action_types.GET_MAINTENANCE_TYPE:
      return { ...state, maintenanceTypes: data };
    case action_types.GET_MAINTENANCE_SUB_TYPE:
      return { ...state, maintenanceSubTypes: data };
    default:
      return state;
  }
};
