import React from "react";
import { Layout } from "antd";
import Header from "../../molecules/Header";
import Sidebar from "../../molecules/Sidebar";
import { NavHeader } from "../../molecules/NavHeader";
const { Content } = Layout;

type IProps = {};
type IState = { tabKey: string };

class Dashboard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      tabKey: "1",
    };
  }

  render() {
    return (
      <Layout className="main-layout">
        <Header />
        <Layout>
          <NavHeader />
          <Layout className="layout-space">
            <Content className="main-content">
              <iframe
                style={{
                  flex: 1,
                  width: "100%",
                  height: "100%",
                  borderWidth: 0,
                  display: "flex",
                }}
                allowFullScreen={true}
                title="Agi-Properties-ORION-BO"
                src="https://app.powerbi.com/reportEmbed?reportId=e64e5cb8-7222-448f-aa5d-f254822de6d1&autoAuth=true&ctid=38a3f678-5fe7-4dbb-8eb9-eee7a0c6fd57"
              ></iframe>

            </Content>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

export default Dashboard;
