import * as Crypto from "./crypto";
import * as lodash from "./lodash";

export const CryptoJS = Crypto;
export const _ = lodash;


//function to create label value object for selectField
export const getSelected = ((data,k) =>{
    return  data[k] ? {label:data[k],value:k} : '';
  });