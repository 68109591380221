import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import { actionSignUp, updateFormValues } from "./duck/actions";
import { authService } from "../../../configs/constants";
import {
  Row,
  Col,
  Typography,
  Form,
  Select,
  Button,
  Popover,
  Progress,
  message,
} from "antd";
import { setCookie } from "../../../utils/helper";
import { useForm, Controller, useWatch } from "react-hook-form";
import axios from "axios";
import LoginTemplate from "../Login/LoginTemplate";
import TermsAndCondition from "./components/TermsAndCondition";
import { Popup } from "../../atoms/Popup";
import thumbIcon from "../../../assets/img/thumb.svg";
import facebookIcon from "../../../assets/img/facebook.svg";
import googleIcon from "../../../assets/img/google.svg";
import {
  InputCheckbox,
  InputField,
  InputPassword,
} from "../../atoms/FormElement";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";
import { SITEKEY } from "../../../configs/constants";

const { Option } = Select;

export const SignUp = (props) => {
  const history = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [tokenCapcha, setReCapcha] = React.useState();
  const [user, setUser] = React.useState({ error: false, message: "" });
  const refReCaptcha = useRef(null);
  const { username, password, email } = useSelector(
    (state:any) => ({
      username: state.signup.username,
      password: state.signup.password,
      email: state.signup.email,
    }),
    shallowEqual,
  );

  const dispatch:any = useDispatch();
  const {
    control,
    handleSubmit, formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm({ mode: "onChange" });

  let checkPassword = useWatch({ control, name: "password" });
  let checkterms = useWatch({ control, name: "terms", defaultValue: false });

  const verifyCallback = (token) => {
    // console.log("recaptch", token);
    setReCapcha(token);
  };

  useEffect(() => {
    setValue("email", props.location["state"].userEmail);
    const phoneValue = props?.location["state"]?.userPhone;
    const phoneNumber = phoneValue.replace(/\D/g, "").slice(-9);
    setValue("phone", phoneNumber);
    loadReCaptcha(SITEKEY);
  }, []);

  const { Text, Paragraph } = Typography;

  const onFinish = async (values) => {
    const { username, phone, password, email } = values;
    setLoading(true);
    const headers = {
      application: 5,
    };

    const postJson = {
      username: username,
      first_name: username,
      last_name: username,
      password: password,
      partyId: 1,
      // dob: "",
      contact_number: phone,
      email: email,
      recapcha: tokenCapcha,
    };
    const url = `${authService}/user/register`;
    try {
      const result = await axios.post(url, postJson, { headers: headers });
      setLoading(false);
      // await window.localStorage.setItem(
      //   "login-response",
      //   JSON.stringify(result?.data?.result),
      // );
      // setCookie("landlordUser", true, new Date(result?.data?.exp).getDate());
      // reset();
      // setTimeout(() => history("/dashboard"), 1000);

      history("/otp",{
        state: {
          userEmail: email,
        },
      });
    } catch (e) {
      setLoading(false);
      const { response } = e;
      refReCaptcha.current.execute();
      message.error(response?.data?.result?.error);

      // setLoading(false);
      // const { response } = e;
      // let str = response?.data?.result?.error;
      // if (str.search('already')) {
      //   setUser({
      //     error: true,
      //     message: str
      //   })
      // } else {
      //   message.error(response?.data?.result?.error);
      // }
    }
  };

  const prefixSelector = (
    <Form.Item noStyle>
      <Controller
        name="prefix"
        control={control}
        defaultValue="971"
        render={()=>(
          <Select>
            <Option value="971">+971</Option>
            <Option value="92">+92</Option>
          </Select>
        )}
      />
    </Form.Item>
  );

  const content = (
    <div>
      <Progress
        percent={30}
        size="small"
        status="exception"
        showInfo={false}
        style={{ width: "272px" }}
      />
      <ul className="ag-unordered-list">
        <li className="ag-success">One Upper Case letter</li>
        <li>One Lower Case letter</li>
        <li>One number</li>
      </ul>
    </div>
  );

  const popupProps = {
    closable: false,
    visibility: visible,
    content: (
      <TermsAndCondition
        onCancel={() => setVisible(false)}
        onAccept={() => {
          setVisible(false);
          setValue("terms", true);
        }}
      />
    ),
    width: 1200,
    onCancel: () => setVisible(false),
  };

  return (
    <>
      <LoginTemplate load={loading}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Link to="/login" className="btn-tab-style">
              Sign in
            </Link>
            <Link to="/signup" className="btn-tab-style active">
              Sign up
            </Link>
          </Col>
          <Col span={24}>
            <Form name="normal_signup" onFinish={handleSubmit(onFinish)}>
              <Popover
                placement="right"
                content={
                  <Text type="danger">
                    <InfoCircleOutlined /> {user.message}
                  </Text>
                }
                trigger="click"
                visible={user.error}
              >
                <InputField
                  isRequired={true}
                  fieldname="username"
                  control={control}
                  initValue=""
                  onBlur={() => setUser({ error: false, message: "" })}
                  iProps={{ placeholder: "Username", size: "large" }}
                  rules={{
                    required: "Please input username",
                    pattern: {
                      value: /(?=^.{1,50}$)^\S*$/,
                      message: "Input username without space!",
                    },
                  }}
                  validate={errors.username && "error"}
                  validMessage={errors.username && errors.username.message}
                />
              </Popover>
              <InputField
                isRequired={true}
                fieldname="email"
                control={control}
                initValue=""
                iProps={{ placeholder: "Email", size: "large" }}
                rules={{
                  pattern: {
                    value: /(?=^.{1,50}$)^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    message: "Enter valid Email Address",
                  },
                  required: "Please enter Email Address",
                }}
                validate={errors.email && "error"}
                validMessage={errors.email && errors.email.message}
              />

              {/* <Popover
                placement="rightTop"
                title="Password must contain:"
                content={content}
                trigger="click"
                visible={errors.password ? true : false}
              >
                <InputPassword
                  isRequired={true}
                  fieldname="password"
                  control={control}
                  initValue=""
                  iProps={{ placeholder: "Password", size: "large" }}
                  rules={{
                    pattern: {
                      value: /^(?!.*[\s])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,50}$/,
                      // message: "Passwords must contain at least 8 characters, 1 capital letter, 1 number and a special character",
                    },
                    required: "Please enter password",
                  }}
                  validate={errors.password && "error"}
                // validMessage={errors.password && errors.password.message}
                />
              </Popover>
              <InputPassword
                isRequired={true}
                fieldname="confirm"
                control={control}
                initValue=""
                iProps={{ placeholder: "Confirm Password", size: "large" }}
                rules={{
                  required: "Please confirm your password!",
                  validate: (value) =>
                    value === checkPassword || "The passwords do not match",
                }}
                validate={errors.confirm && "error"}
                validMessage={errors.confirm && errors.confirm.message}
              /> */}

              <InputField
                isRequired={true}
                fieldname="phone"
                control={control}
                validate={errors.phone && "error"}
                validMessage={errors.phone && errors.phone.message}
                initValue=""
                rules={{
                  minLength: {
                    value: 9,
                    message:
                      "Please enter valid mobile number, 9 digits required",
                  },
                  maxLength: {
                    value: 9,
                    message:
                      "Please enter valid mobile number, 9 digits required",
                  },
                  required: "Please enter Mobile No.",
                  setValueAs: (value) => `+${getValues("prefix")}${value}`,
                }}
                iProps={{
                  size: "large",
                  placeholder: "Add Mobile Number",
                  addonBefore: prefixSelector,
                  type: "number",
                }}
              />
              <InputCheckbox
                fieldname="terms"
                label="I agree to the"
                control={control}
                validate={errors.terms && "error"}
                validMessage={errors.terms && errors.terms.message}
                initValue={false}
                class="success-check"
                rules={{ required: "Should accept agreement" }}
              />
              <Button
                type="link"
                className="p-0 fontWeight600"
                onClick={() => setVisible(true)}
              >
                Terms and Conditions
              </Button>

              <Form.Item className="mb-0 mt-1 text-center">
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!checkterms}
                  className="signup-form-button"
                  size="large"
                >
                  Sign up
                </Button>
              </Form.Item>
              <ReCaptcha
                action="main"
                sitekey={SITEKEY}
                ref={refReCaptcha}
                verifyCallback={verifyCallback}
              />
            </Form>
          </Col>
          <Col span={24}>
            <Paragraph className="copyright">Copyright © 2022</Paragraph>
          </Col>
        </Row>
      </LoginTemplate>
      <Popup {...popupProps} />
    </>
  );
};

export default SignUp;
