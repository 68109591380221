import dayjs from "dayjs";

export const disabledEndDate = (startDate: any) => {
  let disabledDate = dayjs(startDate);

  if (startDate < dayjs()) disabledDate = dayjs();

  return disabledDate.subtract(1, "days");
};

export const numberWithCommas = (number: number) => {
  return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const getCookie = (cname) => {
  const name = cname + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const checkCookie = (cname) => {
  const user = getCookie(cname);
  if (user != "") {
    return true;
  } else {
    /*  user = prompt("Please enter your name:", "");
      if (user != "" && user != null) {
        setCookie("username", user, 365);
      } */
    return false;
  }
};

export const deleteCookie = (cname) => {
  document.cookie = cname + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export const configTimeSlot = [
  {
    id: 1,
    label: "15 Minute",
    value: 15,
  },
  {
    id: 2,
    label: "30 Minute",
    value: 30,
  },
  {
    id: 3,
    label: "45 Minute",
    value: 45,
  },
  {
    id: 4,
    label: "60 Minute",
    value: 60,
  },
];

export const currencyFormat = (num, symbol?) => {
  if (num)
    return (
      (symbol ?? "AED ") +
      num?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  else return "";
};


// Function to calculate width based on content length
export const calculateWidth = (text, padding = 20) => {
  const font = '14px Arial'; // Adjust the font size and family if needed
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;
  const textWidth = context.measureText(text).width;
  return textWidth + padding; // Add some padding for spacing
};

