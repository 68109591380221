import React, { useState, useEffect } from "react";
import { s3BucketUrl } from "../../../../../configs/constants";
import {
  Row,
  Col,
  Button,
  Card,
  Image,
  Typography,
  message,
  Upload,
  Space,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import PicturePlaceholder from "../../../../../assets/img/Picture-Placeholder.svg";
import MapImage from "../../../../../assets/img/map1.png";
import PropertyFigureChip from "../../../../atoms/PropertyFigureChip";
import {
  MaintenanceIcon,
  ScheduleIcon,
  LocationIcon,
} from "../../../../atoms/Icons";

const { Text, Title } = Typography;

export default ({ propertyDetails }) => {
  const { name, property_info, image } = propertyDetails;
  const [imageData, setImageData] = useState("");
  useEffect(() => {
    if (image && image.length) {
      const filtered = image.filter(
        (e) => e.media_purposeT_type === "profile_photo",
      );
      if (filtered.length) {
        const url = `${s3BucketUrl}/${filtered[0].data}`;
        setImageData(url);
      }
    }
  }, [image]);
  const [fileList, setFilelist] = useState([]);

  const profileprops = {
    name: "file",
    accept: "image/*",
    onChange(info) {
      setFilelist(info.fileList);
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      }
    },
  };

  return (
    <Card bordered={false}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Card
            bordered={false}
            className={`${fileList.length > 0 ? "card_coverImage" : ""}`}
            cover={
              image?.length > 0 ? (
                <Image alt="Pretty Photo" src={imageData} />
              ) : null
            }
          >
            <Space
              direction="vertical"
              align="center"
              size={12}
              className="w-100"
            >
              {fileList.length < 1 ? (
                <Image
                  style={{ marginTop: "12px" }}
                  src={PicturePlaceholder}
                  alt="Profile Pictuer"
                />
              ) : null}
              <Upload {...profileprops} fileList={fileList}>
                {fileList.length > 0 ? (
                  <Button
                    className="ag-gray-button-outline overButton"
                    icon={<EditOutlined />}
                  >
                    Change Photo
                  </Button>
                ) : (
                  <Button type="primary">Click to Upload</Button>
                )}
              </Upload>
              {fileList.length < 1 ? (
                <Text type="secondary" className="smallFont12">
                  File types accepted include: JPEG, PNG
                </Text>
              ) : null}
            </Space>
          </Card>
        </Col>
        <Col span={24}>
          <Space direction="vertical" size={16} className="w-100">
            <Title level={3} className="ag-primary mb-0">
              {name}
            </Title>
            <Space size={15}>
              <Text className="ag-primary RegularFont">
                <LocationIcon className="ag-primary" />
                {`${property_info?.length && property_info[0]?.city?.name},${
                  property_info?.length && property_info[0]?.country?.name
                }`}
              </Text>
            </Space>
          </Space>
        </Col>
        <Col span={24}>
          <PropertyFigureChip
            title="Visit Scheduling"
            figure={5}
            figureAddon="Visits"
            icon={<ScheduleIcon />}
            color="#A2D69E"
          />
        </Col>
        <Col span={24}>
          <PropertyFigureChip
            title="Maintenance Requests"
            figure={8}
            color="#447093"
            icon={<MaintenanceIcon />}
          />
        </Col>
        <Col span={24}>
          <Card
            className="card_coverImage"
            bordered={false}
            cover={<Image alt="Map" src={MapImage} />}
          >
            <Button
              className="ag-gray-button-outline overButton overButton-black"
              icon={<EditOutlined />}
            >
              Change Map
            </Button>
          </Card>
        </Col>
      </Row>
    </Card>
  );
};
