import * as React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ROOT } from "../configs/routeNames";
import "../styles/antd.less";
import "../styles/styles.scss";

import Pages from "./Pages";

const Root = () => (
  <Router>
    <Routes>
      <Route path="*" element={<Pages />} />
    </Routes>
  </Router>
);
export default Root;
