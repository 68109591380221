import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Typography, message, Space, Spin } from "antd";
import { useForm } from "react-hook-form";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { InputField } from "../../../atoms/FormElement";
import { adminService } from "../../../../configs/constants";
import axios from "../../../../utils/axiosInceptor";
import { useSelector, useDispatch } from "react-redux";
import { BsPlusCircle, BsArrowCounterclockwise } from "react-icons/bs";
import { getAllOrganizations } from "../../Organizations/ducks/actions";

const { Paragraph } = Typography;

const defaultValues: any = {
  name: "",
};

var _ = require("lodash");

export default (props) => {
  const { onUpdate, onchangeUpdate } = props;
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const dispatch: any = useDispatch();
  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues,
  });

  const subscription = useSelector(
    (state: any) => state.subscription.getAllSubscriptions
  );

  useEffect(() => {
    dispatch(getAllOrganizations());
  }, []);

  useEffect(() => {
    if (props.selected) {
      setValue("spname", props.selected.name);
      setValue("contactPersonName", props.selected.user.name);
      setValue("email", props.selected.user.email);
      setValue("mobile", props.selected.user.mobile);
    } else {
      reset();
    }
  }, [props.selected, subscription, props]);

  const onFinish = (values) => {
    props.onSubmit();
    reset();
    setLoader(true);
    setSubmited(true);
    let postJson;

    if (props.selected?.orgId) {
      postJson = {
        userName: values.contactPersonName,
      };
      const url = `${adminService}/serviceprovider/${props.selected.orgId}`;
      axios
        .patch(url, postJson, { headers: { application: 4 } })
        .then((response) => {
          setLoader(false);
          setSubmited(false);
          reset();
          message.success(response.data.message);
          onUpdate(!onchangeUpdate);
          props.onSubmit();
        })
        .catch((error) => {
          message.error(
            error.response.data?.error || error.response.data.message
          );
          setLoader(false);
          setSubmited(false);
          reset();
        });
    } else {
      postJson = {
        name: values.spname,
        userName: values.contactPersonName,
        userEmail: values.email,
        userCountryCode: "+971",
        userMobile: values.mobile,
      };

      const url = `${adminService}/serviceprovider`;

      axios
        .post(url, postJson, { headers: { application: 4 } })
        .then((response) => {
          setTimeout(() => {
            let { data } = response;
            setLoader(false);
            setSubmited(false);
            reset();
            message.success(data?.message);
            onUpdate(!onchangeUpdate);
            props.onSubmit();
          }, 3000);
        })
        .catch((error) => {
          message.error(
            error.response.data?.error || error.response.data?.message
          );
          setLoader(false);
          setSubmited(false);
          reset();
        });
    }
  };

  // var isDisabled = props.selected?.businessUnitId ? true : false;

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit(onFinish)}
      className="bu_o_form"
    >
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          <Row gutter={24}>
            <Col span={12}>
              <InputField
                control={control}
                isRequired={true}
                fieldname="spname"
                label="Service Provider Name"
                validate={errors.spname && "error"}
                validMessage={errors.spname && errors.spname.message}
                rules={{
                  required: "Please enter organization name",
                }}
                iProps={{
                  disabled: props.editMode,
                  placeholder: "Enter service provider name",
                }}
              />
            </Col>

            <Col span={12}>
              <InputField
                control={control}
                isRequired={true}
                fieldname="email"
                label="Service Provider Email"
                validate={errors.email && "error"}
                validMessage={errors.email && errors.email.message}
                iProps={{
                  disabled: props.editMode,
                  placeholder: "Enter email",
                }}
                rules={{
                  pattern: {
                    value: /(?=^.{1,50}$)^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    message: "Enter valid Email Address",
                  },
                  required: "Please enter Email Address",
                }}
              />
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <InputField
                isRequired={true}
                control={control}
                fieldname="mobile"
                validate={errors.mobile && "error"}
                label="Service Provider Mobile Number"
                validMessage={errors.mobile && errors.mobile.message}
                iProps={{
                  type: "number",
                  addonBefore: "+971",
                  className: "apperance",
                  disabled: props.editMode,
                  placeholder: "Add Mobile Number",
                }}
                rules={{
                  minLength: {
                    value: 9,
                    message:
                      "Please enter valid mobile number, 9 digits required",
                  },
                  maxLength: {
                    value: 10,
                    message: "Mobile number can not be more than 10 degits",
                  },
                  required: "Please enter mobile no.",
                  setValueAs: (value) => `${value}`,
                }}
              />
            </Col>
            <Col span={12}>
              <InputField
                control={control}
                isRequired={true}
                label="Contact Person Name"
                fieldname="contactPersonName"
                validate={errors.contactPersonName && "error"}
                iProps={{
                  placeholder: "Enter contact person name",
                }}
                rules={{
                  required: "Please enter contact person name",
                }}
                validMessage={
                  errors.contactPersonName && errors.contactPersonName.message
                }
              />
            </Col>
          </Row>

          <Row gutter={24} justify="space-between">
            <Col>
              <Button
                className="ag-error theme_grey_btn"
                htmlType="button"
                onClick={() => {
                  !props.selected && reset();
                  props.onCancel();
                }}
              >
                {<BsArrowCounterclockwise />} Back
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button theme_btn" htmlType="submit">
                {<BsPlusCircle />} {props.selected ? "Update" : "Add"}
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </Form>
  );
};
