import React from 'react';
import { Avatar, Card, List, Row, Col, Typography, Space } from 'antd';
import { RightOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;

const PropertyFigureChip = props => {

  return (
    <Card
      bodyStyle={{ padding: '10px 24px' }}
      style={{ borderRadius: 8 }}
    >
      <Row gutter={16} align="middle">
        <Col>
          <Avatar icon={props.icon} size={48} style={{ backgroundColor: props.color }} />
        </Col>
        <Col flex="1 1 auto">
          <Space direction="vertical" size={0}>
            <Text className="smallFont12" style={{ letterSpacing: '-0.41px' }}>{props.title}</Text>
            <Text className="fontSize18Bold ag-primary">{`${props.figure ? (props.figure.toString().length < 2 ? '0' + props.figure : props.figure) : '0'} ${props.figureAddon ? props.figureAddon : ''}`}</Text>
          </Space>
        </Col>
        <Col>
          <RightOutlined />
        </Col>
      </Row>
    </Card >
  );
};

export default PropertyFigureChip;
