import React, { useState } from "react";

import { Row, Col } from "antd";
import Services from "./components/Services";
import { useNavigate } from "react-router-dom";
import Notification from "./components/Notification";
import SectionHeader from "../../molecules/SectionHeader";
import DashboardLayout from "../../molecules/DashboardLayout";

const Configuration = () => {
  const [visible, setVisible]: any = useState(false);
  const [trigger, setTrigger]: any = useState(0);
  const [type, setType]: any = useState("SER");

  const history = useNavigate();

  const addConfiguration = () => {
    if (type === "TS") {
      setTrigger(0);
      setVisible(true);
    } else if (type === "NF") {
      history("/add-new");
    }
  };

  const changeConfigType = () => {
    switch (type) {
      // case "NF":
      //   return <Notification />;
      case "SER":
        return <Services />;
      // case "TS":
      //   return (
      //     <TimeSlots
      //       trigger={trigger}
      //       visible={visible}
      //       setTrigger={setTrigger}
      //       setVisible={setVisible}
      //     />
      //   );
      default:
        return <Notification />;
    }
  };

  return (
    <DashboardLayout>
      <SectionHeader
        noborder={true}
        heading="Configurations"
        // addNewAction={type != "SER" && addConfiguration}
      />
      <Row gutter={[24, 32]}>
        {/* <Col span={24}>
          <Radio.Group
            defaultValue="SER"
            buttonStyle="solid"
            onChange={(e) => setType(e.target.value)}
          >
            <Space size={12}>
              <Radio.Button value="NF">Notifications</Radio.Button>
              <Radio.Button value="SER">Services</Radio.Button>
              <Radio.Button value="TS">Time Slots</Radio.Button>
            </Space>
          </Radio.Group>
        </Col> */}
        <Col span={24}>{changeConfigType()}</Col>
      </Row>
    </DashboardLayout>
  );
};

export default Configuration;
