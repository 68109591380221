import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Card, Space, Typography, Divider } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const { Text, Title } = Typography;

const initialPropertyState = {
  name: "",
  status: "",
  builtIn: "",
  size: "",
  description: "",
  neighborhood: "",
  city: "",
  country: "",
  streetAddress: "",
  region: "",
  zip: "",
  unit: "",
  parking: "",
  threeSixtyDegreeTour: "",
  unitType: "",
  subUnitType: "",
  usageType: "",
  features: [],
  amenities: [],
  rentInfo: [],
};

const unitsMasterData = {
  1: "Residential",
  2: "Apartment",
  3: "Villa",
};
export default ({ propertyDetails }) => {
  const [propertyData, setPropertyData] = useState(initialPropertyState);
  console.log("propertyData inside", propertyData);

  useEffect(() => {
    if (Object.keys(propertyDetails).length) {
      const {
        name,
        builtIn,
        asset_status,
        asset_type,
        description,
        is_360tour_available,
        is_parking_available,
        property_amenities,
        property_features,
        size,
        rents,
        unit_subtype_id,
        unit_type_id,
        usage_type_id,
        property_info,
      } = propertyDetails;
      const dt = new Date(builtIn);
      const obj = {
        name: name,
        status: asset_status,
        builtIn: `${dt.getDate()}-${dt.getMonth() + 1}-${dt.getFullYear()}`,
        size: size,
        description: description,
        neighborhood: property_info[0]?.neighborhood,
        city: property_info[0]?.city?.name,
        country: property_info[0]?.country?.name,
        streetAddress: property_info[0]?.street_address,
        region: property_info[0]?.region?.name,
        zip: property_info[0]?.zipcode,
        unit: "",
        parking: is_parking_available === "true" ? "Yes" : "No",
        threeSixtyDegreeTour: is_360tour_available === "true" ? "Yes" : "No",
        unitType: unitsMasterData[unit_type_id],
        subUnitType: unitsMasterData[unit_subtype_id],
        usageType: "",
        features: property_features,
        amenities: property_amenities,
        rentInfo: rents,
      };
      setPropertyData(obj);
    }
  }, [propertyDetails]);

  return (
    <>
      <Card
        bordered={false}
        bodyStyle={{ padding: "16px 32px 0" }}
        headStyle={{ padding: "16px 32px 0", borderBottom: 0 }}
        title={
          <Title level={5} className="ag-primary mb-0">
            General Information
          </Title>
        }
        extra={
          <Link to="#">
            <EditOutlined className="ag-primary ag-fontSize24" />
          </Link>
        }
      >
        <Row gutter={[20, 24]}>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Name
              </Title>
              <Text className="ag-primary" strong>
                {propertyData?.name}
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Built in
              </Title>
              <Text className="ag-primary" strong>
                ----
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Size, Sq.ft
              </Title>
              <Text className="ag-primary" strong>
                {propertyData?.size}
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Status
              </Title>
              <Text className="ag-primary" strong>
                {propertyData.status}
              </Text>
            </Space>
          </Col>
          <Col span={24}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Description:
              </Title>
              <Text className="ag-primary lineHeight28" strong>
                {propertyData?.description}
              </Text>
            </Space>
          </Col>
        </Row>
      </Card>
      <Divider className="m-0" />
      <Card
        bordered={false}
        bodyStyle={{ padding: "16px 20px 0" }}
        headStyle={{ padding: "16px 20px 0", borderBottom: 0 }}
        title={
          <Title level={5} className="ag-primary mb-0">
            Property Information
          </Title>
        }
        extra={
          <Link to="#">
            <EditOutlined className="ag-primary ag-fontSize24" />
          </Link>
        }
      >
        <Row gutter={[20, 24]}>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Neighborhood
              </Title>
              <Text className="ag-primary" strong>
                {propertyData?.neighborhood}
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                City
              </Title>
              <Text className="ag-primary" strong>
                {propertyData?.city}
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Street Address
              </Title>
              <Text className="ag-primary" strong>
                {propertyData?.country}
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                State/ Region
              </Title>
              <Text className="ag-primary" strong>
                {propertyData?.region}
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Zip Code
              </Title>
              <Text className="ag-primary" strong>
                {propertyData?.zip}
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Country
              </Title>
              <Text className="ag-primary" strong>
                {propertyData?.country}
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Unit
              </Title>
              <Text className="ag-primary" strong>
                {propertyData?.unit}
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Parking
              </Title>
              <Text className="ag-primary" strong>
                {propertyData?.parking}
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Usage Type
              </Title>
              <Text className="ag-primary" strong>
                Residential
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Unit Type
              </Title>
              <Text className="ag-primary" strong>
                Apartments
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Unit Sub-Type
              </Title>
              <Text className="ag-primary" strong>
                {propertyData?.subUnitType}
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                360° Tour Available
              </Title>
              <Text className="ag-primary" strong>
                {propertyData?.threeSixtyDegreeTour}
              </Text>
            </Space>
          </Col>
        </Row>
        <Row gutter={[20, 24]}>
          <Col span={24}>
            <Title level={5} className="ag-primary mb-0">
              Features
            </Title>
          </Col>
        </Row>
        <Row gutter={[20, 24]}>
          {propertyData?.features?.map((e) => {
            return (
              <Col span={6}>
                <Title level={5} className="ag-secondary mb-0">
                  {e.label}
                </Title>
              </Col>
            );
          })}
        </Row>
        <Row gutter={[20, 24]}>
          <Col span={24}>
            <Title level={5} className="ag-primary mb-0">
              Amenities
            </Title>
          </Col>
        </Row>
        <Row gutter={[20, 24]}>
          {propertyData?.amenities?.map((e) => {
            return (
              <Col span={6}>
                <Title level={5} className="ag-secondary mb-0">
                  {e.label}
                </Title>
              </Col>
            );
          })}
        </Row>
      </Card>
      <Divider className="m-0" />
      <Card
        bordered={false}
        bodyStyle={{ padding: "16px 20px 0" }}
        headStyle={{ padding: "16px 20px 0", borderBottom: 0 }}
        title={
          <Title level={5} className="ag-primary mb-0">
            Rent Information
          </Title>
        }
      >
        <Row gutter={[20, 24]}>
          <Col span={6}>
            <Title level={5} className="ag-primary mb-0">
              Amount
            </Title>
          </Col>
          <Col span={6}>
            <Title level={5} className="ag-primary mb-0">
              Type
            </Title>
          </Col>
        </Row>
        {propertyData?.rentInfo?.map((e) => {
          return (
            <Row gutter={[20, 24]}>
              <Col span={6}>
                <Title level={5} className="ag-secondary mb-0">
                  {e.price}
                </Title>
              </Col>
              <Col span={6}>
                <Title level={5} className="ag-secondary mb-0">
                  {e.type}
                </Title>
              </Col>
            </Row>
          );
        })}
      </Card>
    </>
  );
};
