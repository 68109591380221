import axios from "../../../../utils/axiosInceptor";
import { adminService } from "../../../../configs/constants";
import {
  GET_SERVICE_PROVIDER,
  GET_ALL_SERVICE_PROVIDER,
  ADD_NEW_SERVICE_PROVIDER,
} from "./constants";

const serviceProvider = `${adminService}/serviceprovider`;
const serviceAllProviderList = `${adminService}/serviceprovider/list`;

// Get Service Provider List
export const getServicePrivider =
  (page: number = 1, serchText?) =>
  async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(
        `${serviceProvider}?page=${page}&${
          serchText ? `search=${serchText}` : ""
        }`
      );
      dispatch({
        type: GET_SERVICE_PROVIDER,
        data: result,
      });
    } catch (e) {
      console.log({ e });
    }
  };

// Get Service Provider Master List
export const getAllServicePrivider = () => async (dispatch) => {
  try {
    const {
      data: { result },
    } = await axios.get(serviceAllProviderList);
    dispatch({
      type: GET_ALL_SERVICE_PROVIDER,
      data: result,
    });
  } catch (e) {
    console.log({ e });
  }
};

// Add New Service Provider
export const addNewServicePrivider = (data: object) => async (dispatch) => {
  try {
    const {
      data: { result },
    } = await axios.post(`${serviceProvider}`, data);
    return dispatch({
      type: ADD_NEW_SERVICE_PROVIDER,
      data: result,
    });
  } catch (e) {
    console.log(e);
    return e;
  }
};
