import React from "react";
import { Routes, Route } from "react-router-dom";

// Screens
import Login from "../app/pages/Login";
import SignUp from "../app/modules/SignUp";
import ForgotPassword from "../app/modules/Login/ForgotPassword";
import ChangePassword from "../app/modules/Login/ChangePassword";
import Success from "../app/modules/Login/Success";
import Landlord from "../app/modules/Landlord";
import FacilityManager from "../app/modules/FacilityManager";
import Supervisors from "../app/modules/Supervisors";
import TenantListing from "../app/modules/Tenants";
import AllProperties from "../app/modules/AllProperties";
import PropertyDetail from "../app/modules/AllProperties/PropertyDetail";
import RoleAndAccess from "../app/modules/RoleAndAccess";
import ViewDetails from "../app/modules/Configurations/components/ViewDetails";
import Configurations from "../app/modules/Configurations";
import UserList from "../app/modules/RoleAndAccess/UserList";
import Users from "../app/modules/Users";
import RolePermissions from "../app/modules/RoleAndAccess/RolePermissions";
import AddNotification from "../app/modules/Configurations/components/AddNotification/index";
import CMSDetail from "../app/modules/Cms/components/CMSDetail";
import Dashbord from "../app/modules/Dashbord1";
import Organizations from "../app/modules/Organizations";
import Announcement from "../app/modules/Announcement";
import Tickets from "../app/modules/Organizations/Tickets";
import Subscriptions from "../app/modules/Subscriptions/index";
import Plans from "../app/modules/Plans/index";
import Cms from "../app/modules/Cms";
import AddCms from "../app/modules/Cms/components/addCMS";
import PropertyTickets from "../app/modules/AllProperties/PropertyTickets";
import SignUpNew from "../app/modules/SignUpNew";
import OTP from "../app/modules/SignUpNew/OTP";
import CreatePassword from "../app/modules/SignUpNew/CreatePassword";
import PostDatedCheques from "../app/modules/PostDatedCheques";
import Technicians from "../app/modules/Technicians";
import Protected from "./protected";
import Error from "../app/modules/Error";
import ServiceProvider from "../app/modules/ServiceProvider";
import ContactUs from "../app/modules/Contact Us";
import IndividualLandlord from "../app/modules/Individual Landlords";
import Properties from "../app/modules/Properties";
import ReportPage from "../app/pages/Reports";
const Pages = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<SignUp />} />
      <Route path="/signup" element={<SignUpNew />} />
      <Route path="/otp" element={<OTP />} />
      <Route path="/create-password" element={<CreatePassword />} />
      <Route path="/success" element={<Success />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="*" element={<Error />} />
      <Route
        path="/dashboard"
        element={
          <Protected>
            <Dashbord />
          </Protected>
        }
      />
        <Route
        path="/reports/:type"
        element={
          <Protected>
            <ReportPage />
          </Protected>
        }
      />
      <Route
        path="/supervisors"
        element={
          <Protected>
            <Supervisors />
          </Protected>
        }
      />
      <Route
        path="/configurations"
        element={
          <Protected>
            <Configurations />
          </Protected>
        }
      />
      <Route
        path="/add-new"
        element={
          <Protected>
            <AddNotification />
          </Protected>
        }
      />
      <Route
        path="/subscriptions"
        element={
          <Protected>
            <Subscriptions />
          </Protected>
        }
      />
      <Route
        path="/plans"
        element={
          <Protected>
            <Plans />
          </Protected>
        }
      />
      <Route
        path="/individual-landlord"
        element={
          <Protected>
            <IndividualLandlord />
          </Protected>
        }
      />
      <Route
        path="/properties/:id"
        element={
          <Protected>
            <Properties />
          </Protected>
        }
      />
      <Route path="/contactus" element={<ContactUs />} />
      <Route
        path="/view-details/:id"
        element={
          <Protected>
            <ViewDetails />
          </Protected>
        }
      />
      <Route
        path="/all-properties/:id"
        element={
          <Protected>
            <AllProperties />
          </Protected>
        }
      />
      <Route
        path="/landlord-management"
        element={
          <Protected>
            <Landlord />
          </Protected>
        }
      />
      <Route
        path="/property-detail/:id"
        element={
          <Protected>
            <PropertyDetail />
          </Protected>
        }
      />
      <Route
        path="/maintenance/:id"
        element={
          <Protected>
            <PropertyTickets />
          </Protected>
        }
      />
      <Route
        path="/user-list/:id"
        element={
          <Protected>
            <UserList />
          </Protected>
        }
      />
      <Route
        path="/users"
        element={
          <Protected>
            <Users />
          </Protected>
        }
      />
      <Route
        path="/users/:type"
        element={
          <Protected>
            <Users />
          </Protected>
        }
      />
      <Route
        path="/permissions"
        element={
          <Protected>
            <RolePermissions />
          </Protected>
        }
      />
      <Route
        path="/facility-managers"
        element={
          <Protected>
            <FacilityManager />
          </Protected>
        }
      />
      <Route
        path="/technicians"
        element={
          <Protected>
            <Technicians />
          </Protected>
        }
      />
      <Route
        path="/tenants/:id"
        element={
          <Protected>
            <TenantListing />
          </Protected>
        }
      />
      <Route
        path="/organizations"
        element={
          <Protected>
            <Organizations />
          </Protected>
        }
      />
      <Route
        path="/organization-tickets/:id"
        element={
          <Protected>
            <Tickets />
          </Protected>
        }
      />
      <Route
        path="/cms"
        element={
          <Protected>
            <Cms />
          </Protected>
        }
      />
      <Route
        path="/add-cms"
        element={
          <Protected>
            <AddCms />
          </Protected>
        }
      />
      <Route
        path="/cms/:id"
        element={
          <Protected>
            <CMSDetail />
          </Protected>
        }
      />
      <Route
        path="/roles-access-management"
        element={
          <Protected>
            <RoleAndAccess />
          </Protected>
        }
      />
      <Route
        path="/post-dated-cheques"
        element={
          <Protected>
            <PostDatedCheques />
          </Protected>
        }
      />
      <Route
        path="/announcement"
        element={
          <Protected>
            <Announcement />
          </Protected>
        }
      />

      <Route
        path="/serviceprovider"
        element={
          <Protected>
            <ServiceProvider />
          </Protected>
        }
      />
    </Routes>
  );
};

export default Pages;
