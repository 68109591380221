import { PROPERTIES, PROPERTY, PROPERTY_UPDATED } from "./constants";
import axios from "../../../../utils/axiosInceptor";
import { landlordProperties } from "../../../../configs/constants";

export const getLandlordProperties = (landlordId, search?, filter?) => async (dispatch) => {
  try {
    if (filter === 'type') filter = 'unitType'
    let url = `${landlordProperties}/boPropertyList?users=${landlordId}&${filter}=${search}`;

    const {
      data: { result },
    } = await axios.get(url);

    if (result) {
      dispatch({
        type: PROPERTIES,
        data: result,
      });
    }
  } catch (e) {
    console.log({ e });
  }
};

export const getLandlordProperty = (landlordId, unitId) => async (dispatch) => {
  try {
    
    let url = `${landlordProperties}/boPropertyList?users=${landlordId}&unitId=${unitId}`;

    const {
      data: { result },
    } = await axios.get(url);

    if (result) {
      dispatch({
        type: PROPERTY,
        data: result,
      });
    }
  } catch (e) {
    console.log({ e });
  }
};

export const updateLandlordProperty = (unitId, data) => async (dispatch) => {
  try {
    
    let url = `${landlordProperties}/individual/${unitId}`;

    const {
      data: { result },
    } = await axios.patch(url, data);

    if (result) {
      dispatch({
        type: PROPERTY_UPDATED,
        data: result,
      });
    }
  } catch (e) {
    console.log({ e });
  }
};
