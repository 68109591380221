import {
  LOGIN_INIT,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  UPDATE_VALUE,
  LOGOUT_SUCCESS,
  CONFIG_DATA,
} from "./constants";
import { LoginActions } from "./types";
import { AuthModel } from "./model";

import { CONFIG_KEY, TOKEN_KEY } from "../../../../configs";
import { saveToLocal, getFromLocal } from "../../../../utils/cache";

const initialState: AuthModel = {
  username: "",
  password: "",
  isLoading: false,
  isLoggedIn: false,
  configData: getFromLocal(CONFIG_KEY, true) || null,
  token: getFromLocal(TOKEN_KEY, false) || null,
};

const auth = (
  state: AuthModel = initialState,
  action: LoginActions
): AuthModel => {
  switch (action.type) {
    case LOGIN_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case LOGIN_SUCCESS: {
      const tokenData = saveToLocal(
        TOKEN_KEY,
        action.data.accessToken.jwtToken,
        false
      );
      return {
        ...state,
        token: tokenData || null,
        isLoading: false,
        isLoggedIn: true,
      };
    }

    case CONFIG_DATA: {
      const confidData = saveToLocal(CONFIG_KEY, action.data || null, true);
      return {
        ...state,
        configData: JSON.parse(confidData),
      };
    }

    case LOGOUT_SUCCESS: {
      return {
        ...initialState,
        token: null,
      };
    }

    case LOGIN_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case UPDATE_VALUE: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }

    default:
      return state;
  }
};
export default auth;
