import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Layout,
  Avatar,
  Dropdown,
  Menu,
  Image,
  Space,
  Button,
  Badge,
  Typography,
} from "antd";
import {
  UserOutlined,
  SearchOutlined,
  BellOutlined,
  LogoutOutlined,
  ContactsOutlined,
} from "@ant-design/icons";
import { actionLogout } from "./duck/actions";
import storeLogo from "../../../assets/img/eqari_logo.png";
import NotificationPanel from "../NotificationPanel";
import { deleteCookie } from "../../../utils/helper";
import { Link } from "react-router-dom";
import { onLogout } from "../../modules/Login/duck/actions";
import SubMenu from "antd/es/menu/SubMenu";
import { authService } from "../../../configs/constants";
import axios from "axios";

const { Header } = Layout;
const { Text } = Typography;

let cachedNotificationLength = 0;

const HeaderBar = () => {
  const dispatch: any = useDispatch();
  const history = useNavigate();
  const [notifications, setNotifications] = useState([]);
  let notificationInterval = null;

  const logoutHandler = async () => {
    const url = `${authService}/logout`;

    try {
      const response = await axios.post(url);

      if (response?.data?.success || response?.data?.code === 401) {
        window.localStorage.clear();
        deleteCookie("landlordUser");
        dispatch(onLogout());
        history("/login");
      }
    } catch (error) {
      window.localStorage.clear();
      deleteCookie("landlordUser");
      dispatch(onLogout());
      history("/login");
    }

  };

  
  const menu = (
    <Menu>
      {/* <SubMenu key="Maintenance" icon={<ContactsOutlined />} title="Contact Us"
      style={{}}
    >
        <Menu.Item key="getHelp">
          <Link to={"/contactus"} state={{ type: "Get help" }}>
            Get help
          </Link>
        </Menu.Item>
        <Menu.Item key="reportProblem">
          <Link to={"/contactus"} state={{ type: "Report a problem" }}>
            Report a problem
          </Link>
        </Menu.Item>
        <Menu.Item key="requestFeature">
          <Link to={"/contactus"} state={{ type: "Request a feature" }}>
            Request a feature
          </Link>
        </Menu.Item>
        <Menu.Item key="requestDeactivation">
          <Link to={"/contactus"} state={{ type: "Request deactivation" }}>
            Request deactivation
          </Link>
        </Menu.Item>
      </SubMenu> */}
      <Menu.Item>
        <Button
          type="link"
          className="btn-link"
          icon={<LogoutOutlined />}
        >
          Contact Us
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button
          onClick={logoutHandler}
          type="link"
          className="btn-link"
          icon={<LogoutOutlined />}
        >
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );

  const Notification: any = NotificationPanel;

  return (
    <Header className="site-header">
      <Row>
        <Col flex={200}>
          <Image
            src={storeLogo}
            preview={false}
            width={150}
            alt="EQARCOM logo"
            style={{ marginTop: 5 }}
          />
        </Col>
        <Col flex="auto">
          <Space size={12}>
            {/* <Dropdown
                overlay={<Notification notificationsData={notifications} />}
                placement="bottomRight"
                trigger={["click"]}
                arrow
              >
                <Button
                  type="link"
                  className="headerIcon-btn"
                  size="large"
                  onClick={() => setSeen(false)}
                >
                  <BellOutlined />
                </Button>
              </Dropdown> */}
            <Dropdown 
              overlay={menu} 
              placement="bottomRight"
              // overlayStyle={{minWidth: '197px', left: '1328px', top: '60px'}}
            >
              <Space style={{paddingTop: '10px', paddingRight: '12px'}}>
                <Avatar
                  size={24}
                  icon={<UserOutlined />}
                  style={{ backgroundColor: "#184461", color: "#fff" }}
                />
                <Text
                  style={{
                    color: "#184461",
                  }}
                >
                  {JSON.parse(localStorage.getItem("login-response"))?.email}
                </Text>
              </Space>
            </Dropdown>
          </Space>
        </Col>
      </Row>
    </Header>
  );
};

export default HeaderBar;
