
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Typography,
  Form,
  Popover,
  Button,
  Progress,
  message,
} from "antd";
import LoginTemplate from "../Login/LoginTemplate";
import axios from "axios";
import { authService } from "../../../configs/constants";
import { useForm } from "react-hook-form";
import { InputField, InputPassword } from "../../atoms/FormElement";
import { setCookie } from "../../../utils/helper";
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";
import { SITEKEY } from "../../../configs/constants";

const { Text } = Typography;

const CreatePassword = (props) => {
  const [loading, setLoading] = useState(false);
  const [tokenCapcha, setReCapcha] = useState();
  const history = useNavigate();
  const refReCaptcha = useRef(null);
  const {
    control,
    handleSubmit, formState: { errors },
    watch,
    formState,
    getValues,
  } = useForm({ mode: "onChange" });
  const { isValid } = formState;
  let checkPassword = watch("password");

  const verifyCallback = (token) => {
    // console.log("recaptch", token);
    setReCapcha(token);
  };

  React.useEffect(() => {
    loadReCaptcha(SITEKEY);
  }, []);

  const content = (
    <div>
      <Progress
        percent={30}
        size="small"
        status="exception"
        showInfo={false}
        style={{ width: "230px" }}
      />
      <ul className="ag-unordered-list">
        <li>One Upper Case letter</li>
        <li>One Lower Case letter</li>
        <li>One Number</li>
        <li>One Special Charector</li>
      </ul>
    </div>
  );

  // const onFinish = async (values) => {
  //   setLoading(true)
  //   const { usercode, password } = values;
  //   const url = `${authService}/user/reset-password`;
  //   const usr = window.localStorage.getItem("usernameForCode");

  //   const postJson = {
  //     username: usr,
  //     password: password,
  //     code: usercode,
  //   };

  //   try {
  //     await axios.post(url, postJson);
  //     message.success("Password successfully updated")
  //     setTimeout(() => history("/login"), 3000);
  //   } catch (e) {
  //     setLoading(false)
  //     const { response } = e;
  //     message.error(response?.data?.result?.error)
  //   }
  // };

  const onFinish = async (values) => {
    const { password } = values;
    setLoading(true);
    const headers = {
      application: 5,
    };

    const postJson = {
      code: props.location["state"]?.verification_code,
      password: password,
      recapcha: tokenCapcha,
    };
    const url = `${authService}/user/set-password`;
    try {
      const response = await axios.post(url, postJson, { headers: headers });
      await window.localStorage.setItem(
        "login-response",
        JSON.stringify(response?.data?.result),
      );

      setLoading(false);
      setCookie(
        "landlordUser",
        true,
        new Date(response?.data?.result?.exp).getDate(),
      );
      history("/dashboard");
    } catch (e) {
      setLoading(false);
      refReCaptcha.current.execute();
      const { response } = e;
      message.error(response?.data?.result?.error);
    }
  };

  return (
    <LoginTemplate load={loading}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Text className="defaultFont fontWeight600 green-color">
            Create a password
          </Text>
        </Col>
        <Col span={24}>
          <Form name="normal_login" onFinish={handleSubmit(onFinish)}>
            <Popover
              placement="rightTop"
              title="Password must contain: 8 characters"
              content={content}
              trigger="click"
              visible={errors.password ? true : false}
            >
              <InputPassword
                isRequired={true}
                fieldname="password"
                control={control}
                initValue=""
                iProps={{ placeholder: "Create Password", size: "large" }}
                rules={{
                  pattern: {
                    value: /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^!-_&*+=]).*$/,
                    //value: /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^!&*+=]).*$/,
                    // message: "Passwords must contain at least 8 characters, 1 capital letter, 1 number and a special character",
                  },
                  required: "Please enter password",
                }}
                validate={errors.password && "error"}
                // validMessage={errors.password && errors.password.message}
              />
            </Popover>
            <InputPassword
              isRequired={true}
              fieldname="confirm"
              control={control}
              initValue=""
              iProps={{ placeholder: "Confirm Password", size: "large" }}
              rules={{
                required: "Please confirm your password!",
                validate: (value) =>
                  value === checkPassword || "The passwords do not match",
              }}
              validate={errors.confirm && "error"}
              validMessage={errors.confirm && errors.confirm.message}
            />
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                size="large"
              >
                Sign Up
              </Button>
            </Form.Item>
            <ReCaptcha
              action="main"
              sitekey={SITEKEY}
              ref={refReCaptcha}
              verifyCallback={verifyCallback}
            />
          </Form>
        </Col>
      </Row>
    </LoginTemplate>
  );
};

export default CreatePassword;
