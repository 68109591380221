import axios from '../../../utils/axiosInceptor'
import { getOrgnicationList,getOnlinePaymentsList,getCourierPaymentsList } from '../../../configs/constants'

export const getOrginationListService = async ()=>{
    try {
        const {data} = await axios.get(`${getOrgnicationList}`);
       
       return data?.result;
      
      } catch (e) {
        console.log({ e });
      }
  
}


//  get online Reports data  Courier

export const getOnlineReportListService = async (payload)=>{


  try {
    const {data} = await axios.post(`${getOnlinePaymentsList}`,payload);
  
   return data;
  
  } catch (e) {
    console.log({ e });
  }
}

//  get online Reports data  Courier

export const getCourierReportListService = async (payload)=>{


   try {
     const {data} = await axios.post(`${getCourierPaymentsList}`,payload);
   
    return data;
   
   } catch (e) {
     console.log({ e });
   }
 }
 