import { Button, Col, Row, Space, Spin, message, Typography, Divider  } from "antd";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "../../../../utils/axiosInceptor";
import { propertyService } from "../../../../configs/constants";
import { BsArrowCounterclockwise } from "react-icons/bs";
import { DeleteOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const { Text } = Typography;

const RemoveProperty = (props) => {
  const [loader, setLoader] = useState(false);
  const [property, setProperty] = useState(props.selected);
  const [isError, setisError] = useState(false);

  const onFinish = () => {
    
    setLoader(true);
    
    const url = `${propertyService}/property/remove/${property.unitId}`;

      axios
        .delete(url, { headers: { application: 4 } })
        .then((response) => {
          let { data } = response;
          setLoader(false);
          message.success(data?.message);
          props.onRemove();
        })
        .catch((error) => {
          message.error(
            error.response.data?.error || error.response.data?.message
          );
          setLoader(false);
        });
  
  };

  return (
    <>
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
            </Space>
          </Col>
        </Row>
      )}{" "}
      <Row gutter={24}>
        <Col>
          <Divider /> 
        </Col>
        <Col xl={24}>
          <Text strong>{`Do you want to remove "${property.propertyName}" from the property list ?`}</Text>
        </Col>
        <Col>
          <Divider /> 
        </Col>
      </Row>

      <Row gutter={24} justify="space-between">
        <Col>
          <Button
            className="ag-error theme_grey_btn"
            htmlType="button"
            onClick={() => {
              props.onCancel();
            }}
          >
            {<BsArrowCounterclockwise />} Cancel
          </Button>
        </Col>
        <Col>
          <Button className="ag-green-button theme_btn" onClick={() => {
              onFinish()
            }}>
            {<DeleteOutlined />} Remove
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default RemoveProperty;
