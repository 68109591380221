import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import AddPlan from "./AddSubscription";
import { Popup } from "../../atoms/Popup";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { EditOutlined } from "@ant-design/icons";
import axios from "../../../utils/axiosInceptor";
import { getSubscriptions } from "./ducks/actions";
import SectionHeader from "../../molecules/SectionHeader";
import { adminService } from "../../../configs/constants";
import DashboardLayout from "../../molecules/DashboardLayout";

import {
  Col,
  Row,
  Space,
  Table,
  Switch,
  Button,
  message,
  Typography,
} from "antd";

const { Title } = Typography;

const Subscriptions = (props) => {
  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState();
  const [updates, setUpates] = useState(false);

  const dispatch: any = useDispatch();

  const subscription = useSelector(
    (state: any) => state.subscription.getSubscriptions
  );

  useEffect(() => {
    if (subscription.length == 0) dispatch(getSubscriptions(1, search));
  }, [subscription]);

  useEffect(() => {
    const filterData = () => {
      setPage(1);
      dispatch(getSubscriptions(1, search));
    };

    const debouncer = setTimeout(() => {
      filterData();
    }, 1500);
    return () => {
      clearTimeout(debouncer);
    };
  }, [search]);

  const addNew = () => {
    setVisible(true);
    setTrigger(0);
  };

  const onEdit = (record) => {
    setVisible(true);
    setTrigger(1);
    setSelected(record);
  };

  const onAdd = () => {
    setVisible(false);
    dispatch(getSubscriptions(1, search));
  };

  const onChange = (checked: boolean, data: any) => {
    let status = "";

    switch (data.status) {
      case "enable":
        status = "disable";
        break;
      case "disable":
        status = "enable";
        break;
      default:
        status = "enable";
        break;
    }

    axios({
      url: `${adminService}/subscription/${data.subscriptionId}`,
      method: "PATCH",
      data: { status },
      headers: { application: 4 },
    })
      .then((response) => {
        message.success(response.data.message);
        dispatch(getSubscriptions(1, search));
      })
      .catch((error) => {
        message.error(
          error.response.data?.error || error.response.data.message
        );
      });
  };

  const columns: any = [
    {
      title: "Organization Name",
      dataIndex: "organisationName",
      key: "organisationName",
      width: 220,
    },
    {
      title: "Plan Name",
      dataIndex: "planName",
      key: "planName",
      width: 220,
    },
    {
      title: "User(s)",
      dataIndex: "numberOfUser",
      key: "numberOfUser",
      width: 120,
    },
    {
      title: "Unit(s)",
      dataIndex: "unitSubscribed",
      key: "unitSubscribed",
      width: 120,
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      width: 150,
      render: (date: string) => dayjs(date).format("DD-MM-YYYY"),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      width: 150,
      render: (date: string) => {
        return dayjs(date).subtract(1, "days").format("DD-MM-YYYY");
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 150,
      render: (text, record) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => onEdit(record)}
              className="p-0 ag-success"
            >
              <div className="Simple-tooltip">
                <EditOutlined />
                <section className="tt-content">Edit</section>
              </div>
            </Button>

            {/* Enable disable switch */}
            <div className="Simple-tooltip">
              <Switch
                className="switch"
                onChange={(e) => onChange(e, record)}
                defaultChecked={record.status === "enable" ? true : false}
              />
              <section className="tt-content">{record.status}</section>
            </div>
          </Space>
        );
      },
    },
  ];

  const popup = [
    {
      title: (
        <Title level={3} className="m-0">
          Add New Subscription
        </Title>
      ),
      content: (
        <AddPlan
          onSubmit={onAdd}
          onCancel={() => setVisible(false)}
          onUpdate={setUpates}
          onchangeUpdate={updates}
          editMode={false}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="m-0">
          Edit Subscription
        </Title>
      ),
      content: (
        <AddPlan
          onSubmit={onAdd}
          selected={selected}
          onCancel={() => setVisible(false)}
          onUpdate={setUpates}
          onchangeUpdate={updates}
          editMode={true}
        />
      ),
      width: 650,
    },
  ];

  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => setVisible(false),
  };

  const onTopSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <DashboardLayout>
        <SectionHeader
          heading="Subscriptions"
          addNewAction={addNew}
          // search={onTopSearch}
        />
        <Row gutter={24}>
          <Col xl={24}>
            <Table
              className="custom-table org_table_wrap"
              columns={columns}
              pagination={{
                current: subscription?.currentPage,
                pageSize: subscription?.perPage,
                showSizeChanger: false,
                className: "ag-property-text-pagination mb-1",
                position: ["topLeft"],
                onChange: (e) => {
                  setPage(e);
                  dispatch(getSubscriptions(e, search));
                },
                total: subscription?.totalCount,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} Subscriptions`,
              }}
              dataSource={subscription?.data}
              scroll={{ y: 500 }}
              rowKey={(item) => item.startDate}
            />

            {/* CARD FORMAT Subscriptions  */}
            {/* <Row gutter={24}>
            <Col span={8}>
             <PlanPanel button={{
               backgroundColor:"#447093",
               text:"You are using this plan currently."
               }}/>
            </Col>
            <Col span={8}>
            <PlanPanel button={{
              backgroundColor:"#044C26",
              text:"Upgrade to this plan."
              }}/>
            </Col>
            <Col span={8}>
            <PlanPanel button={{
              backgroundColor:"#044C26",
              text:"Upgrade to this plan."
              }}/>
            </Col>
          </Row> */}
          </Col>
        </Row>
      </DashboardLayout>
      <Popup {...popupProps} />
    </>
  );
};

export default Subscriptions;
