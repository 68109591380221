import { LANDLORDS } from "./constants";
import axios from "../../../../utils/axiosInceptor";
import { individualLanlord } from "../../../../configs/constants";

export const getIndividualLandlords =
  (page = 1, searchBy?, searchText?) =>
  async (dispatch) => {
    try {
      let url = `${individualLanlord}/individualLandlord?page=${page}`;
      url += searchBy ? `&searchby=${searchBy}` : "";
      url += searchBy && searchText ? `&text=${searchText}` : "";

      const {
        data: { result },
      } = await axios.get(url);

      if (result) {
        dispatch({
          type: LANDLORDS,
          data: result,
        });
      }
    } catch (e) {
      console.log({ e });
    }
  };
