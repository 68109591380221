import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Typography,
  Form,
  Popover,
  Button,
  Progress,
  message,
} from "antd";
import LoginTemplate from "./LoginTemplate";
import axios from "axios";
import { authService } from "../../../configs/constants";
import { useForm } from "react-hook-form";
import { InputField, InputPassword } from "../../atoms/FormElement";
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";
import { SITEKEY } from "../../../configs/constants";

const { Text } = Typography;

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const history = useNavigate();
  const [tokenCapcha, setReCapcha] = useState();
  const refReCaptcha = useRef(null);

  const {
    control,
    handleSubmit,
    formState : {errors},
    watch,
    formState,
    getValues,
  } = useForm({ mode: "onChange" });
  const { isValid } = formState;
  let checkPassword = watch("password");

  const content = (
    <div>
      <Progress
        percent={30}
        size="small"
        status="exception"
        showInfo={false}
        style={{ width: "230px" }}
      />
      <ul className="ag-unordered-list">
        <li>One Upper Case letter</li>
        <li>One Lower Case letter</li>
        <li>One Number</li>
        <li>One Special Charector</li>
      </ul>
    </div>
  );

  const getUserNameFromEmail = (email: string) => {
    let userNameAndDomain = email.split("@");
    let username = "";
    if (userNameAndDomain.length == 2) {
      username = userNameAndDomain[0] + userNameAndDomain[1].replace(".", "");
    }
    return username;
  };

  const verifyCallback = (token) => {
    // console.log("recaptch", token);
    setReCapcha(token);
  };

  React.useEffect(() => {
    loadReCaptcha(SITEKEY);
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    const { usercode, password } = values;
    const url = `${authService}/user/reset-password`;
    const usr = window.localStorage.getItem("usernameForCode");
    // let user = usr;
    // if (usr.includes("@")) {
    //   user = getUserNameFromEmail(user).toLocaleLowerCase();
    // }

    const postJson = {
      username: usr,
      password: password,
      code: usercode,
      recapcha: tokenCapcha,
    };

    try {
      await axios.post(url, postJson);
      message.success("Password successfully updated");
      setTimeout(() => history("/login"), 3000);
    } catch (e) {
      setLoading(false);
      refReCaptcha.current.execute()
      const { response } = e;
      message.error(response?.data?.result?.error);
    }
  };

  return (
    <LoginTemplate load={loading}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Text className="ag-primary RegularFont">Change Password</Text>
        </Col>
        <Col span={24}>
          <Form name="normal_login" onFinish={handleSubmit(onFinish)}>
            <InputField
              isRequired={true}
              fieldname="usercode"
              control={control}
              initValue=""
              iProps={{
                placeholder: "Enter code",
                size: "large",
                type: "number",
              }}
              rules={{
                required: "Please input your Code!",
                min: {
                  value: 0,
                  message: "Please input correct code!",
                },
                maxLength: {
                  value: 6,
                  message: "Please input correct code!",
                },
              }}
              validate={errors.usercode && "error"}
              validMessage={errors.usercode && errors.usercode.message}
            />
            <Popover
              placement="rightTop"
              title="Password must contain: 8 characters"
              content={content}
              trigger="click"
              visible={errors.password ? true : false}
            >
              <InputPassword
                isRequired={true}
                fieldname="password"
                control={control}
                initValue=""
                iProps={{ placeholder: "Password", size: "large" }}
                rules={{
                  pattern: {
                    value: /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^!-_&*+=]).*$/,
                    //value: /^(?!.*[\s])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,50}$/,
                    // message: "Passwords must contain at least 8 characters, 1 capital letter, 1 number and a special character",
                  },
                  required: "Please enter password",
                }}
                validate={errors.password && "error"}
                // validMessage={errors.password && errors.password.message}
              />
            </Popover>
            <InputPassword
              isRequired={true}
              fieldname="confirm"
              control={control}
              initValue=""
              iProps={{ placeholder: "Re-enter new password", size: "large" }}
              rules={{
                required: "Please confirm your password!",
                validate: (value) =>
                  value === checkPassword || "The passwords do not match",
              }}
              validate={errors.confirm && "error"}
              validMessage={errors.confirm && errors.confirm.message}
            />
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                size="large"
                disabled={!isValid}
              >
                Change Password
              </Button>
            </Form.Item>
            <ReCaptcha
              action="main"
              ref={refReCaptcha}
              sitekey={SITEKEY}
              verifyCallback={verifyCallback}
            />
          </Form>
        </Col>
      </Row>
    </LoginTemplate>
  );
};

export default ChangePassword;
