
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Typography, Form, Button, Select } from "antd";
import LoginTemplate from "./LoginTemplate";
import { useForm, Controller } from "react-hook-form";
import { InputField } from "../../atoms/FormElement";

const { Title } = Typography;
const { Option } = Select;

const Success = () => {
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const onSubmit = (values) => {
    console.log(values);
    history("/organizations");
  };

  const { control, handleSubmit, formState: { errors } } = useForm();

  return (
    <LoginTemplate load={loading}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Title level={5} className="ag-primary highlight text-center">
            You have successfully signed up! We would like a few more details to
            set you up:
          </Title>
        </Col>
        <Col span={24}>
          <Form
            name="normal_signup"
            layout="vertical"
            onFinish={handleSubmit(onSubmit)}
          >
            <InputField
              isRequired={true}
              fieldname="work_add"
              label="Where do you work?"
              control={control}
              initValue=""
              iProps={{ placeholder: "Al Ghurair", size: "large" }}
              rules={{
                required: "Please provide work place",
              }}
              validate={errors.work_add && "error"}
              validMessage={errors.work_add && errors.work_add.message}
            />
            <Form.Item
              required={true}
              label="Select an account type">
              <Controller
                name="account_type"
                control={control}
                defaultValue="landlord"
                render={ () => (
                  <Select placeholder="Select an account type" size="large">
                    <Option value="landlord">Landlord</Option>
                    <Option value="facility">Facility Manager</Option>
                  </Select>
                )}
              />
            </Form.Item>

            <Button
              type="primary"
              htmlType="submit"
              className="signup-form-button"
              size="large"
            >
              Let’s go!
            </Button>
          </Form>
        </Col>
      </Row>
    </LoginTemplate>
  );
};

export default Success;
