import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Card,
  Col,
  Form,
  Typography,
  message,
  Upload,
  Input,
  Spin,
} from "antd";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { useForm, Controller } from "react-hook-form";
import draftToHtml from "draftjs-to-html";
import { useNavigate } from "react-router-dom";
import BreadCrumb from "../../../atoms/BreadCrumb";
import DashboardLayout from "../../../molecules/DashboardLayout";
import { InputField } from "../../../atoms/FormElement";
import axios from "../../../../utils/axiosInceptor";
import { adminService } from "../../../../configs/constants";
import { SelectField } from "../../../atoms/FormElement";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { getContentCategories } from "../ducks/actions";
import { useDispatch, useSelector } from "react-redux";
import { getSingleUpload, uniquiFileName } from "../../../../utils/fileUpload";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined spin />;
const breadcrumbList: any = [
  {
    label: "CMS",
    route: "/cms",
  },
  {
    label: "Create New",
  },
];

const defaultValues: any = {
  key: "",
  name: "",
  cms_categories: "",
  meta_desc: "",
  cms_media: "",
  cms_media_type: "",
};

const dummyRequest = (response) => {
  console.log("check response", response);
  setTimeout(() => {
    response.onSuccess("ok");
  }, 0);
};

const { Paragraph, Title } = Typography;

const AddCms = () => {
  const history = useNavigate();
  const dispatch:any = useDispatch();
  const [editorData, setEditorData] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const contentCategories = useSelector((state:any) => state.cms.categoriesData);
  const { control, formState: { errors }, handleSubmit, setValue } = useForm({
    defaultValues,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getContentCategories());
  }, []);

  const onEditorStateChange = (editorState) => {
    const data = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setEditorData(data);
    setEditorState(editorState);
  };

  const onFinish = async (values) => {
    setLoading(true);
    if (editorState.getCurrentContent().hasText() == false) {
      message.error("Body is not allowed to be empty");
      setLoading(false);
      return false;
    }
    let imageResp = null;
    let modifiedName = uniquiFileName(
      values.cms_media.fileList[0].originFileObj.name,
    );
    imageResp = await getSingleUpload(
      modifiedName,
      values.cms_media.fileList[0].originFileObj,
    );
    const { name } = values;
    let cat_id = values.cms_categories.map((value, key) => ({
      id: value.value,
    }));
    const postJson = {
      name,
      cms_categories: cat_id,
      meta_tag: "#Lorem,#Ipsum, #six",
      meta_desc: editorData,
      cms_media_type: "image",
      cms_media: modifiedName,
    };

    if (imageResp) {
      const url = `${adminService}/cms/add`;
      try {
        await axios.post(url, postJson, { headers: { application: 4 } });
        setTimeout(() => {
          message.success("added successfully");
          setLoading(false);
          history("/cms");
        }, 3000);
      } catch (e) {
        const { response } = e;
        console.log("checasdk", response);
        setLoading(false);
        message.error(response?.data?.result);
      }
    } else {
      setLoading(false);
      message.error("Image Not Uploaded. Please try again Later");
    }
  };

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <DashboardLayout>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <BreadCrumb name="CMS" link="/cms" list={breadcrumbList} />
          </Col>
          <Col span={24}>
            <Title level={2}>Add details to create new</Title>
          </Col>
          <Col span={24}>
            <Card
              bordered={false}
              bodyStyle={{ padding: "32px 32px" }}
              className="h-auto"
            >
              <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
                <Row gutter={24}>
                  {/* <Col span={24}>
                    <Title level={3}>Add Details</Title>
                  </Col> */}
                  <Col span={24}>
                    <Row gutter={24}>
                      <Col span={8}>
                        <Form.Item
                          required={true}
                          label="Upload Feature Image"
                          validateStatus={errors.cms_media && "error"}
                          // help={errors.cms_media && errors.cms_media.message}
                        >
                          <Controller
                            name="cms_media"
                            control={control}
                            rules={{ required: "Profile image required" }}
                            defaultValue=""
                            render={({ field: {value, onChange} }) => (
                              <Upload
                                showUploadList={false}
                                accept="image/*"
                                maxCount={1}
                                customRequest={dummyRequest}
                                onChange={(e) => onChange(e)}
                                className="uploadWithbtn"
                              >
                                <Input
                                  size="large"
                                  value={
                                    value
                                      ? value.fileList[0].name
                                      : "Click to Choose file"
                                  }
                                  addonAfter="Browse"
                                />
                              </Upload>
                            )}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                    <InputField
                      isRequired={true}
                      fieldname="name"
                      label="Title / Subject"
                      control={control}
                      rules={{
                        required: "Please enter Title (or) Subject",
                      }}
                      initValue=""
                      validate={errors.name && "error"}
                      validMessage={errors.name && errors.name.message}
                    />
                  </Col>
                  <Col span={8}>
                    {contentCategories && (
                      <SelectField
                        isRequired={true}
                        fieldname="cms_categories"
                        label="Categories"
                        control={control}
                        iProps={{
                          placeholder: "Select categories",
                          isMulti: true,
                        }}
                        rules={{ required: "Please select categories" }}
                        valueGot={""}
                        selectOption={contentCategories.map((e) => {
                          return { value: e.id, label: e.name };
                        })}
                        setValue={setValue}
                        validate={errors.cms_categories && "error"}
                        validMessage={
                          errors.cms_categories && errors.cms_categories.message
                        }
                      />
                    )}
                  </Col>

                    </Row>
                  </Col>
                 
                  <Col span={24}>
                    <Paragraph className="ag-primary">Body</Paragraph>
                    <Editor
                      editorState={editorState}
                      wrapperClassName="editor-main"
                      editorClassName="editor-body"
                      onEditorStateChange={onEditorStateChange}
                    />
                  </Col>
                  <Col span={24} className="mt-1">
                    <Row justify="space-between">
                      <Button size="large" className="theme_grey_btn" onClick={() => history("/cms")}>
                        Cancel
                      </Button>
                      <Button htmlType="submit" type="primary" size="large">
                        Publish
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </DashboardLayout>
    </Spin>
  );
};

export default AddCms;
