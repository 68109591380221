import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Button,
  Popover,
  message,
  Checkbox,
  Typography,
} from "antd";
import {
  UserOutlined,
  LockOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { getConfigData } from "./duck/actions";
import { setCookie } from "../../../utils/helper";
import LoginTemplate from "./LoginTemplate";
import axios from "axios";
import { authService } from "../../../configs/constants";
import { useForm } from "react-hook-form";
import { InputField, InputPassword } from "../../atoms/FormElement";
import { CONFIG_DATA } from "./duck/constants";

const { Text, Paragraph } = Typography;

export default (props) => {
  const [loading, setLoading] = useState(false);
  const [loginStatus, setLoginStatus] = useState(false);
  const [loginStatus1, setLoginStatus1] = useState(false);
  // const [tokenCapcha, setReCapcha] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch: any = useDispatch();
  // const [configData, setconfigData] = useState({});
  let token = JSON.parse(window.localStorage.getItem("login-response"))?.token;
  const navigate = useNavigate();
  const history = useNavigate();

  React.useEffect(() => {
    token && history("/dashboard");
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const onFinish = async (values) => {
    setLoginStatus(false);
    setLoading(true);
    const url = `${authService}/admin/signin`;

    const postJson = {
      email: values.username,
      password: values.password,
      // recapcha: tokenCapcha,
    };
    const headers = {
      application: 5,
    };
    try {
      const {
        data: { result },
      } = await axios.post(url, postJson, { headers: headers });
      await new Promise((resolve, reject) => {
        localStorage.setItem("login-response", JSON.stringify(result));
        resolve("");
      });

      // dispatch({
      //   type: CONFIG_DATA,
      //   data: result?.configData,
      // });
      setCookie("landlordUser", true, new Date(result?.exp).getDate());
      localStorage.setItem("deviceId", uuidv4());
      setTimeout(() => {
        navigate("/dashboard");
        dispatch(getConfigData("emiratesList,businessUnitTypes"));
      }, 2000);
    } catch (error) {
      console.log(error);
      setLoading(false);
      const { response } = error;
      // refReCaptcha.current.execute()
      if (response?.data?.error) {
        setErrorMessage(response?.data?.error);
        if (response?.data?.message.search("password") > 0) {
          setLoginStatus(true);
        } else {
          setLoginStatus1(true);
        }
      } else {
        setLoginStatus(false);
        setLoginStatus1(false);
        message.error("Invalid Attempt");
      }
    }
  };

  return (
    <LoginTemplate load={loading}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          {/* <Link to="/login" className="btn-tab-style active">
            Sign in
          </Link>
          {/* <Link to="/signup" className="btn-tab-style">
            Sign up
          </Link> */}
          <strong>Sign in with your account</strong>
        </Col>
        <Col span={24}>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={handleSubmit(onFinish)}
          >
            <Popover
              placement="right"
              content={
                <Text type="danger">
                  <InfoCircleOutlined /> {errorMessage}
                </Text>
              }
              onOpenChange={() => setLoginStatus1(false)}
              trigger="click"
              open={loginStatus1}
            >
              <InputField
                fieldname="username"
                control={control}
                isRequired={true}
                initValue=""
                iProps={{
                  placeholder: "Username / Email",
                  size: "large",
                  prefix: <UserOutlined className="site-form-item-icon" />,
                }}
                rules={{
                  required: "Please enter email address",
                  pattern: {
                    value: /(?=^.{1,50}$)^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    message: "Enter valid Email Address!",
                  },
                }}
                validate={errors.username && "error"}
                validMessage={errors.username && errors.username.message}
              />
            </Popover>
            <Popover
              placement="right"
              content={
                <Text type="danger">
                  <InfoCircleOutlined /> {errorMessage}
                </Text>
              }
              onOpenChange={() => setLoginStatus(false)}
              trigger="click"
              open={loginStatus}
            >
              <InputPassword
                fieldname="password"
                control={control}
                initValue=""
                iProps={{
                  placeholder: "Password",
                  size: "large",
                  prefix: <LockOutlined className="site-form-item-icon" />,
                }}
                rules={{
                  pattern: {
                    value: /(?=^.{1,50}$)^\S*$/,
                    message: "Please enter correct password",
                  },
                  required: "Please enter password",
                }}
                validate={errors.password && "error"}
                validMessage={errors.password && errors.password.message}
              />
            </Popover>
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              {/* <Link to="/forgot-password" className="login-form-forgot">
                Forgot password
              </Link> */}
            </Form.Item>

            <Form.Item className="mb-0">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                size="large"
              >
                Sign in
              </Button>
            </Form.Item>
            {/* <ReCaptcha
              action="main"
              sitekey={SITEKEY}
              ref={refReCaptcha}
              verifyCallback={verifyCallback}
            /> */}
          </Form>
        </Col>

        {/* <div className="social-login">
            <span>Or, login with:</span>
            <Button type="link">
              <img src={thumbIcon} alt="Thumb" />
            </Button>
            <Button type="link">
              <img src={facebookIcon} alt="Facebook" />
            </Button>
            <Button type="link">
              <img src={googleIcon} alt="Google" />
            </Button>
          </div> */}
        <Col span={24}>
          <Paragraph className="copyright">Copyright © 2023</Paragraph>
        </Col>
      </Row>
    </LoginTemplate>
  );
};
