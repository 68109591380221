import { Button, Col, Form, Row, Space, Spin, message, Card, Typography, Switch } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import axios from "../../../../utils/axiosInceptor";
import { propertyService } from "../../../../configs/constants";
import { Popup } from "../../../atoms/Popup";
import { InputField, SelectField } from "../../../atoms/FormElement";
import ShowDocument from '../ShowDocument';
import UpdateProperty from '../UpdateProperty';
import RejectReason from '../RejectReason';
import { BsArrowCounterclockwise, BsPlusCircle } from "react-icons/bs";
import { FileImageOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { getLandlordProperty, updateLandlordProperty } from "../ducks/action";
import PdfViewer from "../../../molecules/PDFViewer/pdfView";

var _ = require("lodash");
const { Title } = Typography;

const EditProperty = (props) => {
  const dispatch: any = useDispatch();
  const [loader, setLoader] = useState(false);
  const [isError, setisError] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [documentObj, setDocumentURL] = useState(false);
  const [showPropertyModal, setShowPropertyModal] = useState(false);
  const [showRejectReasonModal, setShowRejectReasonModal] = useState(false)
  const [showPdf, setShowPdf] = useState(false);
  const [ejariDocument, setEjariDocument] = useState(null);
  const [rejectDocumentName, setRejectDocumentName] = useState(null)
  const [documents, setDocumentsList] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [documentApproved, setDocumentApproved] = useState(null)
  const [docName, newDocName] = useState(null)
  const fileInputRef = useRef(null);

  const { onUpdate, onchangeUpdate } = props;
  const documentNames = ['titleDeed', 'powerOfAttorney', 'emiratesIdFront', 'emiratesIdBack', 'passport'];

  const { emiratesList } = useSelector(
    (state: any) => state.auth.configData
  )?.[0];

  const property = useSelector(
    (state: any) => state.properties.getLandlordProperty
  );

  const updatedProperty = useSelector(
    (state: any) => state.properties.updateLandlordProperty
  );
  
  const config = useSelector((state: any) => state.auth.configData);

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
    setValue,
  } = useForm();

  const setPropertyFormValues = (key, value) => {

    switch (key) {
      case 'unit':
        setValue('bathrooms', value['bathrooms']);
        setValue('areaSqft', value['areaSqft']);
        setValue('plotArea', value['plotArea']);
        setValue('balconySize', value['balconySize']);
        setValue('parkingSpaces', value['parkingSpaces']);
        setValue('unitSubType', value['unitSubType']);
        setValue('unitNumber', value['unitNumber']);
        break;

      case 'address':
        const emirate = props?.selected?.address?.emirates

        if (emirate) {
          
          setValue(
            "emirates",
            {
              value: emiratesList.indexOf(emirate),
              label: emirate,
            } || "",
          );
        }
        setValue('longitude', value['longitude']);
        setValue('latitude', value['latitude']);
        setValue('community', value['community']);
        setValue('country', value['country']);
        break;

      case 'documentStatus':
        setDocumentApproved(value === 'Verified')
        break;
    
      default:
        setValue(key, value);
        break;
    }
  }

  useEffect(() => {

    const unitProperty = property ? property.data[0] : null
    const unitPropertyDocuments = property ? property.userDocuments : null
    const ejariCirtificate = property ? property?.ejari : null
    
    if (unitProperty) {

      Object.entries(unitProperty).forEach(([key, value]) => {
        setPropertyFormValues(key, value)
      });
      
      let propDocs = unitProperty.documents.concat(unitPropertyDocuments)
      
      for (let i = 0; i < documentNames.length; i++) {
        let docName = documentNames[i];
        let found = false;
        
        for (let j = 0; j < propDocs.length; j++) {
          if (propDocs[j].documentName === docName) {
            found = true;
            break;
          }
        }
        
        if (!found) {
          let newObject = {
            createdAt: "",
            documentId: "",
            documentName: docName,
            reason: "",
            status: "",
            type: "",
            updatedAt: ""
          };
          propDocs.push(newObject);
        }
      }

      propDocs = sortItems(propDocs)
      setDocumentsList(propDocs)
      if(Object.keys(ejariCirtificate).length === 0 && ejariCirtificate.constructor === Object)
      setEjariDocument({documentName: 'ejari_certificate'})
      else
      setEjariDocument(ejariCirtificate)
    } else reset();

    return () => {
      reset();
    };
  }, [property]);

  const sortItems = (propDocs) => {
    return propDocs.sort( (a,b) => {
      if (a?.documentName?.toLowerCase() < b?.documentName?.toLowerCase()) {
        return -1;
      }
      if (a?.documentName?.toLowerCase() > b?.documentName?.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }

  const getProperty = () => dispatch(getLandlordProperty(props.selected.userId, props.selected.unitId));

  useEffect(() => {
  
    if (props.selected) {
      getProperty()
    } else reset();

    return () => {
      reset();
    };

  }, [props.selected, props]);

  const getDocumentStatus = () => {
    
    let docArr = documents.filter(obj => obj.documentName !== 'powerOfAttorney')
    const docsName = docArr.some(doc => (doc.documentId == '' || doc.status === 'rejected'))
    if(docsName) return "Documents pending"

    const docsStatus = docArr.some(doc => doc.status !== 'approved') 
    if(docsStatus) return "Verification pending"

    if(documentApproved) return 'Verified'

    return "Verification pending"
  }

  const onFinish = () => {
    let values = formValues;

    setLoader(true);
    setSubmited(true);
    let postJson;
    
    if (props.selected?.unitId) {
      postJson = {
          "propertyName": values.propertyName,
          "dewaPremiseNumber": values.dewaPremiseNumber,
          "makaniNumber": values.makaniNumber,
          "documentStatus": getDocumentStatus(),
          "unit": {
            ...props.selected.unit,
            "unitNumber": values.unitNumber,
            "areaSqft": values.areaSqft,
            "plotArea": values.plotArea,
            "balconySize": values.balconySize,
            "bathrooms": values.bathrooms,
            "parkingSpaces": values.parkingSpaces,
            "unitSubType": values.unitSubType,
          },
            "address": {
              ...props.selected.address,
              "addressLine1": values.propertyName,
              "community": values.community,
              "country": values.country,
              "emirates": values?.emirates?.label || props.selected?.address?.emirates,
              "longitude": values.longitude,
              "latitude": values.latitude
            }
          }
    
        dispatch(updateLandlordProperty(props.selected.unitId, postJson));

    }
  };

  useEffect(() => {

    if(updatedProperty) {
      setShowPropertyModal(false);
      setLoader(false);
      setSubmited(false);
      reset();
      message.success('property updated successfully.');
      onUpdate(!onchangeUpdate);
      props.onSubmit();
    }

  }, [updatedProperty])

  const propertyRemoveContent = {
    title: (
      <Title level={3} className="m-0">
        Document
      </Title>
    ),
    content: (
      <ShowDocument
        documentObj={documentObj}
      />
    ),
    width: 950,
  };

  const removeDocumentProps = {
    closable: true,
    visibility: showDocumentModal,
    title: propertyRemoveContent.title,
    content: propertyRemoveContent.content,
    width: propertyRemoveContent.width,
    onCancel: () => {
      setShowDocumentModal(false);
      setDocumentURL(null)
    },
  };

  const onFinishReasonSubmit = (reason) => {

    modifyDocumentStatus('rejected', rejectDocumentName, reason)
    setShowRejectReasonModal(false);

  }

  const rejectReasonContent = {
    title: (
      <Title level={3} className="m-0">
        Reason for rejecting the document
      </Title>
    ),
    content: (
      <RejectReason 
        onSubmit={onFinishReasonSubmit}
        onCancel={() => { 
          setShowRejectReasonModal(false);
          setRejectDocumentName(null)
        }} />
    ),
    width: 650,
  };

  const documentRejectProps = {
    closable: true,
    visibility: showRejectReasonModal,
    title: rejectReasonContent.title,
    content: rejectReasonContent.content,
    width: rejectReasonContent.width,
    onCancel: () => {
      setShowRejectReasonModal(false);
      setRejectDocumentName(null)
    },
  }

  
  const propertyUpdateContent = {
    title: (
      <Title level={3} className="m-0">
        Update Property
      </Title>
    ),
    content: (
      <UpdateProperty 
        onSubmit={() => {
          onFinish()
        }}
        onCancel={() => { 
          setShowPropertyModal(false);
        }} />
    ),
    width: 650,
  };

  const propertyUpdateProps = {
    closable: true,
    visibility: showPropertyModal,
    title: propertyUpdateContent.title,
    content: propertyUpdateContent.content,
    width: propertyUpdateContent.width,
    onCancel: () => {
      setShowPropertyModal(false);
    },
  };

  const modifyDocumentStatus = (status, name, reason?) => {
    setLoader(true)
    let postJson = {
      "status": status,
      "reason": reason || '',
      "comments": "Test",
      "documentName": name
      }

  const url = `${propertyService}/unit/updateDocument/${props.selected?.unitId}`;

  axios
    .patch(url, postJson, { headers: { application: 4 } })
    .then((response) => {
      let { data } = response;
      let propDocs = data.result

      for (let i = 0; i < documentNames.length; i++) {
        let docName = documentNames[i];
        let found = false;
        for (let j = 0; j < propDocs.length; j++) {
          if (propDocs[j].documentName === docName) {
            found = true;
            break;
          }
        }
        
        if (!found) {
          let newObject = {
            createdAt: "",
            documentId: "",
            documentName: docName,
            reason: "",
            status: "",
            type: "",
            updatedAt: ""
          };
          propDocs.push(newObject);
        }
      }

      propDocs = sortItems(propDocs)
      setDocumentsList(propDocs) 
      setDocumentApproved(docStatus => {

        return propDocs.map(doc => doc.name !== 'powerOfAttorney').some(doc => doc.status === 'approved')
        
      })
      setLoader(false);
      message.success(data?.message);
    })
    .catch((error) => {
      message.error(
        error.response.data?.error || error.response.data?.message
      );
      setLoader(false);
    });
  }

  const showDocument = async propertyDocument => {
    
    setLoader(true)
    const url = `${propertyService}/document/getDocument/${propertyDocument.documentId}`;
    // let headType;
    // if(false) {
    //   headType = {responseType: "blob"}
    // } else {
    //   headType = { headers: {
    //     'organization-unit': 'AGP',
    //     'bussiness-unit': 'AUTO',
    //     'channel': 'MOBILE',
    //     'accept-language': 'EN',
    //     'source': 'Orion',
    //     'accept':'application/json',
    //     'Content-Type': 'multipart/form-data',
    //   }}
    // }

    // try {
    //   const response = await axios.get(url, headType)
    //   console.log(response);
    //   if(response?.data?.result?.documnentData?.type !== 'pdf') {
    //     setDocumentURL(response.data?.result);
    //     setShowDocumentModal(true)
    //     setLoader(false);
    //   } else {
    //     axios.get(response.data?.result?.documnentData.file, { responseType: "blob" }).then(blob => {
    //       const reader = new FileReader();
    //       const binaryString = reader.readAsDataURL(blob.data);
    //       reader.onload = (event: any) => {
    //         //Here you can do whatever you want with the base64 String
    //         console.log("File in Base64: ", event.target.result);
    //       };
    
    //       reader.onerror = (event: any) => {
    //         console.log("File could not be read: " + event.target.error.code);
    //       };
    //     });
    //   }

    // } catch (error) {
      
    // }
    // return
    axios
      .get(url, { headers: { application: 4 } })
      .then((response) => {
        let { data } = response;
        
        if(data?.result?.documnentData?.type === 'pdf') {
          var link = document.createElement('a');
          link.href = data?.result?.location
          link.download = propertyDocument?.documentName || 'file.pdf';
          link.dispatchEvent(new MouseEvent('click'));
          setLoader(false);
        } else {
          setDocumentURL(data?.result);
          setShowDocumentModal(true)
          setLoader(false);
        }
        setLoader(false);
      })
      .catch((error) => {
        message.error(
          error.response.data?.error || error.response.data?.message
        );
        setLoader(false);
      });
  }

  const removeDocument = (docName) => {

  }

  const viewDocument = (status, document) => {
    switch (status) {
      case 'VIEW':
        showDocument(document)
        break;
      case 'APPROVE':
        modifyDocumentStatus('approved', document.documentName)
      break;
      case 'REJECT': {
        setRejectDocumentName(document.documentName)
        setShowRejectReasonModal(true)
      }
      break;
      case 'DELETE': {
        removeDocument(document.documentName)
      }
      break;

      default:
        break;
    }
  }

  const convertToNormalCase = (input = 'Document') => {
    // Check if the input is in camel case
    if (input.indexOf('_') === -1 && input !== input.toLowerCase()) {
      return input.replace(/([a-z])([A-Z])/g, '$1 $2');
    }
    
    // Check if the input is in snake case
    if (input.indexOf('_') !== -1) {
      return input.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    }
    
    return input; // Already in normal case
  }

  const handleFileChange = async (event) => {

    let file = event.target.files[0];
    let url = `${propertyService}/document/uploadFile`;

    const postJson = {
      file_asset: file,
    };

      try {
        setLoader(true);
        const fileUpload = await axios.post(url, postJson, { headers: { 'Content-Type': 'multipart/form-data' } })

        let updatedObjects;
        if(fileUpload?.data?.result?.document) {
          if(docName === 'ejari_certificate') {
            url = `${propertyService}/ejari/individual/uploadEjariCertificate/${property.data[0].unitId}`;
            const ejariFileUploadRes = await axios.patch(url, {documentId: fileUpload?.data?.result?.documentId, type: fileUpload?.data?.result?.document?.type}, { headers: { 'Content-Type': 'application/json' } })

            if(ejariFileUploadRes?.data?.code === 200) {
              const ejariDocuments = ejariFileUploadRes?.data?.result?.result?.ejari?.ejariDocuments
              if(ejariDocuments) {
                let ejariDocument = ejariDocuments.find(obj => obj.documentName === 'ejari_certificate')
                setEjariDocument(ejariDocument)
                setLoader(false)
              }
              message.success(
                ejariFileUploadRes?.data?.message
              );
            }
            return
          }
          updatedObjects = documents.map((obj) => {
            
            if (obj.documentName === docName) {
              return { ...obj, documentId: fileUpload?.data?.result?.documentId, type: fileUpload?.data?.result?.document?.type?.toUpperCase() };
            }
            return obj;
          });
        }
        
        if(docName === 'ejari_certificate') return setLoader(false);

        updatedObjects = updatedObjects.filter(obj => obj.documentId !== '')

        const updatedDocuments = await axios.post(`${propertyService}/unit/uploadDocuments/${property.data[0].unitId}`, { documents :updatedObjects}, { headers: { application: 4 } })
        let updatedDocumentsRes = updatedDocuments?.data?.result?.documents

        if(updatedDocumentsRes) {
          for (let i = 0; i < documentNames.length; i++) {
            let docName = documentNames[i];
            let found = false;
            for (let j = 0; j < updatedDocumentsRes.length; j++) {
              if (updatedDocumentsRes[j].documentName === docName) {
                found = true;
                break;
              }
            }
            
            if (!found) {
              let newObject = {
                createdAt: "",
                documentId: "",
                documentName: docName,
                reason: "",
                status: "",
                type: "",
                updatedAt: ""
              };
              updatedDocumentsRes.push(newObject);
            }
          }
          updatedDocumentsRes = sortItems(updatedDocumentsRes)
          setDocumentsList(updatedDocumentsRes)
        }
        
        setLoader(false);

      } catch (error) {
        message.error(
          error.response.data?.error || error.response.data?.message
        );
        setLoader(false);
      }
  };

  const DocumentCard = ({document}) => {
    
    return (
      <Card style={{marginBottom: '10px', borderColor: 'grey', height: 100, width: document.documentName !== 'ejari_certificate' ? '100%' : '152%'}} key={document.documentId}>
        <Card.Meta
          avatar={<FileImageOutlined style={{fontSize: '30px', marginTop: '15px', marginRight: 20}} />}
          title={convertToNormalCase(document.documentName)}
          description={
            <>
              <span style={{marginRight: '7px'}}><a style={{...(!document.documentId ? {color: 'grey', cursor: 'auto'} : null)}} onClick={() => document.documentId && viewDocument('VIEW', document)}>View</a></span>
              <span>|</span>
              {document.documentName !== 'ejari_certificate' && <span style={{marginRight: '7px', marginLeft: '7px'}}><a style={{...(!document.documentId ? {color: 'grey', cursor: 'auto'} : null), ...(document.status === 'approved' ? {color: "black"} : {})}} onClick={() => document.documentId && viewDocument('APPROVE', document)}>{document.status === 'approved' ? 'Approved' : 'Approve'}</a></span>}
              {document.documentName !== 'ejari_certificate' && <span>|</span> }
              {document.documentName !== 'ejari_certificate' && <span style={{marginLeft: '7px', marginRight: '7px'}}><a style={{...(!document.documentId ? {color: 'grey', cursor: 'auto'} : null), ...(document.status === 'rejected' ? {color: "black"} : {})}} onClick={() => document.documentId && viewDocument('REJECT', document)}>{document.status === 'rejected' ? 'Rejected' : 'Reject'}</a></span>}
              {document.documentName !== 'ejari_certificate' && <span>|</span> }

              <span style={{marginLeft: '7px'}}>
                <a onClick={() => {
                  newDocName(document.documentName)
                    if(fileInputRef.current) { 
                      fileInputRef.current.click();
                  }
                }}>{document.documentId ? 'Change' : 'Upload'}</a></span>

            </>
          }
        />
      </Card>
      );
  };

  const onFinishSubmit = (values) => {
    
    setFormValues(values)
    setShowPropertyModal(true);

  }

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinishSubmit)}>
      {/* spinner section  */}
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
            </Space>
          </Col>
        </Row>
      )}{" "}
      {/* <PdfViewer
        pdf={pdfFile}
        onCancel={() => setShowPdf(false)}
        visible={showPdf}
      /> */}
      <input
        type="file"
        accept=".jpg, .jpeg, .png, .pdf"
        style={{ display: 'none' }}
        onChange={handleFileChange}
        ref={fileInputRef}
      />
      {/* Form controls */}
      <Row gutter={24}>
        <Col span={14}>
          <Row gutter={12}>
            <Title style={{fontSize: 20, paddingTop: 3, fontWeight: 'bold'}}>
              Property Details
            </Title>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <InputField
                control={control}
                isRequired={true}
                label="Unit Number"
                fieldname="unitNumber"
                validate={errors.unitNumber && "error"}
                validMessage={errors.unitNumber && errors.unitNumber.message}
                initValue=""
                rules={{
                  required: "Please enter unit number",
                }}
                onChange={(e) => {
                  setValue("unitNumber", e);
                }}
                iProps={{
                  type: "text",
                  className: "ag-black",
                  placeholder: "Enter Unit number",
                }}
              />
            </Col>
            <Col span={12}>
              <InputField
                control={control}
                isRequired={true}
                label="Property Name"
                fieldname="propertyName"
                validate={errors.propertyName && "error"}
                validMessage={errors.propertyName && errors.propertyName.message}
                initValue=""
                rules={{
                  required: "Please enter property name",
                }}
                onChange={(e) => {
                  setValue("propertyName", e);
                }}
                iProps={{
                  type: "text",
                  className: "ag-black",
                  placeholder: "Enter Property Name",
                }}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <InputField
                control={control}
                isRequired={true}
                label="Address"
                fieldname="community"
                validate={errors.community && "error"}
                validMessage={errors.community && errors.community.message}
                initValue=""
                rules={{
                  required: "Please enter address",
                }}
                onChange={(e) => {
                  setValue("community", e);
                }}
                iProps={{
                  type: "text",
                  className: "ag-black",
                  placeholder: "Enter Address",
                }}
              />
            </Col>
            <Col span={12}>
              <InputField
                control={control}
                isRequired={true}
                label="Country"
                fieldname="country"
                validate={errors.country && "error"}
                validMessage={errors.country && errors.country.message}
                initValue=""
                rules={{
                  required: "Please enter country",
                }}
                onChange={(e) => {
                  setValue("country", e);
                }}
                iProps={{
                  type: "text",
                  className: "ag-black",
                  placeholder: "Enter country Name",
                }}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <SelectField
                label="Emirate"
                control={control}
                isRequired={true}
                fieldname="emirates"
                validate={errors.emirates && "error"}
                iProps={{ placeholder: "Select emirate" }}
                validMessage={errors.emirates && errors.emirates.message}
                rules={{
                  required: "Please select emirate",
                }}
                selectOption={_.map(emiratesList, (val, key) => ({
                  value: key,
                  label: val,
                }))}
              />
            </Col>{" "}
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <InputField
                control={control}
                label="Makani Number"
                fieldname="makaniNumber"
                validate={errors.makaniNumber && "error"}
                validMessage={errors.makaniNumber && errors.makaniNumber.message}
                initValue=""
                onChange={(e) => {
                  setValue("makaniNumber", e);
                }}
                iProps={{
                  type: "text",
                  className: "ag-black",
                  placeholder: "Enter makani number",
                }}
              />
            </Col>
            <Col span={12}>
              <InputField
                control={control}
                isRequired={true}
                label="DEWA Premise No"
                fieldname="dewaPremiseNumber"
                validate={errors.dewaPremiseNumber && "error"}
                validMessage={
                  errors.dewaPremiseNumber && errors.dewaPremiseNumber.message
                }
                initValue=""
                onChange={(e) => {
                  setValue("dewaPremiseNumber", e);
                }}
                rules={{
                  required: "Please enter DEWA Premise No",
                }}
                iProps={{
                  type: "text",
                  className: "ag-black",
                  placeholder: "Enter DEWA premise number",
                }}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <InputField
                control={control}
                isRequired={true}
                label="Title Deed Number"
                fieldname="titleDeedNumber"
                validate={errors.titleDeedNumber && "error"}
                validMessage={
                  errors.titleDeedNumber && errors.titleDeedNumber.message
                }
                initValue=""
                rules={{
                  required: "Please enter unit number",
                }}
                onChange={(e) => {
                  setValue("titleDeedNumber", e);
                }}
                iProps={{
                  type: "text",
                  className: "ag-black",
                  placeholder: "Enter title deed number",
                }}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <InputField
                control={control}
                label="Longitude"
                fieldname="longitude"
                validate={errors.longitude && "error"}
                validMessage={errors.longitude && errors.longitude.message}
                initValue=""
                onChange={(e) => {
                  setValue("longitude", e);
                }}
                iProps={{
                  type: "text",
                  className: "ag-black",
                  placeholder: "Enter longitude",
                }}
              />
            </Col>
            <Col span={12}>
              <InputField
                control={control}
                label="Latitude"
                fieldname="latitude"
                validate={errors.latitude && "error"}
                validMessage={errors.latitude && errors.latitude.message}
                initValue=""
                onChange={(e) => {
                  setValue("latitude", e);
                }}
                iProps={{
                  type: "text",
                  className: "ag-black",
                  placeholder: "Enter latitude",
                }}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <InputField
                control={control}
                isRequired={true}
                label="Beds"
                fieldname="unitSubType"
                validate={errors.unitSubType && "error"}
                validMessage={errors.unitSubType && errors.unitSubType.message}
                initValue=""
                rules={{
                  required: "Please enter unit number",
                }}
                onChange={(e) => {
                  setValue("unitSubType", e);
                }}
                iProps={{
                  // type: "number",
                  className: "ag-black",
                  placeholder: "Enter bed type",
                }}
              />
            </Col>
            <Col span={12}>
              <InputField
                control={control}
                isRequired={true}
                label="Baths"
                fieldname="bathrooms"
                validate={errors.bathrooms && "error"}
                validMessage={errors.bathrooms && errors.bathrooms.message}
                initValue=""
                onChange={(e) => {
                  setValue("bathrooms", e);
                }}
                rules={{
                  required: "Please enter number of Bathrooms",
                }}
                iProps={{
                  type: "number",
                  className: "ag-black",
                  placeholder: "Enter number of bathrooms",
                }}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <InputField
                control={control}
                isRequired={true}
                label="Built-up Area (Sq.ft)"
                fieldname="areaSqft"
                validate={errors.areaSqft && "error"}
                validMessage={errors.areaSqft && errors.areaSqft.message}
                initValue=""
                rules={{
                  required: "Please enter built up area",
                }}
                onChange={(e) => {
                  setValue("areaSqft", e);
                }}
                iProps={{
                  type: "number",
                  className: "ag-black",
                  placeholder: "Enter Built up area",
                }}
              />
            </Col>
            <Col span={12}>
              <InputField
                control={control}
                isRequired={false}
                label="Plot Area (Sq.ft)"
                fieldname="plotArea"
                validate={errors.plotArea && "error"}
                validMessage={errors.plotArea && errors.plotArea.message}
                initValue=""
                onChange={(e) => {
                  setValue("plotArea", e);
                }}
                // rules={(props.selected?.unit?.unitType !== 'APARTMENT') ? {
                //   required: "Please enter enter Plot area",
                // } : {}}
                iProps={{
                  type: "number",
                  className: "ag-black",
                  placeholder: "Enter plot area",
                }}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <InputField
                control={control}
                isRequired={false}
                label="Balcony (Sq.ft)"
                fieldname="balconySize"
                validate={errors.balconySize && "error"}
                validMessage={errors.balconySize && errors.balconySize.message}
                initValue=""
                // rules={(props.selected?.unit?.unitType !== 'APARTMENT') ? {
                //   required: "Please enter built up area",
                // } : {}}
                onChange={(e) => {
                  setValue("balconySize", e);
                }}
                iProps={{
                  type: "number",
                  className: "ag-black",
                  placeholder: "Enter Built up area",
                }}
              />
            </Col>
            <Col span={12}>
              <InputField
                control={control}
                isRequired={true}
                label="Parking Spaces(Sq.ft)"
                fieldname="parkingSpaces"
                validate={errors.parkingSpaces && "error"}
                validMessage={errors.parkingSpaces && errors.parkingSpaces.message}
                initValue=""
                rules={{
                  required: "Please enter Parking Spaces area",
                }}
                onChange={(e) => {
                  setValue("parkingSpaces", e);
                }}
                iProps={{
                  type: "number",
                  className: "ag-black",
                  placeholder: "Enter plot area",
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={10}>
          <Row gutter={12}>
            {
              documents && documents.length > 0 && (
                <Title style={{fontSize: 20, paddingTop: 3, fontWeight: 'bold'}}>
                  Documents
                </Title>
              )
            }
          </Row>
          <Row gutter={18}>
            <Col>
            {
              documents && documents.map(document => <DocumentCard document={document} />)
            }
            </Col>
          </Row> 

          {/* <Row gutter={18}>
            <Col span={10}>
              <Title style={{fontSize: 14, paddingTop: 3}}>
                Upload Document
              </Title>
            </Col>
            <Col span={6}>
              <Button
                className="ag-green-button theme_btn"
                htmlType="button"
                onClick={() => documentUpload()}
              >
                {<CloudUploadOutlined />} 
                Upload
              </Button>
            </Col>
          </Row>    */}
          
          {
            documents && documents.length > 0 && (
              <Row gutter={24}>
                <Col span={6}>
                  <Title style={{fontSize: 14, paddingTop: 3}}>
                    Not Approved
                  </Title>
                </Col>

                <Col span={3}>
                <div className="Simple-tooltip" style={{fontSize: 20, paddingTop: 3}}>
                  <Switch
                    className="switch"
                    size="default"
                    onChange={(e) => {
                      // need api for document status update
                      setDocumentApproved(status => !status)
                      }
                    }
                    checked={documentApproved}
                  />
                  <section className="tt-content">{documentApproved ? `Approved` : 'Not Approved'}</section>
                </div>
                  
                </Col>
                <Col span={5}>
                  <Title style={{fontSize: 14, paddingTop: 3}}>
                    Approved
                  </Title>
                </Col>
              </Row> 
            )
          }    
          {
            ejariDocument && 
            <Row gutter={18}>
              <Col>
                <DocumentCard document={ejariDocument} />
              </Col>
            </Row> 
          }  
        </Col>
      </Row>
      {/* buttons */}
      <Row gutter={24} justify="space-between">
        <Col>
          <Button
            className="ag-error theme_grey_btn"
            htmlType="button"
            onClick={() => {
              props.onCancel();
            }}
          >
            {<BsArrowCounterclockwise />} Back
          </Button>
        </Col>
        <Col>
          <Button className="ag-green-button theme_btn" htmlType="submit">
            {<BsPlusCircle />} Update
          </Button>
        </Col>
      </Row>
      <Popup {...removeDocumentProps} />
      <Popup {...propertyUpdateProps} />
      <Popup {...documentRejectProps} />
      
    </Form>
  );
};

export default EditProperty;
