import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Pagination, Row, Col, Input, Tabs, Select, message, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { getAssignedList, getUnAssignedList, getZones } from "./ducks/actions";
import Grid from "../../atoms/Grid";
import DashboardLayout from "../../molecules/DashboardLayout";
import SectionHeader from "../../molecules/SectionHeader";
import axios from "../../../utils/axiosInceptor";
import { adminService, baseUrl } from "../../../configs/constants";
import { LoadingOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const [searchText, setSearchText] = useState();
  const [tabKey, setTabkey] = useState("1");
  const [page, setPage] = useState(1);
  const [IDs, setIDs] = useState(1);
  const [page2, setPage2] = useState(1);
  const [assignIDs, setAssignIDs] = useState(1);
  const dispatch:any = useDispatch();
  const [zoneList, setZoneList] = useState([]);
  const [unassign, setUnassigned] = useState([]);
  const [assign, setAssigned] = useState([]);
  const [loader, setLoader] = useState(false);

  const assignedList = useSelector((state:any) => state.technician.assignedList);
  const unassignedList = useSelector(
    (state:any) => state.technician.unassignedList,
  );
  const zones = useSelector((state:any) => state.technician.zones);
  
  const { Option } = Select;

  const getTokenApi = async () => {
    let token: any = localStorage.getItem("token2");
    if (!token) {
      await fetch(`${baseUrl}/get-token`, {
        method: "POST",
        body: JSON.stringify({
          username: "globalAPIAdmin",
          password: "d4Lkdulqilnvryg6CN6e9hfUJz9ss8pq",
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          localStorage.setItem("token2", JSON.stringify(data.result));
          token = data.result;
        });
    } else {
      token = JSON.parse(token);
    }
    return token;
  };

  const getList = async () => {
    let tokena = await getTokenApi();
    dispatch(getZones());
    dispatch(getUnAssignedList(tokena));
    dispatch(getAssignedList(tokena));
  };

  useEffect(() => {
    getList();
    return () => {
      localStorage.removeItem("token2");
    };
  }, []);

  useEffect(() => {
    setInterval(() => {
      localStorage.removeItem("token2");
      getTokenApi();
    }, 1000 * 60 * 30);
  }, []);

  useEffect(() => {
    if (zones.length > 0) {
      setZoneList(zones);
    }
  }, [zones]);

  useEffect(() => {
    if (Object.keys(unassignedList).length > 0) {
      let temp = [];
      unassignedList.pageData.map((x) => {
        temp.push({
          id: x.party_id,
          name: x.name,
          email:
            x.electronic_address[0]?.email || x.electronic_address[1]?.email,
          mobile:
            x.telecommunication_number[0]?.contact_number ||
            x.telecommunication_number[1]?.contact_number,
        });
      });
      setUnassigned(temp);
    }
  }, [unassignedList]);

  useEffect(() => {
    if (Object.keys(assignedList).length > 0) {
      let temp = [];
      assignedList.pageData.map((x) => {
        temp.push({
          id: x.party_id,
          name: x.name,
          email:
            x.electronic_address[0]?.email || x.electronic_address[1]?.email,
          mobile:
            x.telecommunication_number[0]?.contact_number ||
            x.telecommunication_number[1]?.contact_number,
          zone: x.zone_id,
        });
      });
      setAssigned(temp);
    }
  }, [assignedList]);

  const onChangeZone = (e, item) => {
    setLoader(true);
    let body = {
      party_id: item.data.id,
    };
    if (e) {
      body["zone_id"] = e;
    }
    if (item?.data?.zone) {
      body["delete_zone_id"] = item.data.zone;
    }
    axios
      .post(`${adminService}/admin/assignZoneToTechnicians `, body)
      .then((res) => {
        setLoader(false);
        getList();
        if (body["delete_zone_id"] && !body["zone_id"]) {
          message.success("Zone Unassigned Successfully");
        } else {
          message.success("Zone Assigned Successfully");
        }
      })
      .catch((err) => {
        setAssigned([]);
        setLoader(false);
        getList();
        const { response } = err;
        message.error(response.data.message);
      });
  };

  const zoneComponent = (params) => {
    return (
      <Select
        className="Sentence active-select ag-blue"
        placeholder="Select"
        style={{ width: 170 }}
        allowClear
        onChange={(e) => onChangeZone(e, params)}
        defaultValue={params?.data?.zone ? params?.data?.zone : null}
      >
        {/* {console.log("---", params?.data)} */}
        {zoneList.map((x) => (
          <Option
            key={x.id}
            value={x?.id}
            className="Sentence active-select"
          >
            {x?.name}
          </Option>
        ))}
      </Select>
    );
  };

  const unassignCol = [
    {
      headerName: "#",
      valueGetter: `${IDs}+node.rowIndex`,
      minWidth: 40,
      maxWidth: 80,
      pinned: "left",
    },
    {
      headerName: "ID",
      field: "id",
      hide: true,
      maxWidth: 140,
      pinned: "left",
    },
    { headerName: "Name", field: "name" },
    { headerName: "Email Address", field: "email" },
    {
      headerName: "Mobile Number",
      field: "mobile",
    },
    {
      headerName: "Zone",
      field: "zone",
      cellRendererFramework: zoneComponent,
    },
  ];
  const assignedCol = [
    {
      headerName: "#",
      valueGetter: `${assignIDs}+node.rowIndex`,
      minWidth: 40,
      maxWidth: 80,
      pinned: "left",
    },
    {
      headerName: "ID",
      field: "id",
      maxWidth: 140,
      hide: true,
      pinned: "left",
    },
    { headerName: "Name", field: "name" },
    { headerName: "Email Address", field: "email" },
    { headerName: "Mobile Number", field: "mobile" },
    {
      headerName: "Zone",
      field: "zone",
      cellRendererFramework: zoneComponent,
    },
  ];

  const fetchData = async (e) => {
    if (e >= 2) {
      if (e > page) {
        setIDs((e - 1) * 50 + 1);
        setPage(e);
      } else if (e < page) {
        setIDs((e - 1) * 50 + 1);
        setPage(e);
      }
    } else {
      setIDs(e);
      setPage(e);
    }
    let tokena = await getTokenApi();
    dispatch(getUnAssignedList(tokena, e));
  };

  const fetchPagData = async (e) => {
    if (e >= 2) {
      if (e > page2) {
        setAssignIDs((e - 1) * 50 + 1);
        setPage2(e);
      } else if (e < page2) {
        setAssignIDs((e - 1) * 50 + 1);
        setPage2(e);
      }
    } else {
      setAssignIDs(e);
      setPage2(e);
    }
    let tokena = await getTokenApi();
    dispatch(getAssignedList(tokena, e));
  };

  const searchField = (
    <Input
      type="text"
      onChange={(e) => onSearch(e)}
      placeholder=""
      suffix={<SearchOutlined />}
    />
  );

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <>
      <DashboardLayout>
        <SectionHeader
          heading="Technicians"
          total={
            tabKey === "2" ? assignedList?.dataCount : unassignedList?.dataCount
          }
          //addNewAction={addFacility}
        />
        <Spin indicator={antIcon} size="large" spinning={loader}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Tabs
                defaultActiveKey={tabKey}
                onChange={(key) => setTabkey(key)}
                tabBarExtraContent={searchField}
                tabBarGutter={48}
                className="ag-property-status"
              >
                <TabPane tab="Unassigned" key="1">
                  {zoneList.length > 0 && (
                    <>
                      <Grid
                        data={unassign}
                        columns={unassignCol}
                        filterGrid={searchText}
                        pagination={false}
                      />
                      <Row justify="end" className="mt-1">
                        <Pagination
                          className="ag-property-text-pagination"
                          current={page}
                          total={unassignedList?.dataCount}
                          onChange={fetchData}
                          showSizeChanger={false}
                          pageSize={50}
                        />
                      </Row>
                    </>
                  )}
                </TabPane>
                <TabPane tab="Assigned" key="2">
                  <Grid
                    data={assign}
                    columns={assignedCol}
                    filterGrid={searchText}
                    pagination={false}
                  />
                  <Row justify="end" className="mt-1">
                    <Pagination
                      className="ag-property-text-pagination"
                      current={page2}
                      total={assignedList.dataCount}
                      onChange={fetchPagData}
                      showSizeChanger={false}
                      pageSize={50}
                    />
                  </Row>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </Spin>
      </DashboardLayout>
    </>
  );
};
