
export const paymentTypes =  [
    {
       "label": "All",
       "value": ""
    },
    {
      "label": "Rent Installment",
      "value": "rent_installment"
    },
    {
      "label": "Security Deposit",
      "value": "security_deposit"
    },
    {
      "label": "Ejari Fee",
      "value": "ejari_fee"
    },
    {
      "label": "Rental Commission",
      "value": "rental_commission"
    },
    {
      "label": "Furniture Charges",
      "value": "furniture_charges"
    },
    {
      "label": "Cheque Bounce Penalty Fee",
      "value": "cheque_bounce_penalty_fee"
    },
    {
      "label": "Administration Charges",
      "value": "administration_charges"
    },
    {
      "label": "Parking Charges",
      "value": "parking_charges"
    },
    {
      "label": "Service Charges",
      "value": "service_charges"
    }
  ];