import * as React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import Root from "./routing/Root";
import store from "./redux/store";
import "./styles/antd.less";
import "./styles/styles.scss";

const container = document.getElementById("root");

const rootEl = createRoot(container!);


rootEl.render(
  <Provider store={store}>
    <Root />
  </Provider>
);
