import React, { useState, useEffect } from "react";
import DashboardLayout from "../../molecules/DashboardLayout";
import { Row, Col } from "antd";
import { paymentTypes } from "./constant";
import SectionHeader from "../../molecules/SectionHeader";
import OnlinePayment from "./onlinePayments";
import CourierPayment from "./courierPaymentReport";
import { useParams } from "react-router-dom";
import { getOrginationListService } from "./service";

export default (props) => {

  const [searchingText, setSearchText] = useState(null);
  const [searchingBy, setSearchBy] = useState(null);
  const [paymentType, setPaymentType] = useState(null);
  const [organizationList, setOrginizationList] = useState(null);
  const [totlaRecord, setTotalRecord] = useState(0);
  const [orgId, setOrgId] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [exportReport, setExportReport] = useState(false);
  const { type } = useParams();

  let initialSearchKeys = [];

  type === "Pickup-Requests" ? 
   initialSearchKeys = [
    { value: "orderId", label: "Order ID" },
    // { value: "bankRef", label: "Bank Ref" },
    { value: "propertyName", label: "Property Name" },
    { value: "unitCode", label: "Unit Code" }, //changed from community ref: 8405
  ]
  :
  initialSearchKeys = [
    { value: "orderId", label: "Order ID" },
    { value: "bankRef", label: "Bank Ref" },
    { value: "propertyName", label: "Property Name" },
    { value: "unitCode", label: "Unit Code" }, //changed from community ref: 8405
  ];
 
  const orgList = async () => {

    try {
      const data = await getOrginationListService();
      data.push({
        orgId:null,
        name:"All Orgnizations"
       });
       data.reverse();
      setOrginizationList(data);
    } catch (errr) {
      console.log(errr)
    }
  }

  useEffect(() => {
    orgList();
  }, []);


  return (
    <DashboardLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 10]}>
            <Col span={24}>
            
              <SectionHeader
                PaymentModeOptions={type === "Pickup-Requests" ? [] : paymentTypes}
                setSearchBy={setSearchBy}
                searchingBy={searchingBy}
                setSearchText={setSearchText}
                searchingText={searchingText}
                searchOptions={initialSearchKeys}
                organizationList={organizationList}
                paymentReports={true}
                exportAction={true}
                heading={type}
                total={totlaRecord}
                noBorder={false}
                setOrgId={setOrgId}
                setPaymentType={setPaymentType}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setExportReport={setExportReport}
              />
            </Col>
            {
              type === "Pickup-Requests"
                ?
                <CourierPayment
                  setTotalRecord={setTotalRecord}
                  totlaRecord={totlaRecord}
                  searchText={searchingText}
                  searchingBy={searchingBy}
                  orgId={orgId}
                  paymentType={paymentType}
                  startDate={startDate}
                  endDate={endDate}
                  setExportReport={setExportReport}
                  exportReport={exportReport}
                />
                :
                <OnlinePayment
                  setTotalRecord={setTotalRecord}
                  totlaRecord={totlaRecord}
                  searchText={searchingText}
                  searchingBy={searchingBy}
                  orgId={orgId}
                  paymentType={paymentType}
                  startDate={startDate}
                  endDate={endDate}
                  setExportReport={setExportReport}
                  exportReport={exportReport}
                />
            }


          </Row>
        </Col>
      </Row>
    </DashboardLayout>
  );
};
