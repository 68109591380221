import React, { useState, useEffect } from "react";

import { Popup } from "../../atoms/Popup";
import AddRole from "./components/AddRole";
import { useNavigate } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import SectionHeader from "../../molecules/SectionHeader";
import { getRoles, getConfigData } from "./ducks/actions";
import DashboardLayout from "../../molecules/DashboardLayout";
import { Button, Col, Row, Space, Table, Typography } from "antd";

const { Title } = Typography;

const RoleAndAccess = (props) => {
  const dispatch: any = useDispatch();
  const roleList = useSelector((state: any) => state.roles.rolesData);
  // const appIds = useSelector((state: any) => state.roles.appData);
  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [updateRow, setUpdateRow] = useState({});
  const [onClose, setOnClose] = useState(false);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState();

  useEffect(() => {
    dispatch(getRoles());
    dispatch(getConfigData());
    // dispatch(getContentCategories());
    // dispatch(getApplication());
  }, []);

  useEffect(() => {
    const filterData = () => {
      setPage(1);
      dispatch(getRoles());
    };
    const debouncer = setTimeout(() => {
      filterData();
    }, 1500);
    return () => {
      clearTimeout(debouncer);
    };
  }, [search]);

  const addUser = () => {
    setVisible(true);
    setTrigger(1);
  };
  const addRole = () => {
    setVisible(true);
    setTrigger(0);
  };

  const onAddRole = () => {
    dispatch(getRoles());
    setVisible(false);
  };

  const onUpdateRole = () => {
    dispatch(getRoles());
    setVisible(false);
  };

  const updateRoleModel = (role) => {
    setTrigger(2);
    setVisible(true);
    setUpdateRow(role);
    console.log(role, "text?.id");
  };

  const onTopSearch = (e) => {
    setSearch(e.target.value);
  };

  const onEdit = (record) => {
    setVisible(true);
    setTrigger(1);
    setSelected(record);
  };

  const columns: any = [
    {
      title: "Role Name",
      dataIndex: "roleName",
      key: "roleName",
      render: (text, value) => (
        <Button
          type="link"
          className="p-0"
          onClick={() => navigate(`/users/${value.roleName}`)}
          // onClick={() => history(`/users/${value.roleName}`)}
        >
          {text}
        </Button>
      ),
    },
    {
      // title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 150,
      render: (text, record) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => onEdit(record)}
              className="p-0 ag-success"
            >
              <div className="Simple-tooltip">
                <EditOutlined />
                <section className="tt-content">Edit</section>
              </div>
            </Button>
          </Space>
        );
      },
    },
  ];

  const popup = [
    {
      title: (
        <Title level={3} className="m-0">
          Add New Role
        </Title>
      ),
      content: (
        <AddRole
          page={page}
          // appIds={appIds}
          onClose={onClose}
          onSubmit={onAddRole}
          setOnClose={setOnClose}
          onCancel={() => setVisible(false)}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="m-0">
          Edit Role
        </Title>
      ),
      content: (
        <AddRole
          page={page}
          editMode={true}
          // appIds={appIds}
          onClose={onClose}
          selected={selected}
          onSubmit={onAddRole}
          setOnClose={setOnClose}
          onCancel={() => setVisible(false)}
        />
      ),
      width: 650,
    },
  ];

  const onCancel = () => {
    setOnClose(true);
    setVisible(false);
  };
  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => onCancel(),
  };

  return (
    <>
      <DashboardLayout>
        <SectionHeader
          addText={"Role"}
          addNewAction={addRole}
          heading="Roles Management"
          // search={onTopSearch}
        />
        <Row gutter={24} className="roles_wrap">
          <Col span={24}>
            <Table
              className="custom-table"
              columns={columns}
              pagination={{
                current: page,
                pageSize: 10,
                showSizeChanger: false,
                className: "ag-property-text-pagination mb-1",
                position: ["topLeft"],
                onChange: (e) => {
                  setPage(e);
                  dispatch(getRoles());
                },
                total: roleList?.count,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} Roles`,
              }}
              dataSource={roleList}
              scroll={{ y: 500 }}
            />
          </Col>
        </Row>
      </DashboardLayout>
      <Popup {...popupProps} />
    </>
  );
};

export default RoleAndAccess;
