import React, { useState } from "react";
import { Button, Row, Card, Space, Col, Form, Typography, message } from "antd";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { useForm } from "react-hook-form";
import draftToHtml from "draftjs-to-html";
import { useNavigate } from "react-router-dom";
import BreadCrumb from "../../../../atoms/BreadCrumb";
import DashboardLayout from "../../../../molecules/DashboardLayout";
import { InputField, SelectField } from "../../../../atoms/FormElement";
import axios from "../../../../../utils/axiosInceptor";
import { adminService } from "../../../../../configs/constants";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const breadcrumbList: any = [
  {
    label: "Add Template Details",
  },
];

const defaultValues: any = {
  key: "",
  type: "",
  //role:"",
  subject: "",
  footer: "",
};

const { Paragraph, Title } = Typography;

const ViewTemplate = () => {
  const [editorData, setEditorData] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const { control, formState: { errors }, handleSubmit } = useForm({ defaultValues });
  const history = useNavigate();

  const onEditorStateChange = (editorState) => {
    const data = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setEditorData(data);
    setEditorState(editorState);
  };

  const typeList = [
    { value: "email", label: "Email" },
    { value: "push_notification", label: "Push Notification" },
    { value: "sms", label: "SMS" },
  ];

  const onFinish = async (values) => {
    const body = {
      key: `${values.key}_${values.type.value}`,
      type: values.type.value,
      subject: values.subject,
      body: editorData,
      footer: values.footer,
    };
    const url = `${adminService}/notification`;
    try {
      await axios.post(url, body, { headers: { application: 4 } });
      setTimeout(() => {
        message.success("added successfully");
        history("/configurations");
      }, 1500);
    } catch (e) {
      const { response } = e;
      message.error(response?.data?.message);
    }
  };

  return (
    <DashboardLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <BreadCrumb
            name="Configuration"
            link="/configurations"
            list={breadcrumbList}
          />
        </Col>
        <Col span={24}>
          <Card
            bordered={false}
            bodyStyle={{ padding: "32px 32px" }}
            className="h-auto"
          >
            <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
              <Row gutter={24}>
                <Col span={24}>
                  <Title className="title_heading" level={3}>Add Template Details</Title>
                </Col>
                <Col span={8}>
                  <InputField
                    isRequired={true}
                    fieldname="key"
                    label="Key Name"
                    control={control}
                    rules={{
                      required: "Please enter Key name",
                    }}
                    iProps={{ placeholder: "Please enter key name" }}
                    initValue=""
                    validate={errors.key && "error"}
                    validMessage={errors.key && errors.key.message}
                  />
                </Col>
                <Col span={8}>
                  <SelectField
                    isRequired={true}
                    fieldname="type"
                    label="Type"
                    control={control}
                    validate={errors.type && "error"}
                    validMessage={errors.type && errors.type.message}
                    iProps={{ placeholder: "Please select type" }}
                    rules={{ required: "Please select type" }}
                    initValue=""
                    selectOption={typeList}
                  />
                </Col>
                <Col span={8}>
                  <InputField
                    isRequired={true}
                    fieldname="subject"
                    label="Subject"
                    control={control}
                    rules={{
                      required: "Please enter Subject ",
                    }}
                    initValue=""
                    validate={errors.subject && "error"}
                    validMessage={errors.subject && errors.subject.message}
                    iProps={{ placeholder: "Please enter subject" }}
                  />
                </Col>
                <Col span={24}>
                  <Paragraph className="ag-primary">
                    Body <span style={{ color: "#ff4d4f" }}>*</span>
                  </Paragraph>
                  <Editor
                    editorState={editorState}
                    wrapperClassName="editor-main"
                    editorClassName="editor-body"
                    onEditorStateChange={onEditorStateChange}
                  />
                </Col>
                <Col span={24} className="mt-1">
                  <InputField
                    fieldname="footer"
                    label="Footer"
                    control={control}
                    initValue=""
                    validate={errors.footer && "error"}
                    validMessage={errors.footer && errors.footer.message}
                  />
                </Col>
                <Col span={24}>
                  <Row justify="space-between">
                    <Button
                      size="large"
                      className="theme_grey_btn"
                      onClick={() => history("/configurations")}
                    >
                      Cancel
                    </Button>
                    <Button htmlType="submit" type="primary" size="large">
                      Add
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </DashboardLayout>
  );
};

export default ViewTemplate;
