import React, { useState, useEffect } from "react";
import { Row, Col, Input, Button, Typography, Tabs, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Grid from "../../atoms/Grid/";
import { popupConfirm, Popup } from "../../atoms/Popup";
import SectionHeader from "../../molecules/SectionHeader";
import { useSelector, useDispatch } from "react-redux";
import DashboardLayout from "../../molecules/DashboardLayout";
import BreadCrumb from "../../atoms/BreadCrumb";
import AddLandlord from "./component/AddLandlord";
import { Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import {
  getAssignedList,
  getUnAssignedList,
  getBuildings,
} from "./ducks/actions";
import { adminService } from "../../../configs/constants";
import axios from "axios";
import AddBuilding from "./component/AddBuilding";

const { Title } = Typography;
const { TabPane } = Tabs;

const Landlord = () => {
  const supervisorList = useSelector((state:any) => state.supervisor.assignedList);
  const unassignedList = useSelector(
    (state:any) => state.supervisor.unassignedList,
  );
  const unassignedCount = useSelector(
    (state:any) => state.supervisor.unassignedCount,
  );
  const assignedCount = useSelector((state:any) => state.supervisor.assignedCount);
  const [tabKey, setTabkey] = useState("1");
  const [IDs, setIDs] = useState(1);
  const [assignIDs, setAssignIDs] = useState(1);
  const [searchText, setSearchText] = useState();
  const [page, setPage] = useState(1);
  const [page2, setPage2] = useState(1);
  const dispatch:any = useDispatch();
  const history = useNavigate();
  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [facilityRowData, setFacilityRowData] = useState({});
  const buildings = useSelector((state:any) => state.facility.buildings);
  const [onClose, setOnClose] = useState(false);

  const getList = () => {
    dispatch(getAssignedList());
    dispatch(getUnAssignedList());
    dispatch(getBuildings());
  };

  useEffect(() => {
    getList();
  }, []);

  const fetchData = (e) => {
    if (e >= 2) {
      if (e > page) {
        setIDs((e - 1) * 50 + 1);
        setPage(e);
      } else if (e < page) {
        setIDs((e - 1) * 50 + 1);
        setPage(e);
      }
    } else {
      setIDs(e);
      setPage(e);
    }
    dispatch(getUnAssignedList(e));
  };

  const fetchPagData = (e) => {
    if (e >= 2) {
      if (e > page2) {
        setAssignIDs((e - 1) * 50 + 1);
        setPage2(e);
      } else if (e < page2) {
        setAssignIDs((e - 1) * 50 + 1);
        setPage2(e);
      }
    } else {
      setAssignIDs(e);
      setPage2(e);
    }
    dispatch(getAssignedList(e));
  };

  const searchField = (
    <Input
      type="text"
      onChange={(e) => onSearch(e)}
      placeholder=""
      suffix={<SearchOutlined />}
    />
  );

  const onSearch = (e) => {
    if (e.target.value) {
      setSearchText(e.target.value);
    } else {
      setSearchText(undefined);
    }
  };

  const propertiesComponentAdd = (params) => {
    console.log(params);
    return (
      <Select
        className="Sentence active-select ag-blue"
        placeholder="Select"
        style={{ width: 170 }}
        defaultActiveFirstOption={true}
        defaultValue={params?.data?.buildings[0]?.name}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Button
              type="link"
              className="fontWeight600 pl-1 ag-green"
              onClick={() => handleBuilding(params.data)}
            >
              + Add a Building
            </Button>
          </>
        )}
      >
        {
          <React.Fragment>
            {params?.data?.buildings.map((building, distinct) => {
              return (
                <Select.Option
                  key={`${distinct}`}
                  value={building?.id}
                  className="Sentence active-select"
                >
                  {building?.name}
                </Select.Option>
              );
            })}
          </React.Fragment>
        }
      </Select>
    );
  };

  const handleBuilding = (data) => {
    setVisible(true);
    setTrigger(0);
    setFacilityRowData(data);
  };

  const gridProps = {
    sortable: true,
    filter: false,
    resizable: true,
  };

  const unassigncol = [
    {
      headerName: "Id",
      valueGetter: `${IDs}+node.rowIndex`,
      minWidth: 40,
      maxWidth: 80,
      pinned: "left",
    },
    {
      headerName: "Name",
      field: "name",
      cellRendererFramework: partyIdRenderer,
    },
    { headerName: "Email Address", field: "email" },
    { headerName: "Mobile Number", field: "mobile_no" },
    {
      headerName: "Building(s)",
      maxWidth: 230,
      field: "buildings",
      cellRendererFramework: propertiesComponentAdd,
    },
    {
      field: "status",
      maxWidth: 150,
      cellRendererFramework: statusRenderAssigned,
    },
  ];

  const assigncol = [
    {
      headerName: "Id",
      valueGetter: `${assignIDs}+node.rowIndex`,
      minWidth: 40,
      maxWidth: 80,
      pinned: "left",
    },
    {
      headerName: "Name",
      field: "name",
      cellRendererFramework: partyIdRenderer,
    },
    { headerName: "Email Address", field: "email" },
    { headerName: "Mobile Number", field: "mobile_no" },
    {
      headerName: "Building(s)",
      maxWidth: 200,
      field: "buildings",
      cellRendererFramework: propertiesComponentAdd,
    },
    {
      field: "status",
      maxWidth: 120,
      cellRendererFramework: statusRender,
    },
  ];

  const popup = [
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Assign a Building
        </Title>
      ),
      content: (
        <AddBuilding
          page={page}
          partyData={facilityRowData}
          buildings={buildings.map((index) => ({
            label: index.name,
            value: index.id,
          }))}
          id={facilityRowData}
          onCancel={() => setVisible(false)}
        // onSubmit={onSubmit}
        />
      ),
      width: 650,
    },
  ];

  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => onCancel(),
  };

  const onCancel = () => {
    setOnClose(true);
    setVisible(false);
  };

  function statusRender(params) {
    const check = params.data.application_user_status[0]?.is_active;

    return (
      <Button
        type="link"
        className="p-0"
        onClick={() => approveFacilityManager(params)}
        style={{ color: check ? "#1C5D3B" : "#FB7171" }}
      >
        {check ? "Enable" : "Disable"}
      </Button>
    );
  }

  function statusRenderAssigned(params) {
    const check = params.data.application_user_status[0]?.is_active;
    return (
      <Button
        type="link"
        className="p-0"
        onClick={() => approveFacilityManager(params)}
        style={{ color: check ? "#184461" : "#FB7171" }}
      >
        {check ? "Approve" : "Reject"}
      </Button>
    );
  }

  const approveFacilityManager = (item: any) => {
    console.log();
    return popupConfirm({
      title: `Would you like to change the status of ${item.data.name} as a Landlord?`,
      okText: "Confirm",
      okType: "danger",
      onOk() {
        const url = `${adminService}/admin/updateUserActiveStatus`;
        const check = item.data.application_user_status[0]?.is_active;
        try {
          axios
            .put(
              url,
              {
                id: item.data.application_user_status[0]?.id,
                isActive: check ? false : true,
              },
              { headers: { application: 4 } },
            )
            .then((res) => {
              getList();
            });
        } catch (e) {
          console.log(e);
        }
      },
    });
  };

  function partyIdRenderer(params) {
    return (
      <Button
        type="link"
        className="px-0"
        onClick={() => history(`/all-properties/${params.data.id}`)}
      >
        {params.value}
      </Button>
    );
  }

  return (
    <>
      <DashboardLayout>
        <Row gutter={24}>
          <Col span={24}>
            <SectionHeader
              heading="Landlord Management"
              total={tabKey === "2" ? assignedCount : unassignedCount}
            />
            <Row gutter={[24, 24]}>
              <Col span={24}>
                {tabKey == "1" ? (
                  <Row justify="end" className="mb-1">
                    <Pagination
                      style={{ width: "100%" }}
                      className="ag-property-text-pagination"
                      current={page}
                      total={unassignedCount && unassignedCount}
                      onChange={fetchData}
                      showSizeChanger={false}
                      pageSize={50}
                      showTotal={(total, range) =>
                        `Showing ${range[1]}-${total} Landlords`
                      }
                    />
                  </Row>
                ) : (
                  <Row justify="end" className="mb-1">
                    <Pagination
                      style={{ width: "100%" }}
                      className="ag-property-text-pagination"
                      current={page2}
                      total={assignedCount && assignedCount}
                      onChange={fetchPagData}
                      showSizeChanger={false}
                      pageSize={50}
                      showTotal={(total, range) =>
                        `Showing ${range[1]}-${total} Landlords`
                      }
                    />
                  </Row>
                )}
                <Tabs
                  defaultActiveKey={tabKey}
                  onChange={(key) => setTabkey(key)}
                  tabBarExtraContent={searchField}
                  tabBarGutter={48}
                  className="ag-property-status"
                >
                  <TabPane tab="Unassigned" key="1">
                    {unassignedList && (
                      <>
                        <Grid
                          data={unassignedList}
                          columns={unassigncol}
                          pagination={false}
                          defaultSettings={gridProps}
                          filterGrid={searchText}
                          noRowlabel="Supervisors"
                        />
                      </>
                    )}
                  </TabPane>
                  <TabPane tab="Assigned" key="2">
                    {supervisorList ? (
                      <>
                        <Grid
                          data={supervisorList}
                          columns={assigncol}
                          pagination={false}
                          defaultSettings={gridProps}
                          filterGrid={searchText}
                          noRowlabel="Supervisors"
                        />
                      </>
                    ) : (
                      <h1>no data</h1>
                    )}
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          </Col>
        </Row>
      </DashboardLayout>
      <Popup {...popupProps} />
    </>
  );
};

export default Landlord;
