import axios from "../../../../../../utils/axiosInceptor";
import * as action_types from "../constants";
import {
  propertyService,
  adminService
} from "../../../../../../configs/constants";

const getPropertyUrl = `${propertyService}/property-landlord/`;
const getPropertyByIdUrl = `${adminService}/admin/property/`;

// AddGeneral Info Actions
const headers = {
  Authorization:
    "Bearer" +
    " " +
    JSON.parse(window.localStorage.getItem("login-response"))?.token,
};

export const getPropertyById = (propertyId) => {
  return async (dispatch) => {
    const {
      data: { result },
    } = await axios.get(`${getPropertyByIdUrl}/${propertyId}`, { headers });
    dispatch({
      type: action_types.GET_PROPERTY_BY_ID,
      data: result,
    });
  };
};

export const getSingleProperty = (propertyId) => {
  return async (dispatch) => {
    const {
      data: { result },
    } = await axios.get(`${getPropertyUrl}${propertyId}`, { headers });
    dispatch({
      type: action_types.GET_SINGLE_PROPERTY,
      data: result,
    });
  };
};
