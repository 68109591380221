import React, { FC, useState, useRef, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Layout,
  Menu,
  Button,
  Dropdown

} from "antd";
import {
  BsGear,
  BsGrid,
  BsBuilding,
  BsFillKeyFill,
  BsFillEyeFill,
  BsFillDiagram3Fill,
} from "react-icons/bs";
import {
  BellOutlined,
  MenuFoldOutlined,
  FileTextOutlined,
  MenuUnfoldOutlined,
  UsergroupAddOutlined,
  AuditOutlined,
  FileDoneOutlined,
  WifiOutlined,
  MoreOutlined

} from "@ant-design/icons";

interface IProps { }

/**
* @author
* @function @NavHeader
**/


const menu = (
  <Menu>
    <Menu.Item>
      <Link to="/profile">Profile</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/settings">Settings</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/logout">Logout</Link>
    </Menu.Item>
  </Menu>
);
export const NavHeader: FC<IProps> = (props) => {
  const { Header } = Layout;
  const { SubMenu } = Menu;
  const contentRef = useRef(null);
  const [openKeys, setOpenKeys] = useState("");


  const onOpenChange = (options) => {
    setOpenKeys(options[1]);
  };


  const [hasOverflowX, setHasOverflowX] = useState(false);

  useEffect(() => {
    const checkOverflowX = () => {
      if (contentRef.current) {
        const { scrollWidth, clientWidth } = contentRef.current;
        if (scrollWidth > clientWidth) {
          setHasOverflowX(true);
        } else {
          setHasOverflowX(false);
        }
      }
    };

    // Check for overflow initially and whenever the window resizes
    checkOverflowX();
    window.addEventListener('resize', checkOverflowX);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkOverflowX);
    };
  }, []);

  return (
    <Header ref={contentRef} className={hasOverflowX ? 'custome-top-header-scroll-view' : 'custome-top-header'} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginTop: '55px' }}>
      {/* <div className="logo" style={{ color: 'white', fontSize: '1.5rem' }}>MyApp</div> */}
      <Menu
        theme="dark"
        mode="horizontal"
        overflowedIndicator={null} // Disable the overflow indicator
        openKeys={[openKeys]}
        defaultSelectedKeys={['/dashboard']}
        onOpenChange={onOpenChange}
        selectedKeys={[location.pathname]}
        style={{ flex: 1, justifyContent: 'start' }}
        //   breakpoint="md"
        // className="menu"
      >
        <Menu.Item key="/dashboard" icon={<BsGrid />}>
          <Link to="/dashboard" style={{ margin: '1px' }}>Dashboard </Link>
        </Menu.Item>

        <Menu.Item key="/organizations" icon={<BsBuilding />}>
          <Link to="/organizations">Organizations</Link>
        </Menu.Item>
       
        <Menu.Item key="/subscriptions" icon={<BellOutlined />}>
          <Link to="/subscriptions">Subscriptions</Link>
        </Menu.Item>

        <Menu.Item key="/plans" className='adjust-able-item' icon={<FileTextOutlined />}>
            <Link to="/plans">Plans</Link>
        </Menu.Item>


        <Menu.Item key="/serviceproviderd" icon={<BsGear />}>
          <Link to="/serviceproviderd">Service Providers</Link>
        </Menu.Item>


        <SubMenu

          key="/roles"
          icon={<BsFillKeyFill />}
          title="Access Management"

        >
          <Menu.Item key="/roles-access-management">
            <Link to="/roles-access-management">
              {<BsFillDiagram3Fill />} Roles
            </Link>
          </Menu.Item>
          <Menu.Item key="/permissions">
            <Link to="/permissions">{<BsFillEyeFill />} Permissions</Link>
          </Menu.Item>
        </SubMenu>


        <Menu.Item key="/users" className='adjust-able-item' icon={< UsergroupAddOutlined />}>
          <Link to="/users">Users</Link>
        </Menu.Item>
        <Menu.Item key="/serviceprovider" icon={<BsGear />}>
          <Link to="/serviceprovider">Service Providers</Link>
        </Menu.Item>
        <Menu.Item className='adjust-able-item' key="/configurations" icon={<BsGear />}>
          <Link to="/configurations">Configuration</Link>
        </Menu.Item>

       
        <SubMenu
          key="/reports"
          icon={<FileDoneOutlined />}
          title="Reports"

        >
          <Menu.Item key="/reports/Pickup-Requests" style={{ width: '180px' }}>
            <Link to="/reports/Pickup-Requests">
              {<AuditOutlined />} Pickup Requests
            </Link>
          </Menu.Item>
          <Menu.Item key="/reports/Online-Payments">
            <Link to="/reports/Online-Payments">{<WifiOutlined />} Online Payments  </Link>
          </Menu.Item>
        </SubMenu>

        <Menu.Item key="/individual-landlord"  className='adjust-able-item' icon={<FileTextOutlined />}>
            <Link to="/individual-landlord">Individual Landlords</Link>
          </Menu.Item>

        <SubMenu
          className='non-adjust-able-item'
          key="/more"
          title="More"
          icon={<MoreOutlined />}
        

        >
          <Menu.Item  style={{ width: '200px' }} key="/users" icon={< UsergroupAddOutlined />}>
            <Link to="/users">Users</Link>
          </Menu.Item>
          <Menu.Item key="/configurations" icon={<BsGear />}>
            <Link to="/configurations">Configuration</Link>
          </Menu.Item>
          <Menu.Item key="/plans" icon={<FileTextOutlined />}>
            <Link to="/plans">Plans</Link>
          </Menu.Item>
          <Menu.Item key="/individual-landlord" icon={<FileTextOutlined />}>
            <Link to="/individual-landlord">Individual Landlords</Link>
          </Menu.Item>
          <Menu.Item  key="/serviceprovider" icon={<BsGear />}>
            <Link to="/serviceprovider">Service Providers</Link>
          </Menu.Item>
        </SubMenu> 

      </Menu>

    </Header>

  )
}
