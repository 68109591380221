import {
  GET_SERVICE_PROVIDER,
  GET_ALL_SERVICE_PROVIDER,
  ADD_NEW_SERVICE_PROVIDER,
} from "./constants";

var _ = require("lodash");

const initialState = {
  serviceProviders: [],
  allServiceProvider: [],
  serviceProviderDropDown: [],
  newServiceProvider: {},
};
export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case GET_SERVICE_PROVIDER:
      return { ...state, serviceProviders: data };
    case GET_ALL_SERVICE_PROVIDER:
      return {
        ...state,
        serviceProviders: data,
        serviceProviderDropDown: _.map(data, (item) => ({
          value: item.orgId,
          label: item.name,
          status: item.status,
        })),
      };
    case ADD_NEW_SERVICE_PROVIDER:
      return { ...state, newServiceProvider: data };
    default:
      return state;
  }
};
