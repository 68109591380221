import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Button, Form, Typography, message, Space, Spin, Upload, Input } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useForm, Controller } from "react-hook-form";
import { InputField, SelectField } from "../../../atoms/FormElement";
import { adminService } from "../../../../configs/constants";
import axios from "../../../../utils/axiosInceptor";
import { getAnnouncementList, getBuildingList } from "../ducks/actions";
import { getSingleUpload, uniquiFileName } from "../../../../utils/fileUpload";

const defaultValues: any = {
  name: "",
  email_address: "",
  phone_number: "",
  zone_status_type_name: "",
  assets: []
};



const { Paragraph } = Typography;
const dummyRequest = (response) => {
  console.log("check response", response);
  setTimeout(() => {
    response.onSuccess("ok");
  }, 0);
};

const AddAnnouncement = (props) => {
  const dispatch:any = useDispatch();
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [detail, setDetail]: any = useState()
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [custom, setCustom] = useState(false);
  const [building, setBuilding] = useState(false);
  const buildingList = useSelector((state:any) => state.announcement.buildingData);
  const [assetBuilding, setAssetBuilding] = useState([]);
  const { control, formState: { errors }, reset, handleSubmit, setValue, watch, register, setError } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues
  });

  //console.log('buildingList', buildingList)

  const resetForm = () => {
    reset({ defaultValues });
    props?.setOnClose(false)
  }

  useEffect(()=>{
    resetForm();
    setBuilding(false)
    setCustom(false)
  },[props?.onClose])

  useEffect(() => {
    dispatch(getBuildingList())
  }, [])


  const onFinish = async (values) => {
    let imageResp = null;
    let modifiedName = uniquiFileName(values.media.fileList[0].originFileObj.name);
    imageResp = await getSingleUpload(modifiedName, values.media.fileList[0].originFileObj);

    setLoader(true);
    setSubmited(true);
    const { announcementTitle, recipientType } = values;

    let recipientEmails = []
    if (values.recipientEmails) {
      recipientEmails = values.recipientEmails.split(/[ ,]+/)
    }

    let postJson = {}
    if (building) {
      let buildingArray = [];
      values.buildingType.map((value) => {
        buildingArray.push({
          assetId: value.value,
          assetName: value.label
        })
      })
      postJson = {
        announcementTitle,
        media: modifiedName,
        recipientType: 'asset',
        recipient: buildingArray,
      };
    } else {
      postJson = {
        announcementTitle,
        media: modifiedName,
        recipientType: recipientType.value,
        recipientEmails,
      };
    }

    if(imageResp) {
      const url = `${adminService}/admin/make-announcement`;
      try {
        await axios.post(url, postJson, { headers: { application: 4 } });
        setTimeout(() => {
          setLoader(false);
          setSubmited(false);
          reset();
          message.success("Announcement added successfully");
          dispatch(getAnnouncementList(1, ''));
          props.onCancel();
        }, 3000);
      } catch (e) {
        setLoader(false);
        setisError(true);
        const { response } = e;
        setErrorMessage(response?.data?.message);
        console.log("check", response);
      }
    } else {
      message.error("Image Not Uploaded. Please try again Later")
      setLoader(false);
      setSubmited(false);
    }
  };

  const recipientChange = (value) => {
    console.log('value')
    if (value.value == 'custom') {
      setCustom(true)
      setBuilding(false)
    } else if (value.value == 'building') {
      setBuilding(true)
      setCustom(false)
    } else {
      setBuilding(false)
      setCustom(false)
    }
  }

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          <Row gutter={24}>
            <Col span={12}>
              <SelectField
                isRequired={true}
                fieldname="recipientType"
                label="Select Recipent:"
                control={control}
                iProps={{ placeholder: "Select Recipent" }}
                rules={{ required: "Please select Recipent" }}
                initValue=""
                selectOption={props.reciepient}
                validate={errors.recipientType && "error"}
                validMessage={errors.recipientType && errors.recipientType.message}
                valueGot={detail?.recipientType}
                setValue={setValue}
                onChange={recipientChange}
              />
            </Col>
            {custom && (
              <>
                <Col span={12}>
                  <InputField
                    isRequired={true}
                    fieldname="recipientEmails"
                    label="Custom Email (Seperated by comma)"
                    control={control}
                    rules={{
                      required: "Please enter Custom Email",
                    }}
                    iProps={{ placeholder: "Enter Custom Email" }}
                    initValue=""
                    validate={errors.recipientEmails && "error"}
                    validMessage={errors.recipientEmails && errors.recipientEmails.message}
                    valueGot={detail?.recipientEmails}
                    setValue={setValue}
                  />
                </Col>
              </>
            )}

            {building && (
              <>
                <Col span={12}>
                  <SelectField
                    isRequired={true}
                    fieldname="buildingType"
                    label="Select Building:"
                    control={control}
                    iProps={{ placeholder: "Select Building", isMulti: true }}
                    rules={{ required: "Please select Building" }}
                    initValue=""
                    selectOption={buildingList.map((e) => { return { value: e.id, label: e.name } })}
                    validate={errors.buildingType && "error"}
                    validMessage={errors.buildingType && errors.buildingType.message}
                  />
                </Col>
              </>
            )}
            <Col span={12}>
              <InputField
                isRequired={true}
                fieldname="announcementTitle"
                label="Subject/Title"
                control={control}
                rules={{
                  required: "Please enter Subject/Title",
                }}
                iProps={{ placeholder: "Enter Subject or Title of announcement", maxLength: "50" }}
                initValue=""
                validate={errors.announcementTitle && "error"}
                validMessage={errors.announcementTitle && errors.announcementTitle.message}
                valueGot={detail?.announcementTitle}
                setValue={setValue}
              />
            </Col>
            <Col span={12}>
              <Form.Item
                required={true}
                label="Upload Image"
                validateStatus={errors.media && "error"}
                // help={errors.media && errors.media.message}
              >
                <Controller
                  name='media'
                  control={control}
                  rules={{ required: "Announcements image required" }}
                  defaultValue=""
                  render={({ field: {value, onChange} }) => (
                    <Upload
                      showUploadList={false}
                      accept="image/*"
                      maxCount={1}
                      customRequest={dummyRequest}
                      onChange={(e) => onChange(e)}
                      className="uploadWithbtn"
                    >
                      <Input
                        size="large"
                        value={value ? value.fileList[0].name : "Choose file"}
                        addonAfter="Browse"
                      />
                    </Upload>
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="space-between">
            <Col>
              <Button
                className="ag-gray-button-outline"
                htmlType="button"
                onClick={() => {
                  reset({ defaultValues });
                  setBuilding(false);
                  setCustom(false);
                  props.onCancel();
                }}
              >
                Back
              </Button>
            </Col>
            <Col>
              <Button data-testid='add-button' className="ag-green-button" htmlType="submit">
                Add
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </Form>
  );
};

export default AddAnnouncement;
