import { ALL_PLANS, PLANS } from "./constants";
var _ = require("lodash");

const initialState = {
  getPlans: [],
  getAllPlans: [],
  getPlansDrop: [],
};
export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case PLANS:
      return {
        ...state,
        getPlans: data,
      };
    case ALL_PLANS:
      return {
        ...state,
        getAllPlans: data,
        getPlansDrop: _.map(data, (item: any) => ({
          value: item.planId,
          label: item.name,
          perUserPrice: item.perUserPrice,
          perUnitPrice: item.perUnitPrice,
        })),
      };
    default:
      return state;
  }
};
