import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, Image, Button, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import {
  TeamOutlined,
  UserOutlined,
  ExportOutlined,
  EnvironmentFilled,
} from "@ant-design/icons";
import { CSVLink } from "react-csv";
import agResidence from "../../../assets/img/al-ghurair-residence.png";
import * as status_types from "./constants";
const { Text, Title } = Typography;
import moment from "moment";
import { adminService } from "../../../configs/constants";
import axios from "../../../utils/axiosInceptor";

const defaultNewStatusType = {
  value: "All",
  label: "All New Tickets",
};

const defaultCompletedStatusType = {
  value: "All",
  label: "All Closed Tickets",
};

const defaultInProgressStatusType = {
  value: "All",
  label: "All In Progress Tickets",
};

const PropertyCard = (props) => {
  const history = useNavigate();
  const [csvData, setCsvData] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [newStatusType, setNewStatusType] = useState([defaultNewStatusType]);
  const [completedStatusType, setCompletedtatusType] = useState([
    defaultCompletedStatusType,
  ]);
  const [progressStatusType, setProgressStatusType] = useState([
    defaultInProgressStatusType,
  ]);

  const { property, propertyData } = props;
  const { name, property_info, asset_status, createdAt, asset_id } = property;
  const propertyUnits: number = Math.round(Math.random() * 20);
  const propertyOccupied: number = Math.round(Math.random() * propertyUnits);

  const csvLinkEl = useRef();

  const exportAction = async (asset_id) => {
    const url = `${adminService}/admin/getTicketsExportData?assetId=${asset_id}`;
    try {
      const res = await axios.get(url);
      setCsvData(res?.data?.result);
    } catch (e) {
      const { response } = e;
      console.log("error", response?.data?.message);
    }
  };

  const exportDataHeader = [
    { label: "Case Id", key: "case_id" },
    { label: "Status", key: "status" },
    { label: "Initial Assessment", key: "initial_assessment" },
    { label: "Acknowledged", key: "acknowledged" },
    { label: "Reached Barcode Scanned", key: "reached_barcode_scanned" },
    { label: "Inprogress Barcode Scanned", key: "inprogress_barcode_scanned" },
    { label: "Ticket Type Changed", key: "ticket_type_changed" },
    { label: "Issue Resolved", key: "issue_resolved" },
    { label: "Issue Not Resolved", key: "issue_not_resolved" },
    { label: "Technician Forwarded", key: "technician_forwarded" },
    { label: "AG not Responsible", key: "ag_not_responsible" },
    { label: "Reopen Closed", key: "reopen_closed" },
    { label: "Pending Confirmation", key: "pending_confirmation" },
    { label: "Suspended", key: "suspended" },
    { label: "Reject", key: "reject" },
    { label: "Ticket Type Confirm", key: "ticket_type_confirm" },
    { label: "Tenant Status", key: "tenant_status" },
    { label: "Type", key: "type" },
    { label: "SubType", key: "subtype" },
    { label: "Tenant Name", key: "tenant_name" },
    { label: "Technician", key: "technician" },
    { label: "Appartment", key: "appartment" },
    { label: "Recuring", key: "recuring" },
    { label: "Description", key: "description" },
    { label: "Rating", key: "rating" },
    { label: "Review", key: "review" },
    { label: "Date", key: "date" },
  ];

  useEffect(() => {
    let data = [];
    const statusData = [
      ...newStatusType,
      ...completedStatusType,
      ...progressStatusType,
    ];
    if (csvData.length > 0) {
      csvData.map((item: any, key) => {
        console.log(
          "data check",
          item.CaseStatus[item.CaseStatus.length - 1].CaseStatusType
            .ticket_status,
        );
        // let status = statusData.find(
        //   (element) =>
        //     element.value ==
        //     item.CaseStatus[item.CaseStatus.length - 1].CaseStatusType
        //       .ticket_status,
        // );
        let status =
          item.CaseStatus[item.CaseStatus.length - 1].CaseStatusType
            .ticket_status;

        let initial_assessment = item?.CaseStatus.find(
          (element) =>
            element.CaseStatusType.ticket_status == "initial_assessment",
        );
        let acknowledged = item?.CaseStatus.find(
          (element) => element.CaseStatusType.ticket_status == "acknowledged",
        );
        let reached_barcode_scanned = item?.CaseStatus.find(
          (element) =>
            element.CaseStatusType.ticket_status == "reached_barcode_scanned",
        );
        let inprogress_barcode_scanned = item?.CaseStatus.find(
          (element) =>
            element.CaseStatusType.ticket_status ==
            "inprogress_barcode_scanned",
        );
        let ticket_type_changed = item?.CaseStatus.find(
          (element) =>
            element.CaseStatusType.ticket_status == "ticket_type_changed",
        );
        let issue_resolved = item?.CaseStatus.find(
          (element) => element.CaseStatusType.ticket_status == "issue_resolved",
        );
        let issue_not_resolved = item?.CaseStatus.find(
          (element) =>
            element.CaseStatusType.ticket_status == "issue_not_resolved",
        );
        let technician_forwarded = item?.CaseStatus.find(
          (element) =>
            element.CaseStatusType.ticket_status == "technician_forwarded",
        );
        let ag_not_responsible = item?.CaseStatus.find(
          (element) =>
            element.CaseStatusType.ticket_status == "ag_not_responsible",
        );
        let reopen_closed = item?.CaseStatus.find(
          (element) => element.CaseStatusType.ticket_status == "reopen_closed",
        );
        let pending_confirmation = item?.CaseStatus.find(
          (element) =>
            element.CaseStatusType.ticket_status == "pending_confirmation",
        );
        let suspended = item?.CaseStatus.find(
          (element) => element.CaseStatusType.ticket_status == "suspended",
        );
        let reject = item?.CaseStatus.find(
          (element) => element.CaseStatusType.ticket_status == "reject",
        );
        let ticket_type_confirm = item?.CaseStatus.find(
          (element) =>
            element.CaseStatusType.ticket_status == "ticket_type_confirm",
        );
        let type =
          item.CaseCategoryClassification[0]?.CaseCategory?.category_type;
        let stype =
          item.CaseCategorySecondaryClassification[0]?.CaseSubCategory
            ?.category_sub_type;
        let appartment = item.Asset?.name;
        let tenant =
          item.CaseRoleTenant?.length > 0
            ? item.CaseRoleTenant[0]?.Party?.name
            : "";
        let technician =
          item.CaseRoleTechnician?.length > 0
            ? item.CaseRoleTechnician[0]?.Party?.name
            : "";
        let recuring = item.ChildCase[0]?.parent_id;
        let tickid = item.CaseStatus[0]?.case_id;
        let description = item.description;
        let date =
          (item.ticket_date ? item.ticket_date : "") +
          " " +
          (item.ticket_start_time
            ? moment(item.ticket_start_time, "hh:mm:ss").format("LT") + " to "
            : "") +
          (item.ticket_end_time
            ? moment(item.ticket_end_time, "hh:mm:ss").format("LT")
            : "");
        let review = item.CaseReview?.review_text;
        let rating = item.CaseReview?.CaseReviewType?.ReviewType?.rating;
        let initial_assessment_data = initial_assessment
          ? moment(initial_assessment?.createdAt).format("LLL")
          : "";
        let acknowledged_data = acknowledged
          ? moment(acknowledged?.createdAt).format("LLL")
          : "";
        let reached_barcode_scanned_data = reached_barcode_scanned
          ? moment(reached_barcode_scanned?.createdAt).format("LLL")
          : "";
        let inprogress_barcode_scanned_data = inprogress_barcode_scanned
          ? moment(inprogress_barcode_scanned?.createdAt).format("LLL")
          : "";
        let ticket_type_changed_data = ticket_type_changed
          ? moment(ticket_type_changed?.createdAt).format("LLL")
          : "";
        let issue_resolved_data = issue_resolved
          ? moment(issue_resolved?.createdAt).format("LLL")
          : "";
        let issue_not_resolved_data = issue_not_resolved
          ? moment(issue_not_resolved?.createdAt).format("LLL")
          : "";
        let technician_forwarded_data = technician_forwarded
          ? moment(technician_forwarded?.createdAt).format("LLL")
          : "";
        let ag_not_responsible_data = ag_not_responsible
          ? moment(ag_not_responsible?.createdAt).format("LLL")
          : "";
        let reopen_closed_data = reopen_closed
          ? moment(reopen_closed?.createdAt).format("LLL")
          : "";
        let pending_confirmation_data = pending_confirmation
          ? moment(pending_confirmation?.createdAt).format("LLL")
          : "";
        let suspended_data = suspended
          ? moment(suspended?.createdAt).format("LLL")
          : "";
        let reject_data = reject ? moment(reject?.createdAt).format("LLL") : "";
        let ticket_type_confirm_data = ticket_type_confirm
          ? moment(ticket_type_confirm?.createdAt).format("LLL")
          : "";

        const tenantStatus = () => {
          switch (status) {
            case "issue_not_resolved":
              return "Not Solved";
            case "ag_not_responsible":
              return "Not Solved";
            case "issue_resolved":
              return "Solved";
          }
        };

        const stated = () => {
          switch (status) {
            case "initial_assessment":
              return "New";
            case "technician_forwarded":
              return "New";
            case "acknowledged":
              return "New";
            case "issue_resolved":
              return "Closed";
            case "ag_not_responsible":
              return "Closed";
            case "reject":
              return "Closed";
          }
        };

        data.push({
          case_id: tickid,
          status: stated() || "In Progress",
          initial_assessment: initial_assessment_data,
          acknowledged: acknowledged_data,
          reached_barcode_scanned: reached_barcode_scanned_data,
          inprogress_barcode_scanned: inprogress_barcode_scanned_data,
          ticket_type_changed: ticket_type_changed_data,
          issue_resolved: issue_resolved_data,
          issue_not_resolved: issue_not_resolved_data,
          technician_forwarded: technician_forwarded_data,
          ag_not_responsible: ag_not_responsible_data,
          reopen_closed: reopen_closed_data,
          pending_confirmation: pending_confirmation_data,
          suspended: suspended_data,
          reject: reject_data,
          ticket_type_confirm: ticket_type_confirm_data,
          tenant_status: tenantStatus(),
          type: type,
          subtype: stype,
          tenant_name: tenant,
          technician: technician,
          appartment: appartment,
          recuring: recuring,
          description: description,
          rating: rating,
          review: review,
          date: date,
        });
      });
      setExportData(data);
      setTimeout(() => {
        //@ts-ignore
        csvLinkEl?.current?.link.click();
      }, 300);
    }
  }, [csvData]);

  const Statuses = () => {
    switch (asset_status) {
      case status_types.PROPERTY_DISABLED:
        return false;
      case status_types.PROPERTY_DRAFT:
        return false;
      case status_types.PROPERTY_RENTED:
        return true;
      case status_types.PROPERTY_PUBLISHED:
        return true;
    }
  };
  return (
    <Card bordered={false}>
      <Row gutter={[24, 24]}>
        <Col span={8} className="imageCard">
          <Image
            preview={false}
            width={235}
            height={165}
            alt="Al Ghurair Residence"
            src={agResidence}
          />
        </Col>
        <Col span={16}>
          <Card
            bordered={false}
            title={name}
            className="ag-nospace-head ag-nospace-body"
          >
            <Text className="lineHeight28">
              <EnvironmentFilled /> {property_info[0]?.street_address},{" "}
              {property_info[0]?.city.name}, {property_info[0]?.country.name}
            </Text>
            <Title level={5} className="lineHeight28 mb-0">
              Status:{" "}
              <span
                className={`${Statuses() === true ? "ag-success" : "ag-error"}`}
              >
                {Statuses() ? `Active ` : `Inactive `}
              </span>
            </Title>
            <Title level={5} className="lineHeight28 m-0">
              Uploaded On:{" "}
              <span className="colorGrey">
                {createdAt && moment(createdAt).format("DD-MM-YY")}
              </span>
            </Title>
          </Card>
        </Col>
      </Row>
      <Row gutter={24} justify={"space-around"}>
        <Col span="8">
          <Button
            className="w-100"
            type="primary"
            icon={<TeamOutlined />}
            onClick={() =>
              history(`/tenants/${asset_id}`,{
                state: {
                  info: property_info,
                },
              })
            }
          >
            Tenants
          </Button>
        </Col>
        {/* <Col span="8">
          <Button
            className="w-100"
            type="primary"
            icon={<UserOutlined />}
            onClick={() => history(`/maintenance/${asset_id}`)}
          >
            Maintenance
          </Button>
        </Col> */}
        <Col span="8">
          <CSVLink
            target="_blank"
            data={exportData}
            headers={exportDataHeader}
            filename={"Propertydetail.csv"}
            ref={csvLinkEl}
          />
          <Button
            className="w-100"
            type="primary"
            icon={<ExportOutlined />}
            onClick={() => exportAction(asset_id)}
          >
            Export
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default PropertyCard;
