import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import axios from "../../../../../utils/axiosInceptor";
import { InputField, SelectField } from "../../../../atoms/FormElement";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { adminService } from "../../../../../configs/constants";
import { BsPlusCircle, BsArrowCounterclockwise } from "react-icons/bs";
import { Row, Col, Button, Form, Typography, message, Space, Spin } from "antd";
import { configTimeSlot } from "../../../../../utils/helper";
import {
  getMaintenanceTypes,
  getMaintenanceSubTypes,
} from "../../ducks/actions";

const defaultValues: any = {
  name: "",
};

const { Paragraph } = Typography;

const UpdateServices = (props) => {
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const {
    reset,
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues,
  });
  const condition = props.type === "category";
  const dispatch: any = useDispatch();

  useEffect(() => {
    if (props?.childDetail) {
      setValue(
        "timeInMin",
        configTimeSlot.filter(
          (item) => item.value === props?.childDetail.timeInMin
        )
      );
    }
  }, [props.childDetail]);

  const onFinish = async (value) => {
    setLoader(true);
    setSubmited(true);
    try {
      let url =
        props.childDetail.type == "Maintenance Types"
          ? `${adminService}/maintenance/category/${props.childDetail.categoryId}`
          : `${adminService}/maintenance/subcategory/${props.childDetail.categoryId}/${props.childDetail.subCategoryId}`;
      let postData = {
        name: `${value.name}`,
        timeInMin: value.timeInMin.value,
      };
      await axios.patch(url, postData, { headers: { application: 4 } });
      reset();
      setLoader(false);
      setSubmited(false);
      dispatch(getMaintenanceTypes())
      message.success("Updated successfully");
      condition
        ? dispatch(getMaintenanceTypes())
        : dispatch(getMaintenanceSubTypes(props.childDetail.categoryId));
      props.setVisible(false);
    } catch (error) {
      reset();
      setLoader(false);
      setisError(false);
      setSubmited(false);
      message.error(error.response.data?.error || error.response.data.message);
    }
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          <Row gutter={24}>
            <Col span={12}>
              <InputField
                isRequired={true}
                fieldname="name"
                label={
                  condition
                    ? "Change Category Title"
                    : "Change Sub-Category Title"
                }
                control={control}
                rules={{
                  required: condition
                    ? "Please enter category title"
                    : "Please enter Sub-Category title",
                }}
                iProps={{
                  placeholder: condition ? "Plumbing" : "Water Leakage",
                }}
                setValue={setValue}
                valueGot={props.childDetail.title}
                initValue=""
                validate={errors.name && "error"}
                validMessage={errors.name && errors.name.message}
              />
            </Col>

            {!condition && (
              <Col span={8}>
                <SelectField
                  control={control}
                  isRequired={true}
                  fieldname="timeInMin"
                  selectOption={configTimeSlot}
                  label="Choose time in Minutes"
                  validate={errors.timeInMin && "error"}
                  iProps={{ placeholder: "Choose time slot" }}
                  rules={{ required: "Please select the time slot" }}
                  validMessage={errors.timeInMin && errors.timeInMin.message}
                />
              </Col>
            )}
          </Row>
          <Row gutter={24} justify="space-between">
            <Col>
              <Button
                className="ag-error theme_grey_btn"
                htmlType="button"
                onClick={() => props.setVisible(false)}
              >
                {<BsArrowCounterclockwise />} Back
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button theme_btn" htmlType="submit">
                {<BsPlusCircle />} Update
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </Form>
  );
};

export default UpdateServices;