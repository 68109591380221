import React, { useState, useEffect } from "react";

import type { MenuProps } from "antd";
import { Popup } from "../../atoms/Popup";
import { EditOutlined } from "@ant-design/icons";
import { getServicePrivider } from "./ducks/actions";
import AddServiceProvider from "./AddServiceProvider";
import { useDispatch, useSelector } from "react-redux";
import SectionHeader from "../../molecules/SectionHeader";
import DashboardLayout from "../../molecules/DashboardLayout";
import { Button, Col, Row, Space, Table, Typography } from "antd";

const { Title } = Typography;

var _ = require("lodash");

export default (props) => {
  const dispatch: any = useDispatch();
  const [selected, setSelected] = useState();
  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [updates, setUpates] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const serviceprovider = useSelector(
    (state: any) => state.serviceprovider.serviceProviders
  );

  useEffect(() => {
    dispatch(getServicePrivider(page, search));
  }, []);

  useEffect(() => {
    const filterData = () => {
      setPage(1);
      dispatch(getServicePrivider(page, search));
    };

    const debouncer = setTimeout(() => {
      filterData();
    }, 1500);
    return () => {
      clearTimeout(debouncer);
    };
  }, [search]);

  const addNew = () => {
    setVisible(true);
    setTrigger(0);
  };

  const onDropClick: MenuProps["onClick"] = ({ key }) => {
    setVisible(true);
    setTrigger(+key);
  };

  const onEdit = (record) => {
    setVisible(true);
    setTrigger(1);
    setSelected(record);
  };

  const editUnit = (record) => {
    setVisible(true);
    setTrigger(3);
    setSelected(record);
  };

  const onAdd = () => {
    setVisible(false);
    dispatch(getServicePrivider(1, search));
  };

  const onTopSearch = (e) => {
    setSearch(e.target.value);
  };

  const columns: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 220,
    },
    {
      title: "Contact Person Name",
      dataIndex: "username",
      key: "username",
      width: 220,
      render: (_, item) => <p>{item?.user?.name}</p>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 220,
      render: (_, item) => <p>{item?.user?.email}</p>,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      width: 220,
      render: (_, item) => (
        <p>{`${item?.user?.countryCode} ${item?.user?.mobile}`}</p>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 150,
      render: (text, record) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              className="p-0 ag-success"
              onClick={() => onEdit(record)}
            >
              <div className="Simple-tooltip">
                <EditOutlined />
                <section className="tt-content">Edit</section>
              </div>
            </Button>
          </Space>
        );
      },
    },
  ];

  const popup = [
    {
      title: (
        <Title level={3} className="m-0">
          Add New Service Provider
        </Title>
      ),
      content: (
        <AddServiceProvider
          onSubmit={onAdd}
          onUpdate={setUpates}
          onchangeUpdate={updates}
          onCancel={() => setVisible(false)}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="m-0">
          Edit Service Provider
        </Title>
      ),
      content: (
        <AddServiceProvider
          editMode={true}
          onSubmit={onAdd}
          selected={selected}
          onUpdate={setUpates}
          onchangeUpdate={updates}
          onCancel={() => setVisible(false)}
        />
      ),
      width: 650,
    },
  ];

  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => setVisible(false),
  };

  return (
    <>
      <DashboardLayout>
        <SectionHeader
          addMoreText={"Unit"}
          addNewAction={addNew}
          dropTrigger={["click"]}
          heading="Service Providers"
          // search={onTopSearch}
        />
        <Row gutter={24}>
          <Col span={24}>
            <Table
              className="custom-table org_table_wrap organizations"
              columns={columns}
              rowKey={(record, index) => index}
              expandable={{}}
              pagination={{
                current: page,
                pageSize: serviceprovider?.perPage ?? 10,
                showSizeChanger: false,
                className: "ag-property-text-pagination mb-1",
                position: ["topLeft"],
                onChange: (e) => {
                  setPage(e);
                  dispatch(getServicePrivider(e, search));
                },
                total: serviceprovider?.totalCount,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} Service Provider`,
              }}
              dataSource={serviceprovider?.data}
              scroll={{ y: 500 }}
            />
          </Col>
        </Row>
      </DashboardLayout>
      <Popup {...popupProps} />
    </>
  );
};
