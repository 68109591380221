import { Button, Col, Row, Space, Table, Typography, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import DashboardLayout from "../../molecules/DashboardLayout";
import PlanPanel from "../../molecules/PlanPanel";
import SectionHeader from "../../molecules/SectionHeader";
import { EditOutlined } from "@ant-design/icons";
import { Popup } from "../../atoms/Popup";
import AddPlan from "./AddPlans";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getPlans } from "./ducks/actions";
import { InputCheckbox } from "../../atoms/FormElement";

const { Title, Text } = Typography;

const Plans = (props) => {
  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState();
  const [updates, setUpates] = useState(false);

  const dispatch: any = useDispatch();

  const plans = useSelector((state: any) => state.plans.getPlans);

  useEffect(() => {
    if (plans.length == 0) dispatch(getPlans(1));
  }, [plans]);

  const addNew = () => {
    setVisible(true);
    setTrigger(0);
  };

  const onEdit = (record) => {
    setVisible(true);
    setTrigger(1);
    setSelected(record);
  };

  const onAdd = () => {
    setVisible(false);
    dispatch(getPlans(1));
  };

  const columns: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 220,
    },
    {
      title: "Price Per Unit (AED)",
      dataIndex: "perUnitPrice",
      key: "perUnitPrice",
      width: 220,
    },
    {
      title: "Price Per User (AED)",
      dataIndex: "perUserPrice",
      key: "perUserPrice",
      width: 220,
    },
    {
      title: "Renewal",
      dataIndex: "isRenewal",
      key: "isRenewal",
      width: 100,
      render: (e) => <Checkbox disabled defaultChecked={e} />,
    },
    {
      title: "New Lease",
      dataIndex: "isNewLease",
      key: "isNewLease",
      width: 120,
      render: (e) => <Checkbox disabled defaultChecked={e} />,
    },
    {
      title: "Maintenance",
      dataIndex: "isMaintenance",
      key: "isMaintenance",
      width: 150,
      render: (e) => <Checkbox disabled defaultChecked={e} />,
    },
  ];

  const popup = [
    {
      title: (
        <Title level={3} className="m-0">
          Add New Plan
        </Title>
      ),
      content: (
        <AddPlan
          onSubmit={onAdd}
          onCancel={() => setVisible(false)}
          onUpdate={setUpates}
          onchangeUpdate={updates}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="m-0">
          Edit Plans
        </Title>
      ),
      content: (
        <AddPlan
          onSubmit={onAdd}
          selected={selected}
          onCancel={() => setVisible(false)}
          onUpdate={setUpates}
          onchangeUpdate={updates}
        />
      ),
      width: 650,
    },
  ];

  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => setVisible(false),
  };

  return (
    <>
      <DashboardLayout>
        <SectionHeader heading="Plans" addNewAction={addNew} />
        <Row gutter={24}>
          <Col xl={24}>
            <Table
              className="custom-table org_table_wrap"
              columns={columns}
              pagination={{
                current: plans?.currentPage,
                pageSize: plans?.perPage,
                showSizeChanger: false,
                className: "ag-property-text-pagination mb-1",
                position: ["topLeft"],
                onChange: (e) => {
                  setPage(e);
                  dispatch(getPlans(e));
                },
                total: plans?.totalCount,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} Plans`,
              }}
              dataSource={plans?.data}
              scroll={{ y: 500 }}
            />

            {/* CARD FORMAT PLANS  */}
            {/* <Row gutter={24}>
            <Col span={8}>
             <PlanPanel button={{
               backgroundColor:"#447093",
               text:"You are using this plan currently."
               }}/>
            </Col>
            <Col span={8}>
            <PlanPanel button={{
              backgroundColor:"#044C26",
              text:"Upgrade to this plan."
              }}/>
            </Col>
            <Col span={8}>
            <PlanPanel button={{
              backgroundColor:"#044C26",
              text:"Upgrade to this plan."
              }}/>
            </Col>
          </Row> */}
          </Col>
        </Row>
      </DashboardLayout>
      <Popup {...popupProps} />
    </>
  );
};

export default Plans;
