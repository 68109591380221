import React from "react";
import error from '../../../assets/img/404.png';
import {Link} from 'react-router-dom';
const Error = () => {
  return (
    <div className="error_page">
      <Link to='/'><img src={error}/></Link>
     
    </div>
  );
};

export default Error;
