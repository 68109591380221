import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getIndividualLandlords } from "./ducks/action";
import DashboardLayout from "../../molecules/DashboardLayout";
import SectionHeader from "../../molecules/SectionHeader";
import { getPlans } from "../Plans/ducks/actions";
import { Button, Col, Row, Space, Table } from "antd";
import { AuditOutlined, EditOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

// Cached data
const IndividualLandlord = (props) => {
  const dispatch: any = useDispatch();
  const landlords = useSelector(
    (state: any) => state.landlords.getIndividualLandlords
  );

  // state data
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState(null);

  const navigate = useNavigate();

  const filterOptions: any = ['name', 'email', 'mobile'];

  const validatePhoneNumber = (phoneNumber, record) => { 

    if(record.mobile && record.mobile.startsWith('971')) {
      return `+971 ${record.mobile.slice(3)}`
    }

    return record.mobile ? `${record.countryCode} ${record.mobile}` : `-`
  }

  const columns: any = [
    {
      title: "Signup Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 220,
      render: (date: string) => dayjs(date).format("DD-MM-YYYY"),
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 220,
      render: (name) => (name ? name : "-"),
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
      width: 160,
      render: (phoneNumber, record) => validatePhoneNumber(phoneNumber, record),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 120,
    },
    {
      title: "UAE Pass",
      dataIndex: "isUaePassLinked",
      key: "isUaePassLinked",
      width: 120,
      render: (isPassLinked) => (isPassLinked ? 'Yes' : 'No'),
    },
    {
      title: "Properties",
      dataIndex: "propertyCount",
      key: "propertyCount",
      width: 120,
    },
    {
      title: "Last Property Added",
      dataIndex: "lastPropertyAddedAt",
      key: "lastPropertyAddedAt",
      width: 220,
      render: (date: string) => (date ? dayjs(date).format("DD-MM-YYYY") : "-"),
    },
    {
      title: "Tenants",
      dataIndex: "tenants",
      key: "tenants",
      width: 120,
    },
    {
      title: "Subscription Expiry",
      dataIndex: "endDate",
      key: "endDate",
      width: 220,
      render: (date: string) => (date ? dayjs(date).format("DD-MM-YYYY") : "-"),
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 150,
      render: (text, record) => {
        return (
          <Space size={16}>
            {/* <Button
              type="link"
              onClick={() => console.log("edit clicked !")}
              className="p-0 ag-success"
            >
              <div className="Simple-tooltip">
                <EditOutlined />
                <section className="tt-content">Edit</section>
              </div>
            </Button> */}
            <Button
              type="link"
              onClick={() => {
                navigate(`/properties/${record?.userId}`);
              }}
              className="p-0 ag-success"
            >
              <div className="Simple-tooltip">
                <AuditOutlined />
                <section className="tt-content">View Details</section>
              </div>
            </Button>

            {/* Enable disable switch */}
            {/* <div className="Simple-tooltip">
              <Switch
                className="switch"
                onChange={(e) => onChange(e, record)}
                defaultChecked={record.status === "enable" ? true : false}
              />
              <section className="tt-content">{record.status}</section>
            </div> */}
          </Space>
        );
      },
    },
  ];

  const onTopSearch = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    const filterData = () => {
      setPage(1);
      dispatch(getIndividualLandlords(1, searchBy, search));
    };

    const debouncer = setTimeout(() => {
      filterData();
    }, 1500);
    return () => {
      clearTimeout(debouncer);
    };
  }, [search]);

  useEffect(() => {
    if (landlords.length === 0) {
      dispatch(getIndividualLandlords(page));
    }
  }, [landlords]);

  const triggerSearchBy = (filteredValue) => {
    setSearchBy(filteredValue.label)
  }

  return (
    <>
      <DashboardLayout>
        <SectionHeader search={onTopSearch} heading="Landlords" showOption={filterOptions} handleShow={triggerSearchBy}/>
        <Row gutter={24}>
          <Col xl={24}>
            <Table
              className="custom-table org_table_wrap"
              columns={columns}
              pagination={{
                current: landlords?.currentPage,
                pageSize: landlords?.perPage,
                showSizeChanger: false,
                className: "ag-property-text-pagination mb-1",
                position: ["topLeft"],
                onChange: (e) => {
                  setPage(e);
                  dispatch(getIndividualLandlords(e, searchBy, search));
                },
                total: landlords?.totalCount,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} Landlords`,
              }}
              dataSource={landlords?.data}
              scroll={{ y: 500 }}
              rowKey={(item) => item._id}
            ></Table>
          </Col>
        </Row>
      </DashboardLayout>
    </>
  );
};

export default IndividualLandlord;
