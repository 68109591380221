import { Button, Col, Form, Row, Space, Spin, Avatar, Card } from "antd";
import React, { useState, useEffect } from "react";


const ShowDocument = (props) => {

  useEffect(() => {
    return () => {
      props
    }
  }, [])

  return (
    <>
      <Row justify="center" align="middle">
        <Col>
          <Card>
            <Card.Meta
              avatar={<Avatar
                size={750} // Adjust the size value as needed
                src={props?.documentObj?.location}
                shape="square"
                alt="Image"
              />
            }
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ShowDocument;
