import React, { CSSProperties, useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Checkbox,
  Typography,
  TimePicker,
  DatePicker,
  InputNumber,
} from "antd";
import { Controller } from "react-hook-form";
import { default as RSelect, AriaOnFocus } from "react-select";

const { Text } = Typography;
import moment from "moment";

export const InputField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
    fieldKey,
    className,
    getValue,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props.valueGot]);
  return (
    <>
      <Form.Item
        required={isRequired ? isRequired : false}
        label={label}
        validateStatus={validate}
        help={validMessage}
        // fieldKey={fieldKey}
        className={className}
      >
        <Controller
          name={fieldname}
          control={control}
          defaultValue={initValue || initValue == 0 ? initValue : ""}
          rules={rules}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              value={value}
              onChange={(e) => {
                onChange(e);
                props?.onChange && props.onChange(e.target.value);
              }}
              onBlur={props.onBlur}
              {...iProps}
            />
          )}
        />
      </Form.Item>
    </>
  );
};

export const NumberInputField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
    fieldKey,
    className,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props.valueGot]);

  return (
    <>
      <Form.Item
        required={isRequired ? isRequired : false}
        label={label}
        validateStatus={validate}
        help={validMessage}
        fieldKey={fieldKey}
        className={className}
      >
        <Controller
          name={fieldname}
          control={control}
          defaultValue={initValue || initValue == 0 ? initValue : ""}
          rules={rules}
          render={({ field: { onChange, onBlur, value } }) => (
            <InputNumber
              value={value}
              onChange={(e) => {
                onChange(e);
              }}
              onBlur={props.onBlur}
              {...iProps}
            />
          )}
        />
      </Form.Item>
    </>
  );
};

export const InputHiddenField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
    fieldKey,
    className,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props.valueGot]);

  return (
    <>
      <Form.Item
        required={isRequired ? isRequired : false}
        label={label}
        validateStatus={validate}
        help={validMessage}
        fieldKey={fieldKey}
        className={className}
      >
        <Controller
          name={fieldname}
          control={control}
          defaultValue={initValue || initValue == 0 ? initValue : ""}
          rules={rules}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              type="hidden"
              value={value}
              onChange={onChange}
              onBlur={props.onBlur}
              {...iProps}
            />
          )}
        />
      </Form.Item>
    </>
  );
};
export const InputPassword = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props.valueGot]);

  return (
    <>
      <Form.Item
        required={isRequired ? isRequired : false}
        label={label}
        validateStatus={validate}
        help={validMessage}
      >
        <Controller
          name={fieldname}
          control={control}
          defaultValue={initValue || initValue == 0 ? initValue : ""}
          rules={rules}
          // render={()=> (<Input.Password {...iProps} /> )}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Input.Password
              value={props.disabled ? "" : value}
              ref={ref}
              onChange={(e) => {
                onChange(e);
                props.onChange && props.onChange(e);
              }}
              onBlur={props.onBlur}
              {...iProps}
              disabled={props.disabled}
            />
          )}
        />
      </Form.Item>
    </>
  );
};

export const SelectField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    selectOption,
    isRequired,
    initValue,
    validate,
    validMessage,
    field,
    navElement
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props]);

  return (
    <Form.Item
      required={isRequired ? isRequired : false}
      label={label}
      validateStatus={validate}
      help={validMessage}
      {...field}
    >
      <Controller
        name={fieldname}
        control={control}
        rules={rules}
        defaultValue={initValue ? initValue : ""}
        render={({ field: { onChange, onBlur, value } }) => (
          <RSelect
            value={value}
            styles={{
              control: (val) => ({ ...val, minHeight: navElement ? 45 : 32, ...(navElement && {borderWidth: 0, borderRightWidth: '1px'}) }),
              valueContainer: (vcontain) => ({
                ...vcontain,
                padding: "0 8px",
                textTransform: "capitalize",
              }),
              dropdownIndicator: (icontain) => ({ ...icontain, padding: 5, ...(navElement && {color: '#000000'}) }),
              indicatorSeparator: (icontain) => ({
                ...icontain,
                backgroundColor: "#fff",
              }),
              option: (vcontain) => ({
                ...vcontain,
                textTransform: "capitalize",
              }),
              placeholder: (place) => ({ ...place, color: "rgba(0,0,0,.3)", ...(navElement && {fontSize: '18px'}) }),
            }}
            onChange={(e) => {
              onChange(e);
              props.onChange && props.onChange(e);
            }}
            onBlur={props.onBlur}
            {...iProps}
            options={selectOption}
            theme={(theme) => ({
              ...theme,
              borderRadius: 2,
              colors: { ...theme.colors, primary: "#767676" },
            })}
          />
        )}
      />
    </Form.Item>
    // <Form.Item
    //   required={isRequired ? isRequired : false}
    //   label={label}
    //   validateStatus={validate}
    //   help={validMessage}
    //   noStyle={props.noStyle}
    // >
    //   {iProps?.placeholder && <Text className="custom-placeholder">{iProps.placeholder}</Text>}
    //   <Controller
    //     name={fieldname}
    //     control={control}
    //     defaultValue={initValue ? initValue : ""}
    //     rules={rules}
    //     render={()=> (<Select className="with-place" {...iProps}>{selectOption}</Select>}
    //   />
    // </Form.Item>
  );
};

export const SelectNormal = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    selectOption,
    isRequired,
    validate,
    validMessage,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props.valueGot]);

  return (
    <Form.Item
      required={isRequired ? isRequired : false}
      label={label}
      validateStatus={validate}
      help={validMessage}
      noStyle={props.noStyle}
    >
      <Controller
        name={fieldname}
        control={control}
        defaultValue={initValue ? initValue : ""}
        rules={rules}
        render={() => <Select {...iProps}>{selectOption}</Select>}
      />
    </Form.Item>
  );
};

export const DateField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    maxDate,
    selected,
    startDate,
    endDate,
    initValue,
    isRequired,
    validate,
    validMessage,
    isEndDateGrater,
  } = props;

  useEffect(() => {
    if (props.valueGot) {
      props.setValue(fieldname, props.valueGot);
    }
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, []);
  return (
    <>
      <Form.Item
        required={isRequired ? isRequired : false}
        label={label}
        validateStatus={validate}
        help={validMessage}
      >
        <Controller
          name={fieldname}
          control={control}
          defaultValue={initValue ? initValue : ""}
          rules={rules}
          render={({ field: { onChange, value, ref } }) => {
            return (
              <DatePicker
                ref={ref}
                style={{ width: "100%" }}
                value={props.disabled ? "" : value}
                onChange={(e) => {
                  onChange(e);
                  props.onChange && props.onChange(e);
                }}
                onBlur={props.onBlur}
                {...iProps}
              />
            );
          }}
          // <DatePicker
          // defaultValue={moment()}
          //   style={{ width: "100%" }}
          //   value={props.disabled ? '' : value}
          //   // ref={ref}
          //   disabledDate={(current) => {
          //     return moment().add(-1, 'days')  >= current &&
          //          moment().add(1, 'month')  <= current;
          //     }}
          //   onChange={(e) => {
          //     onChange(e);
          //     props.onChange && props.onChange(e);
          //   }}
          //   ref={(dateSelect) => { dateSelect = dateSelect }}
          //   onBlur={props.onBlur}
          //   {...iProps}
          //   disabled={props.disabled}

          // />}
        />
      </Form.Item>
    </>
  );
};

export const InputCheckbox = (props) => {
  const {
    fieldname,
    label,
    control,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, []);

  return (
    <Form.Item
      required={isRequired ? isRequired : false}
      validateStatus={validate}
      help={validMessage}
      valuePropName="checked"
      noStyle
    >
      <Controller
        name={fieldname}
        control={control}
        rules={rules}
        defaultValue={initValue ? initValue : ""}
        render={({ field: { value, onChange } }) => {
          return (
            <Checkbox
              checked={value}
              defaultChecked={true}
              className={props.class}
              onChange={(e) => {
                onChange(e.target.checked);
              }}
            >
              {label}
            </Checkbox>
          );
        }}
      />
    </Form.Item>
  );
};

export const TimeField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props.valueGot]);

  return (
    <>
      <Form.Item
        required={isRequired ? isRequired : false}
        label={label}
        validateStatus={validate}
        help={validMessage}
      >
        <Controller
          name={fieldname}
          control={control}
          defaultValue={initValue ? initValue : ""}
          rules={rules}
          render={() => <TimePicker style={{ width: "100%" }} {...iProps} />}
        />
      </Form.Item>
    </>
  );
};

export const TimeRangeField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props.valueGot]);

  return (
    <>
      <Form.Item
        required={isRequired ? isRequired : false}
        label={label}
        validateStatus={validate}
        help={validMessage}
      >
        <Controller
          name={fieldname}
          control={control}
          defaultValue={initValue ? initValue : ""}
          rules={rules}
          render={() => (
            <TimePicker.RangePicker style={{ width: "100%" }} {...iProps} />
          )}
        />
      </Form.Item>
    </>
  );
};
export const TextAreaField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props.valueGot]);

  return (
    <Form.Item
      required={isRequired ? isRequired : false}
      label={label}
      validateStatus={validate}
      help={validMessage}
      className={props.classes}
    >
      <Controller
        name={fieldname}
        control={control}
        defaultValue={initValue || initValue == 0 ? initValue : ""}
        rules={rules}
        render={() => <Input.TextArea {...iProps} />}
      />
    </Form.Item>
  );
};

// ========================
