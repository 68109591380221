import React, { useState, useEffect } from "react";
import { Pagination } from "antd";
import { Row, Col, Input, Button, Typography, Tabs, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import Grid from "../../atoms/Grid/";
import { popupConfirm, Popup } from "../../atoms/Popup";
import SectionHeader from "../../molecules/SectionHeader";
import DashboardLayout from "../../molecules/DashboardLayout";
import BreadCrumb from "../../atoms/BreadCrumb";
import AddSupervisor from "./component/AddSupervisor";
import {
  getAssignedList,
  getUnAssignedList,
  getBuildings,
} from "./ducks/actions";
import { adminService } from "../../../configs/constants";
import AddBuilding from "./component/AddBuilding";

const { Title } = Typography;
const { TabPane } = Tabs;

const gridProps = {
  sortable: true,
  filter: false,
  resizable: true,
};

const breadcrumbList: any = [
  {
    label: "Supervisors",
    route: "/supervisors",
  },
];

const Supervisors = () => {
  const supervisorList = useSelector((state:any) => state.supervisor.assignedList);
  const unassignedList = useSelector(
    (state:any) => state.supervisor.unassignedList,
  );
  const unassignedCount = useSelector(
    (state:any) => state.supervisor.unassignedCount,
  );
  const buildings = useSelector((state:any) => state.supervisor.buildings);
  const assignedCount = useSelector((state:any) => state.supervisor.assignedCount);
  const [tabKey, setTabkey] = useState("1");
  const [searchText, setSearchText] = useState();
  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [page, setPage] = useState(1);
  const [page2, setPage2] = useState(1);
  const [assignIDs, setAssignIDs] = useState(1);
  const [supervisorRowData, setSupervisorRowData] = useState({})
  const dispatch:any = useDispatch();
  const [onClose, setOnClose] = useState(false);
  const [IDs, setIDs] = useState(1)

  const getList = () => {
    dispatch(getAssignedList());
    dispatch(getUnAssignedList());
    dispatch(getBuildings());
  };

  useEffect(() => {
    getList();
  }, []);

  const searchField = (
    <Input
      type="text"
      onChange={(e) => onSearch(e)}
      placeholder=""
      suffix={<SearchOutlined />}
    />
  );
  const onSearch = (e) => {
    if (e.target.value) {
      setSearchText(e.target.value);
    } else {
      setSearchText(undefined);
    }
  };

  const fetchData = (e) => {
    if (e >= 2) {
      if (e > page) {
        setIDs((e - 1) * 50 + 1);
        setPage(e);
      } else if (e < page) {
        setIDs((e - 1) * 50 + 1);
        setPage(e);
      }
    } else {
      setIDs(e);
      setPage(e);
    }

    dispatch(getUnAssignedList(e));
  };

  const fetchPagData = (e) => {
    if (e >= 2) {
      if (e > page2) {
        setAssignIDs((e - 1) * 50 + 1);
        setPage2(e);
      } else if (e < page2) {
        setAssignIDs((e - 1) * 50 + 1);
        setPage2(e);
      }
    } else {
      setAssignIDs(e);
      setPage2(e);
    }
    dispatch(getAssignedList(e));
  };

  const handleBuilding = (data) => {
    setVisible(true);
    setTrigger(1);
    setSupervisorRowData(data)
  };

  const propertiesComponent = (params) => {
    return (
      <Select
        className="Sentence ag-blue"
        placeholder="Select"
        style={{ width: '90%' }}
        defaultValue={params?.data?.buildings[0]?.name}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Button
              type="link"
              className="fontWeight600 pl-1 ag-green"
              onClick={() => handleBuilding(params.data)}
            >
              + Add a Building
            </Button>
          </>
        )}
      >
        {<React.Fragment>
          {params?.data?.buildings.map((building, distinct) => {
            return (
              <Select.Option
                key={`${distinct}`}
                value={building?.id}
                className="Sentence active-select"
              >
                {building?.name}
              </Select.Option>
            );
          })}
        </React.Fragment>}
      </Select>
    );
  };

  const assignedCol = [
    {
      headerName: "Id",
      valueGetter: `${assignIDs}+node.rowIndex`,
      minWidth: 40,
      maxWidth: 80,
      pinned: 'left'
    },
    {
      headerName: "Name",
      field: "name",
    },
    {
      headerName: "Email Address",
      field: "email",
      minWidth: 250,
      maxWidth: 250,
    },
    {
      headerName: "Mobile Number",
      field: "mobile_no",
      minWidth: 170,
      maxWidth: 170,
    },
    {
      headerName: "Building(s)",
      field: "buildings",
      minWidth: 250,
      maxWidth: 250,
      cellRendererFramework: propertiesComponent,
    },
    {
      headerName: "Status",
      maxWidth: 150,
      field: "status",
      cellRendererFramework: statusRender,
    },
  ];

  const columnDefs = [
    {
      headerName: "Id",
      valueGetter: `${IDs}+node.rowIndex`,
      minWidth: 40,
      maxWidth: 80,
      pinned: 'left'
    },
    { headerName: "Name", field: "name" },
    { headerName: "Email Address", field: "email" },
    { headerName: "Mobile Number", field: "mobile_no" },
    {
      headerName: "Building(s)",
      field: "buildings",
      cellRendererFramework: propertiesComponent,
    },
    {
      headerName: "Status",
      maxWidth: 130,
      field: "status",
      cellRendererFramework: statusRender,
    },
  ];

  function statusRender(params) {
    const check = params.data.application_user_status[0]?.is_active;
    return (
      <Button
        type="link"
        className="p-0"
        onClick={() => approveFacilityManager(params)}
        style={{ color: check ? "#1C5D3B" : "#FB7171" }}
      >
        {check ? "Active" : "In Active"}
      </Button>
    );
  }

  const approveFacilityManager = (item: any) => {
    let popName;
    if (item.data.buildings.length > 0) {
      popName = 'assign'
    }
    else { popName = 'unassign' }
    return popupConfirm({
      title: `Would you like to change the status of ${item.data.name} as a Supervisors?`,
      okText: "Confirm",
      okType: "danger",
      onOk() {
        console.log({ item })
        const url = `${adminService}/admin/updateUserActiveStatus`;
        const check = item.data.application_user_status[0]?.is_active;
        try {
          axios
            .put(
              url,
              {
                id: item.data.application_user_status[0]?.id,
                isActive: check ? false : true,
              },
              { headers: { application: 4 } },
            )
            .then((res) => {
              getList();
            });
        } catch (e) {
          console.log(e);
        }
      },
    });
  };


  const popup = [
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Add New Supervisor
        </Title>
      ),
      content: (
        <AddSupervisor
          page={page}
          onClose={onClose}
          setOnClose={setOnClose}
          onCancel={() => setVisible(false)}
          buildings={buildings.map((index) => ({
            label: index.name,
            value: index.id,
          }))}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Assign a Building
        </Title>
      ),
      content: (
        <AddBuilding
          page={page}
          partyData={supervisorRowData}
          buildings={buildings.map((index) => ({
            label: index.name,
            value: index.id,
          }))}
          onClose={onClose} setOnClose={setOnClose}
          id={supervisorRowData}
          onCancel={() => setVisible(false)}
        />
      ),
      width: 650,
    },
  ];

  const onCancel = () => {
    setOnClose(true)
    setVisible(false)
  }

  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => onCancel(),
  };

  const addSupervisor = () => {
    setVisible(true);
    setTrigger(0);
  };
  return (
    <>
      <DashboardLayout>
        {/* <Row gutter={[24, 24]}>
          <Col span={24}>
            <BreadCrumb list={breadcrumbList} />
          </Col>
        </Row> */}

        <Row gutter={24}>
          <Col span={24}>
            <SectionHeader
              heading="Supervisors"
              total={tabKey === "2" ? assignedCount : unassignedCount}
            //addNewAction={addSupervisor}
            />
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Tabs
                  defaultActiveKey={tabKey}
                  onChange={(key) => setTabkey(key)}
                  tabBarExtraContent={searchField}
                  tabBarGutter={48}
                  className="ag-property-status"
                >
                  <TabPane tab="Unassigned" key="1">
                    {unassignedList && (
                      <>
                        <Grid
                          data={unassignedList}
                          columns={columnDefs}
                          pagination={false}
                          defaultSettings={gridProps}
                          filterGrid={searchText}
                          noRowlabel="Supervisors"
                        // noRowbtnAction={addTechnician}
                        />
                        <Row justify="end" className="mt-1">
                          <Pagination
                            className="ag-property-text-pagination"
                            current={page}
                            total={unassignedCount && unassignedCount}
                            onChange={fetchData}
                            pageSize={50}
                            showSizeChanger={false}
                          />
                        </Row>
                      </>
                    )}
                  </TabPane>
                  <TabPane tab="Assigned" key="2">
                    {supervisorList ? (
                      <>
                        <Grid
                          data={supervisorList}
                          columns={assignedCol}
                          pagination={false}
                          defaultSettings={gridProps}
                          filterGrid={searchText}
                          noRowlabel="Supervisors"
                        />
                        <Row justify="end" className="mt-1">
                          <Pagination
                            className="ag-property-text-pagination"
                            current={page2}
                            total={assignedCount && assignedCount}
                            onChange={fetchPagData}
                            showSizeChanger={false}
                            pageSize={50}
                          />
                        </Row>
                      </>
                    ) : (
                      <h1>no data</h1>
                    )}
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          </Col>
        </Row>
      </DashboardLayout>
      <Popup {...popupProps} />
    </>
  );
};

export default Supervisors;
