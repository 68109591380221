import { Button, Form, Input, Select, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DashboardLayout from "../../molecules/DashboardLayout";
import SectionHeader from "../../molecules/SectionHeader";
import { submitContactUs } from "./ducks/action";

const ContactUs = () => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const onSubmit = async (e) => {
    setLoading(true);

    e.type = location.state.type;
    e.device = "web";

    const response = await submitContactUs(e);
    if (response) {
      message.success("Request submitted Successfully");
      form.resetFields();
    }
    setLoading(false);
  };

  const reset = () => {
    form.resetFields();
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 80 }} defaultValue={"+971"}>
        <Option value="971" selected>
          +971
        </Option>
      </Select>
    </Form.Item>
  );

  return (
    <>
      <DashboardLayout load={loading}>
        <SectionHeader heading="Contact us" />
        <Form
          layout="vertical"
          name="userForm"
          onFinish={onSubmit}
          form={form}
          style={{ maxWidth: 800 }}
          validateMessages={validateMessages}
        >
          <Form.Item
            name="name"
            label="Landlord Name:"
            rules={[{ required: true }]}
          >
            <Input placeholder="Landlord Name" type="text" />
          </Form.Item>
          <br />
          <Form.Item
            name="email"
            label="Email:"
            rules={[
              {
                required: true,
                type: "email",
              },
            ]}
          >
            <Input placeholder="Email" type="text" />
          </Form.Item>
          <br />
          <Form.Item
            name="mobile"
            label="Mobile No.:"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input addonBefore={prefixSelector} style={{ width: "100%" }} />
          </Form.Item>
          <br />
          <Form.Item
            name="Comment"
            label="Comments:"
            rules={[{ required: true }]}
          >
            <TextArea rows={6} />
          </Form.Item>
          <br />
            {/* <Button
              type="link"
              // icon={<TeamOutlined />}
              className="p-0 h-auto property_card_btn"
              style={{ width: 100 }}
              onClick={reset}
            >
              Reset
            </Button>
            &nbsp; */}
            <Button
              type="primary"
              // icon={<TeamOutlined />}
              className="p-0 h-auto property_card_btn"
              htmlType="submit"
              style={{ width: "auto" }}
            >
              Submit
            </Button>
        </Form>
      </DashboardLayout>
    </>
  );
};

export default ContactUs;
