import React, { Children } from 'react';
import {
  Layout,
  Breadcrumb
} from "antd";

import Sidebar from "../Sidebar";
import Header from "../Header";
import Footer from "../Footer";
import { NavHeader } from '../NavHeader';
const { Content } = Layout;

const DashboardLayout = props => {
  return (
    <Layout className="main-layout">
      <Header />
   
      <Layout>
    
      
      <NavHeader />
        <Layout className='layout-space'>
          <Content className='main-content'>
            {props.children}
            <Footer />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
