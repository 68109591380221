
import React from 'react';
import Icon from '@ant-design/icons';

const FilterSvg = () => {
  return (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 64 64">
      <path d="M61.5,8H23.4c-1-3.1-4-5.4-7.5-5.4S9.5,4.9,8.5,8h-6C1.1,8,0,9.2,0,10.5c0,1.4,1.1,2.5,2.5,2.5h6c1,3.1,4,5.4,7.5,5.4
	c3.5,0,6.4-2.2,7.5-5.4h38.1c1.4,0,2.5-1.1,2.5-2.5C64,9.2,62.9,8,61.5,8z M15.9,13.4c-1.6,0-2.9-1.3-2.9-2.9s1.3-2.9,2.9-2.9
	s2.9,1.3,2.9,2.9S17.5,13.4,15.9,13.4z M61.5,29.5h-6c-1-3.1-4-5.4-7.5-5.4c-3.5,0-6.4,2.2-7.5,5.4H2.5C1.1,29.5,0,30.6,0,32
	c0,1.4,1.1,2.5,2.5,2.5h38.1c1,3.1,4,5.4,7.5,5.4c3.5,0,6.4-2.2,7.5-5.4h6c1.4,0,2.5-1.1,2.5-2.5C64,30.6,62.9,29.5,61.5,29.5z
	 M48.1,34.9c-1.6,0-2.9-1.3-2.9-2.9c0-1.6,1.3-2.9,2.9-2.9c1.6,0,2.9,1.3,2.9,2.9C50.9,33.6,49.7,34.9,48.1,34.9z M61.5,50.9H34.1
	c-1-3.1-4-5.4-7.5-5.4c-3.5,0-6.4,2.2-7.5,5.4H2.5c-1.4,0-2.5,1.1-2.5,2.5C0,54.8,1.1,56,2.5,56h16.7c1,3.1,4,5.4,7.5,5.4
	c3.5,0,6.4-2.2,7.5-5.4h27.4c1.4,0,2.5-1.1,2.5-2.5C64,52.1,62.9,50.9,61.5,50.9z M26.6,56.3c-1.6,0-2.9-1.3-2.9-2.9
	c0-1.6,1.3-2.9,2.9-2.9c1.6,0,2.9,1.3,2.9,2.9C29.5,55,28.2,56.3,26.6,56.3z"/>
    </svg>
  );
};

const FilterIcon = props => <Icon component={FilterSvg} {...props} />

export default FilterIcon;