import React, { useState, useEffect } from "react";

import type { MenuProps } from "antd";
import { Popup } from "../../atoms/Popup";
import { BsPlusCircle } from "react-icons/bs";
import AddOrganization from "./AddOrganization";
import axios from "../../../utils/axiosInceptor";
import { useDispatch, useSelector } from "react-redux";
import LinkServiceProvider from "./LinkServiceProvider";
import SectionHeader from "../../molecules/SectionHeader";
import { adminService } from "../../../configs/constants";
import UpdateServiceProvider from "./UpdateServiceProvider";
import DashboardLayout from "../../molecules/DashboardLayout";
import {
  Col,
  Row,
  Space,
  Table,
  Switch,
  Button,
  message,
  Dropdown,
  Typography,
} from "antd";
import { useForm } from "react-hook-form";
import {
  getOrganizations,
  getAllOrganizations,
  getBusinessUnitsTypes,
} from "./ducks/actions";

import {
  EditOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";

const { Title } = Typography;
var _ = require("lodash");
let modalContent = "ALL";
let _closeExpand = false;

export default (props) => {
  const dispatch: any = useDispatch();
  const [selected, setSelected] = useState();
  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [updates, setUpates] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [expand, setExpand] = useState({
    type: null,
    indexes: [],
  });

  const organization = useSelector(
    (state: any) => state.organization.getOrganizations
  );

  const businessUnitTypes = useSelector(
    (state: any) => state.organization.businessUnitTypes
  );

  const {
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    dispatch(getAllOrganizations());
    dispatch(getBusinessUnitsTypes("businessUnitTypes"));
  }, []);

  useEffect(() => {
    const filterData = () => {
      setPage(1);
      dispatch(getOrganizations(1, search));
    };

    const debouncer = setTimeout(() => {
      filterData();
    }, 1500);
    return () => {
      clearTimeout(debouncer);
    };
  }, [search]);

  const onDropClick = (e, record) => {
    if (e.key === "0") modalContent = "BU";
    setSelected(record);
    setTrigger(+e.key);
    setVisible(true);
  };

  const onEdit = (record) => {
    modalContent = "USER";
    setVisible(true);
    setTrigger(1);
    setSelected(record);
  };

  const editUnit = (record) => {
    setVisible(true);
    setTrigger(3);
    setSelected(record);
  };

  const onAdd = () => {
    setSelected(undefined);
    setVisible(false);
    modalContent = "ALL";
    dispatch(getOrganizations(1, search));
    dispatch(getAllOrganizations());
  };

  const addNew = () => {
    modalContent = "ALL";
    setVisible(true);
    setTrigger(0);
  };

  const onChange = (checked: boolean, data: any) => {
    let status = "";

    switch (data.status) {
      case "enable":
        status = "disable";
        break;
      case "disable":
        status = "enable";
        break;
      default:
        status = "enable";
        break;
    }

    axios({
      url: `${adminService}/organization/${data.orgId}`,
      method: "PATCH",
      data: { status },
      headers: { application: 4 },
    })
      .then((response) => {
        message.success(response.data.message);
        dispatch(getOrganizations(page, search));
      })
      .catch((error) => {
        message.error(
          error.response.data?.error || error.response.data.message
        );
      });
  };

  const onTopSearch = (e) => {
    setSearch(e.target.value);
  };

  const items: MenuProps["items"] = [
    {
      label: "Business Unit",
      key: 0,
    },
    {
      label: "Service Provider",
      key: 2,
    },
  ];

  const columns: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
      width: 220,
      render: (_, record) => <p>{record?.user?.name}</p>,
    },
    {
      title: "Business Units",
      dataIndex: "bu",
      key: "bu",
      width: 130,
      render: (text, record, index) => {
        return record.businessUnits.length > 0 ? (
          <>
            <Row
              className="ant-table-item-with-icon"
              style={{
                background:
                  expand.type === "BU" &&
                  expand.indexes.includes(index) &&
                  "#ECF0F1",
              }}
            >
              {true && (
                <Button
                  type="link"
                  className="p-0 ag-success"
                  onClick={() => _handleExpandRow("BU", index)}
                >
                  <div className="Simple-tooltip">
                    {expand.type === "BU" && expand.indexes.includes(index) ? (
                      <MinusSquareOutlined
                        style={{ color: "#1C5D3B" }}
                        onClick={() => (_closeExpand = true)}
                      />
                    ) : (
                      <PlusSquareOutlined
                        style={{
                          color: "#1C5D3B",
                        }}
                      />
                    )}
                    <section className="tt-content">More</section>
                  </div>
                </Button>
              )}
              {record.businessUnits.length} BU
              {record.businessUnits.length > 1 ? "'s" : ""}
            </Row>
          </>
        ) : (
          <>
            <span className="ant-table-item-with-icon">–</span>
          </>
        );
      },
    },
    {
      title: "Service Provider",
      dataIndex: "sp",
      key: "sp",
      width: 130,
      render: (text, record, index) => {
        return record.serviceProvider.length > 0 ? (
          <>
            <Row
              className="ant-table-item-with-icon"
              style={{
                background:
                  expand.type === "SP" &&
                  expand.indexes.includes(index) &&
                  "#ECF0F1",
              }}
            >
              {true && (
                <Button
                  type="link"
                  className="p-0 ag-success"
                  onClick={() => _handleExpandRow("SP", index)}
                >
                  <div className="Simple-tooltip">
                    {expand.type === "SP" && expand.indexes.includes(index) ? (
                      <MinusSquareOutlined
                        style={{ color: "#1C5D3B" }}
                        onClick={() => (_closeExpand = true)}
                      />
                    ) : (
                      <PlusSquareOutlined style={{ color: "#1C5D3B" }} />
                    )}
                    <section className="tt-content">More</section>
                  </div>
                </Button>
              )}
              {record.serviceProvider.length} SP
              {record.serviceProvider.length > 1 ? "'s" : ""}
            </Row>
          </>
        ) : (
          <>
            <span className="ant-table-item-with-icon">–</span>
          </>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 200,
      render: (text, record) => {
        return (
          <Space size={16} className="ag-actions">
            {/* Edit button */}
            <Button
              type="link"
              className="p-0 ag-success"
              onClick={() => onEdit(record)}
            >
              <div className="Simple-tooltip">
                <EditOutlined />
                <section className="tt-content">Edit</section>
              </div>
            </Button>

            {/* Bu or SP button */}
            <Dropdown
              menu={{ items, onClick: (key) => onDropClick(key, record) }}
              trigger={["click"]}
            >
              <Button type="link" className="p-0 ag-success">
                <div className="Simple-tooltip">
                  <BsPlusCircle />
                  <section className="tt-content">Add</section>
                </div>
              </Button>
            </Dropdown>

            {/* Enable disable switch */}
            <div className="Simple-tooltip">
              <Switch
                className="switch"
                onChange={(e) => onChange(e, record)}
                defaultChecked={record?.status === "enable" ? true : false}
              />
              <section className="tt-content">{record.status}</section>
            </div>
          </Space>
        );
      },
    },
  ];

  const _handleExpandRow = (type: string, index: number, action?: string) => {
    let _expand = { ...expand };

    if (_closeExpand) {
      _closeExpand = false;
      _expand.type = null;
      _expand.indexes = [];
      return setExpand(_expand);
    }

    if (_expand.indexes.length === 0) {
      _expand.type = type;
      _expand.indexes.push(index);
    } else {
      _expand.type = type;
      _expand.indexes.pop();
      _expand.indexes.push(index);
    }

    setExpand(_expand);
  };

  const _modalTitle = (type: string) => {
    let title = "";
    switch (type) {
      case "ALL":
        title = "Add New Organization";
        break;
      case "USER":
        title = "Edit Organization";
        break;
      case "BU":
        title = "Add Business Unit";
        break;
      case "EDIT_BU":
        title = "Edit Business Unit";
        break;
      default:
        break;
    }

    return title;
  };

  const popup = [
    {
      title: (
        <Title level={3} className="m-0">
          {_modalTitle(modalContent)}
        </Title>
      ),
      content: (
        <AddOrganization
          onSubmit={onAdd}
          selected={selected}
          onUpdate={setUpates}
          onchangeUpdate={updates}
          modalContent={modalContent}
          onCancel={() => {
            modalContent = "ALL";
            setSelected(undefined);
            setVisible(false);
          }}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="m-0">
          {_modalTitle(modalContent)}
        </Title>
      ),
      content: (
        <AddOrganization
          editMode={true}
          onSubmit={onAdd}
          selected={selected}
          onUpdate={setUpates}
          onchangeUpdate={updates}
          modalContent={modalContent}
          onCancel={() => {
            modalContent = "ALL";
            setSelected(undefined);
            setVisible(false);
          }}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="m-0">
          Link Service Provider
        </Title>
      ),
      content: (
        <LinkServiceProvider
          onSubmit={onAdd}
          selected={selected}
          onUpdate={setUpates}
          onchangeUpdate={updates}
          modalContent={modalContent}
          onCancel={() => {
            // modalContent = "ALL";
            setSelected(undefined);
            setVisible(false);
          }}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="m-0">
          Update Service Provider
        </Title>
      ),
      content: (
        <UpdateServiceProvider
          onSubmit={onAdd}
          selected={selected}
          onUpdate={setUpates}
          onchangeUpdate={updates}
          modalContent={modalContent}
          onCancel={() => {
            // modalContent = "ALL";
            setSelected(undefined);
            setVisible(false);
          }}
        />
      ),
      width: 650,
    },
  ];

  const popupProps = {
    closable: true,
    visibility: visible,
    className: "sp-link-form",
    title: popup[trigger].title,
    width: popup[trigger].width,
    content: popup[trigger].content,
    onCancel: () => {
      modalContent = "ALL";
      setSelected(undefined);
      setVisible(false);
    },
  };

  const _handleBusinessUnitType = (type: string) => {
    let value = "";
    for (let key in businessUnitTypes)
      if (type === key) value = businessUnitTypes[key];

    return value;
  };

  const _unAssignSP = async (data: object) => {
    axios({
      data,
      method: "POST",
      headers: { application: 4 },
      url: `${adminService}/maintenance/serviceprovider/unassign`,
    })
      .then((response: any) => {
        message.success(response.data.message);
        dispatch(getOrganizations(page, search));
      })
      .catch((error: any) => {
        message.error(
          error.response.data?.error || error.response.data.message
        );
      });
  };

  const _expandedRowRender = (record: any) => {
    if (expand.type === "BU")
      return _.map(record.businessUnits, (unit) => (
        <>
          <div className="org_display_data org_data">
            <p>Business Name</p>
            <p>Contact Persone Name</p>
            <p>Email</p>
            <p>Mobile</p>
            <p>Edit</p>
          </div>
          <div row-key={unit.businessUnitName}>
            <div className="org_display_data">
              <p>{unit.businessUnitName}</p>
              <p>{unit.contactPersonName}</p>
              <p>{unit.email}</p>
              <p>{unit.mobile}</p>
              <p>
                <Button
                  type="link"
                  className="p-0 ag-success"
                  onClick={() => {
                    modalContent = "EDIT_BU";
                    setVisible(true);
                    setTrigger(1);
                    setSelected(unit);
                  }}
                >
                  <div className="Simple-tooltip">
                    <EditOutlined />
                    <section className="tt-content">Edit</section>
                  </div>
                </Button>
              </p>
            </div>
          </div>
          <br />
        </>
      ));

    if (expand.type === "SP")
      return _.map(record.serviceProvider, (unit) => (
        <>
          <div className="org_display_data org_data">
            <p>Service Provider Name</p>
            <p>Action</p>
          </div>
          <div row-key={unit.serviceProviderName}>
            <div className="org_display_data">
              <p>{unit.serviceProviderName}</p>
              <p className="status_checkbox">
                <Space size={16} className="ag-actions">
                  {/* Delete Button */}
                  <Button
                    type="link"
                    className="p-0 ag-success"
                    onClick={() => {
                      modalContent = "ALL";
                      setVisible(true);
                      setTrigger(3);
                      setSelected({ ...unit, orgId: record?.orgId });
                    }}
                  >
                    <div className="Simple-tooltip">
                      <EditOutlined />

                      <section className="tt-content">Edit</section>
                    </div>
                  </Button>

                  {/* Enable disable switch */}
                  <div className="Simple-tooltip">
                    <Switch
                      className="switch"
                      defaultChecked={unit?.isAssigned}
                      onChange={(e) =>
                        _unAssignSP({
                          isAssigned: e,
                          orgId: record.orgId,
                          serviceProviderId: unit.serviceProviderId,
                        })
                      }
                    />
                    <section className="tt-content">
                      {unit?.isAssigned ? "Linked" : "Unlinked"}
                    </section>
                  </div>

                  {/* Delete Button */}
                  {/* <Button
                  type="link"
                  className="p-0 ag-light-red"
                  onClick={() => _unLinkSP({ ...unit, orgId: record.orgId })}
                >
                  <div className="Simple-tooltip">
                    <DeleteOutlined />
                    <section className="tt-content">Unlink</section>
                  </div>
                </Button> */}

                  {/* Check Box */}
                  <Col>
                    <Button
                      className="stop_assign"
                      disabled={unit.isStopAssignment}
                      onClick={() =>
                        _unAssignSP({
                          orgId: record.orgId,
                          isStopAssignment: true,
                          serviceProviderId: unit.serviceProviderId,
                        })
                      }
                    >
                      Stop Assignment
                    </Button>
                  </Col>
                </Space>
              </p>
            </div>
          </div>
          <br />
        </>
      ));
  };

  return (
    <>
      <DashboardLayout>
        <SectionHeader
          search={onTopSearch}
          heading="Organizations"
          addNewAction={addNew}
        />
        <Row gutter={24}>
          <Col span={24}>
            <Table
              columns={columns}
              rowKey={(record, index) => index}
              className="custom-table org_table_wrap organizations"
              expandable={{
                showExpandColumn: false,
                expandedRowKeys: [
                  expand.indexes.length > 0 && expand.indexes[0],
                ],
                expandedRowRender: (record) => _expandedRowRender(record),
                rowExpandable: (record) => record.businessUnits.length > 0,
                expandIcon: () => (
                  <span
                    className="m-0 p-0"
                    style={{
                      display: "none",
                    }}
                  />
                ),
              }}
              pagination={{
                current: page,
                position: ["topLeft"],
                showSizeChanger: false,
                pageSize: organization?.perPage ?? 10,
                className: "ag-property-text-pagination mb-1",
                onChange: (e) => {
                  setPage(e);
                  dispatch(getOrganizations(e, search));
                },
                total: organization?.totalCount,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} Organizations`,
              }}
              dataSource={organization?.data}
              scroll={{ y: 500 }}
            />
          </Col>
        </Row>
      </DashboardLayout>
      <Popup {...popupProps} />
    </>
  );
};
