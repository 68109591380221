import { Button, Col, Row, Space, Spin, message, Typography, Divider  } from "antd";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "../../../../utils/axiosInceptor";
import { propertyService } from "../../../../configs/constants";
import { BsArrowCounterclockwise } from "react-icons/bs";
import { SaveOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const { Text } = Typography;

const UpdateProperty = (props) => {

  return (
    <>
      <Row gutter={24} align="middle">
        <Col>
          <Divider /> 
        </Col>
        <Col xl={24} style={{ textAlign: 'center'}}>
          <Text strong>{`Do you want to update the property ?`}</Text>
        </Col>
        <Col>
          <Divider /> 
        </Col>
      </Row>

      <Row gutter={24} justify="space-between">
        <Col>
          <Button
            className="ag-error theme_grey_btn"
            htmlType="button"
            onClick={() => {
              props.onCancel();
            }}
          >
            {<BsArrowCounterclockwise />} Cancel
          </Button>
        </Col>
        <Col>
          <Button className="ag-green-button theme_btn" onClick={() => {
              props.onSubmit()
            }}>
            {<SaveOutlined />} Update
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default UpdateProperty;
