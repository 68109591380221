import axios from "../../../../utils/axiosInceptor";
import { ALL_SUBSCRIPTIONS, SUBSCRIPTIONS } from "./constants";
import { adminService } from "../../../../configs/constants";

//business units

export const getAllSubscriptions = () => async (dispatch) => {
  const url = `${adminService}/subscription/list`;
  const {
    data: { result },
  } = await axios.get(url);
  if (result) {
    dispatch({
      type: ALL_SUBSCRIPTIONS,
      data: result,
    });
  }
};

//subscriptions
export const getSubscriptions =
  (page = 1, filterdata?) =>
  async (dispatch) => {
    const url = `${adminService}/subscription?page=${page}&${
      filterdata ? `search=${filterdata}` : ""
    }`;
    const {
      data: { result },
    } = await axios.get(url);
    if (result) {
      dispatch({
        type: SUBSCRIPTIONS,
        data: result,
      });
    }
  };
