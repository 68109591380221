import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Typography, message, Space, Spin } from "antd";
import { useForm } from "react-hook-form";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { DateField, InputField, SelectField } from "../../../atoms/FormElement";
import { adminService } from "../../../../configs/constants";
import axios from "../../../../utils/axiosInceptor";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { getAllOrganizations } from "../../Organizations/ducks/actions";
import { getAllPlans } from "../../Plans/ducks/actions";
import moment from "moment";
import { numberWithCommas } from "../../../../utils/helper";
import { BsPlusCircle, BsArrowCounterclockwise } from "react-icons/bs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advanced from "dayjs/plugin/advancedFormat";

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advanced);

const { Paragraph } = Typography;

const defaultValues: any = {
  name: "",
  description: "",
  orgId: "",
  planId: "",
  numberOfUsers: 0,
  perUserPrice: 0,
  numberOfUnits: 0,
  perUnitPrice: 0,
  unitsSubscribe: null,
};

export default (props) => {
  const dispatch: any = useDispatch();
  const organizationList = useSelector(
    (state: any) => state.organization.getOrganizationsDrop
  );
  const planList = useSelector((state: any) => state.plans.getPlansDrop);
  const { onUpdate, onchangeUpdate } = props;
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [total, setTotal] = useState(null);
  const [plan, setPlan] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(dayjs());
  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    if (props?.selected) {
      _setPlan(props?.selected?.planName);
      // _calculateTotal();
    }
  }, [planList]);

  useEffect(() => {
    if (props?.selected) {
      _setPlan(props?.selected?.planName);
      // _calculateTotal();
    }
    return () => {
      reset();
      setPlan(null);
      setTotal(null);
      defaultValues.perUserPrice = 0;
      defaultValues.perUnitPrice = 0;
      defaultValues.unitsSubscribe = 0;
      defaultValues.numberOfUsers = 0;
    };
  }, [props]);

  useEffect(() => {
    dispatch(getAllPlans());
    dispatch(getAllOrganizations());
    if (props.selected) {
      defaultValues.numberOfUsers = props.selected.numberOfUser;
      defaultValues.unitsSubscribe = props.selected.unitSubscribed;
      setValue("organisationName", props.selected.organisationName, {
        shouldValidate: true,
      });
      setValue("planName", props.selected.planName, {
        shouldValidate: true,
      });
      _setPlan(props.selected.planName);
      setValue("numberOfUser", props.selected.numberOfUser, {
        shouldValidate: true,
      });
      setValue("unitSubscribed", props.selected.unitSubscribed, {
        shouldValidate: true,
      });
      // setValue("existingtUnits", props.selected.unitSubscribed, {
      //   shouldValidate: true,
      // });
      setValue("unit", props.selected.unit, { shouldValidate: true });
      setValue("user", props.selected.user, { shouldValidate: true });
      setValue("startDate", dayjs(props.selected.startDate), {
        shouldValidate: true,
      });
      setStartDate(dayjs(props.selected.startDate));
      setValue("endDate", dayjs(props.selected.endDate), {
        shouldValidate: true,
      });
      setEndDate(dayjs(props.selected.endDate).subtract(1, "days"));
      defaultValues.unitsSubscribe = props.selected.unitSubscribed;
    } else {
      reset();
      setTotal(null);
    }
  }, [props.selected, props]);

  const _setPlan = (planName: string) => {
    const plan = planList?.find((item) => item.label === planName);
    setPlan({
      perUserPrice: plan?.perUserPrice,
      perUnitPrice: plan?.perUnitPrice,
    });
    // _calculateTotal(props.selected.unitSubscribed);
    return plan;
  };

  const onFinish = (values) => {
    props.onSubmit();
    reset();
    setLoader(true);
    setSubmited(true);
    let postJson;

    if (props.selected?.subscriptionId) {
      postJson = {
        endDate: values.endDate,
        startDate: values.startDate,
        numberOfUser: parseInt(values.numberOfUser),
        unitSubscribed: parseInt(values.unitSubscribed),
      };

      const url = `${adminService}/subscription/${props.selected.subscriptionId}`;
      axios
        .patch(url, postJson, { headers: { application: 4 } })
        .then((response) => {
          setLoader(false);
          setSubmited(false);
          reset();
          message.success(response.data.message);
          onUpdate(!onchangeUpdate);
          props.onSubmit();
        })
        .catch((error) => {
          message.error(
            error.response.data?.error || error.response.data.message
          );
          setLoader(false);
          setSubmited(false);
          reset();
        });
    } else {
      postJson = {
        endDate: values.endDate,
        orgId: defaultValues.orgId,
        startDate: values.startDate,
        planId: defaultValues.planId,
        numberOfUser: parseInt(values.numberOfUser),
        unitSubscribed: parseInt(values.unitSubscribed),
      };

      const url = `${adminService}/subscription`;

      axios
        .post(url, postJson, { headers: { application: 4 } })
        .then((response) => {
          setLoader(false);
          setSubmited(false);
          reset();
          message.success(response.data.message);
          onUpdate(!onchangeUpdate);
          props.onSubmit();
        })
        .catch((error) => {
          console.log(error);
          message.error(
            error.response?.data?.error || error.response.data.message
          );
          setLoader(false);
          setSubmited(false);
          reset();
        });
    }
  };

  const onChangeOrganzation = (e, type) => {
    if (type === "ORGANIZATION") {
      defaultValues.orgId = e.value;
      // defaultValues.numberOfUsers = e.numberOfUsers;
      // defaultValues.numberOfUnits = e.numberOfUnits;
      // setValue("numberOfUser", e.numberOfUsers, {
      //   shouldValidate: true,
      // });
    }

    if (type === "PLAN") {
      defaultValues.planId = e.value;
      defaultValues.perUserPrice = e.perUserPrice;
      defaultValues.perUnitPrice = e.perUnitPrice;
      setPlan({
        perUserPrice: e.perUserPrice,
        perUnitPrice: e.perUnitPrice,
      });
    }
  };

  useEffect(() => {
    _calculateTotal();
  }, [plan]);

  const _calculateTotal = () => {
    let amount = 0;
    if (plan && defaultValues.numberOfUsers && defaultValues.unitsSubscribe) {
      amount =
        defaultValues.numberOfUsers * plan?.perUserPrice +
          defaultValues.unitsSubscribe * plan?.perUnitPrice || 0;
    }
    setTotal(amount ? amount + " AED" : null);
    return `AED ${numberWithCommas(amount)}` || "";
  };

  const PlanLabel = () => {
    const style = {
      margin: 0,
      color: "gray",
      fontSize: "9px",
      fontStyle: "italic",
      fontWeight: "bold",
    };
    return (
      <div
        style={{
          margin: 0,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          lineBreak: "strict",
        }}
      >
        <p style={{ margin: "0 3px 0 0" }}>Plan </p>
        {plan?.perUserPrice && plan?.perUnitPrice && (
          <>
            <p style={style}>
              <span style={{ paddingRight: "3px" }}>
                {`Per User Price: AED ${plan?.perUserPrice}.`}
              </span>
            </p>
            <p style={style}>Per Unit Price: AED {plan?.perUnitPrice}</p>
          </>
        )}
      </div>
    );
  };

  const TotalLabel = () => {
    return (
      <div
        style={{
          margin: 0,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p style={{ margin: "0 3px 0 0" }}>Subscribe Units </p>
        <p
          style={{
            margin: 0,
            color: "gray",
            fontSize: "9px",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          {"Total: " + _calculateTotal()}
        </p>
      </div>
    );
  };

  // const minValue: any = props.editMode
  //   ? props.selected.unitSubscribed
  //   : defaultValues.numberOfUnits;

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          <Row gutter={24}>
            <Col span={12}>
              <SelectField
                isRequired={true}
                fieldname="organisationName"
                label="Organization"
                control={control}
                validate={errors.organisationName && "error"}
                validMessage={
                  errors.organisationName && errors.organisationName.message
                }
                onChange={(e) => onChangeOrganzation(e, "ORGANIZATION")}
                iProps={{
                  placeholder: props.editMode
                    ? props.selected.organisationName
                    : "Select Organization",
                  isDisabled: props.editMode,
                }}
                rules={{ required: "Please select organization" }}
                initValue={
                  props.editMode ? props.selected.organisationName : ""
                }
                selectOption={organizationList}
              />
            </Col>
            <Col span={12}>
              <SelectField
                isRequired={true}
                control={control}
                fieldname="planName"
                label={<PlanLabel />}
                ariaLiveMessages={true}
                validate={errors.planName && "error"}
                validMessage={errors.planName && errors.planName.message}
                onChange={(e) => onChangeOrganzation(e, "PLAN")}
                iProps={{
                  placeholder: props.editMode
                    ? props.selected.planName
                    : "Select Plan",
                  isDisabled: props.editMode,
                }}
                rules={{ required: "Please select plan" }}
                initValue={props.editMode ? props.selected.planName : ""}
                selectOption={planList}
                disabled={true}
              />
            </Col>

            {/* <Col span={12}>
              <InputField
                isRequired={true}
                fieldname="existingtUnits"
                label="Existing Units"
                control={control}
                rules={{
                  required: "Please enter number of units",
                }}
                iProps={{
                  placeholder: "Enter number of units",
                  type: "number",
                  disabled: true,
                  className: "ag-black",
                }}
                initValue=""
                validate={errors.existingtUnits && "error"}
                validMessage={
                  errors.existingtUnits && errors.existingtUnits.message
                }
              />
            </Col> */}
            <Col span={12}>
              <DateField
                control={control}
                isRequired={true}
                label="Start Date"
                fieldname="startDate"
                initValue={startDate}
                rules={{
                  required: "Please enter start date",
                }}
                validate={errors.startDate && "error"}
                onChange={(e) => {
                  setStartDate(e);
                  if (!endDate) {
                    // setEndDate(e);
                    setValue(
                      "endDate",
                      dayjs(e).add(12, "months").subtract(1, "days")
                    );
                  }
                }}
                validMessage={errors.startDate && errors.startDate.message}
                iProps={{
                  value: startDate,
                  format: "DD-MM-YYYY",
                  disabled: props.editMode,
                  placeholder: "DD-MM-YYYY",
                  disabledDate: (current) =>
                    current <= dayjs().subtract(1, "day"),
                }}
              />
            </Col>

            <Col span={12}>
              <DateField
                label="End Date"
                isRequired={true}
                control={control}
                fieldname="endDate"
                validate={errors.endDate && "error"}
                validMessage={errors.endDate && errors.endDate.message}
                onChange={(e) => {
                  setEndDate(e);
                  setValue("endDate", e);
                }}
                rules={{
                  required: "Please enter end date",
                }}
                initValue={
                  endDate ||
                  dayjs(startDate).add(12, "months").subtract(1, "days")
                }
                iProps={{
                  format: "DD-MM-YYYY",
                  disabled: props.editMode,
                  placeholder: "DD-MM-YYYY",
                  disabledDate: (current) =>
                    current <
                    dayjs(startDate).add(12, "months").subtract(1, "days"),
                  value:
                    endDate ||
                    dayjs(startDate).add(12, "months").subtract(1, "days"),
                }}
              />
            </Col>
            <Col span={12}>
              <InputField
                isRequired={true}
                fieldname="unitSubscribed"
                label={<TotalLabel />}
                control={control}
                rules={{
                  min: {
                    // value: minValue,
                    // message: "Value should be greater than existing units",
                    value: 1,
                    message: "Value should be greater than 0.",
                  },
                  pattern: new RegExp("^[0-9]{1,10}$"),
                  required: "Value should be greater than 0",
                }}
                iProps={{
                  type: "number",
                  placeholder: "Enter number of units",
                }}
                initValue=""
                validate={errors.unitSubscribed && "error"}
                validMessage={
                  errors.unitSubscribed && errors.unitSubscribed.message
                }
                onChange={(e) => {
                  defaultValues.unitsSubscribe = e;
                  if (+defaultValues.numberOfUsers > 0) {
                    _calculateTotal();                    
                  }
                }}
              />
            </Col>
            <Col span={12}>
              <InputField
                control={control}
                isRequired={true}
                label="Number of Users"
                fieldname="numberOfUser"
                validate={errors.numberOfUser && "error"}
                validMessage={
                  errors.numberOfUser && errors.numberOfUser.message
                }
                rules={{
                  min: {
                    value: 1,
                    message: "Value should be greater than 0.",
                  },
                  pattern: new RegExp("^[0-9]{1,10}$"),
                  required: "Value should be greater than 0",
                }}
                initValue=""
                onChange={(e) => {
                  setValue("numberOfUser", e);
                  defaultValues.numberOfUsers = e;
                  _calculateTotal();
                }}
                iProps={{
                  type: "number",
                  className: "ag-black",
                  placeholder: "Enter number of users",
                }}
              />
            </Col>
            {/* <Col span={24} className="heading-bg">
              {total && (
                <h4>
                  Total Amount: <h5>{total || 0}</h5>
                </h4>
              )}
            </Col> */}
          </Row>
          <Row gutter={24} justify="space-between">
            <Col>
              <Button
                className="ag-error theme_grey_btn"
                htmlType="button"
                onClick={() => {
                  !props.selected && reset();
                  props.onCancel();
                }}
              >
                {<BsArrowCounterclockwise />} Back
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button theme_btn" htmlType="submit">
                {<BsPlusCircle />} {props.selected ? "Update" : "Add"}
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  {<BsArrowCounterclockwise />} Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </Form>
  );
};
