import React, { useState, useEffect } from "react";

import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import axios from "../../../../../utils/axiosInceptor";
import { getMaintenanceSubTypes, getMaintenanceTypes } from "../../ducks/actions";
import { configTimeSlot } from "../../../../../utils/helper";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { adminService } from "../../../../../configs/constants";
import { BsPlusCircle, BsArrowCounterclockwise } from "react-icons/bs";
import { InputField, SelectField } from "../../../../atoms/FormElement";
import { Row, Col, Button, Form, Typography, message, Space, Spin } from "antd";

const defaultValues: any = {
  name: "",
  assets: "",
};
const { Paragraph } = Typography;

const AddSubType = (props) => {
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  const dispatch: any = useDispatch();

  useEffect(() => {
    return () => {
      reset();
    };
  }, [props]);

  useEffect(() => {
    reset({ defaultValues });
    props?.setOnClose(false);
  }, [props?.onClose]);

  const onFinish = async (param) => {
    setLoader(true);
    setSubmited(true);

    try {
      let url = `${adminService}/maintenance/subcategory/${param.assets.value}`;
      let postData = {
        name: String(param.name),
        timeInMin: param.timeInMin.value,
      };

      await axios.post(url, postData, { headers: { application: 4 } });
      reset();
      setLoader(false);
      setSubmited(false);
      message.success("Maintenance Sub-Type added successfully");
      dispatch(getMaintenanceTypes());
      props.setVisible(false);
    } catch (error) {
      reset();
      setLoader(false);
      setisError(false);
      setSubmited(false);
      message.error(error.response.data?.error || error.response.data.message);
    }
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          <Row gutter={24}>
            <Col span={8}>
              <SelectField
                isRequired={true}
                fieldname="assets"
                label="Select Category"
                control={control}
                iProps={{ placeholder: "Select Category" }}
                rules={{ required: "Please select category" }}
                initValue=""
                selectOption={props?.category?.map((item) => ({
                  label: item.title,
                  value: item.categoryId,
                }))}
                validate={errors.assets && "error"}
                validMessage={errors.assets && errors.assets.message}
              />
            </Col>
            <Col span={8}>
              <InputField
                isRequired={true}
                fieldname="name"
                label="Add Sub-Category Title"
                control={control}
                rules={{
                  required: "Please enter category title",
                }}
                iProps={{ placeholder: "Plumbing" }}
                initValue=""
                validate={errors.name && "error"}
                validMessage={errors.name && errors.name.message}
              />
            </Col>
            <Col span={8}>
              <SelectField
                control={control}
                isRequired={true}
                fieldname="timeInMin"
                selectOption={configTimeSlot}
                label="Choose time in Minutes"
                validate={errors.timeInMin && "error"}
                iProps={{ placeholder: "Choose time slot" }}
                rules={{ required: "Please select the time slot" }}
                validMessage={errors.timeInMin && errors.timeInMin.message}
              />
            </Col>
          </Row>
          <Row gutter={24} justify="space-between">
            <Col>
              <Button
                className="ag-error theme_grey_btn"
                htmlType="button"
                onClick={() => {
                  reset({ defaultValues });
                  props.setVisible(false);
                }}
              >
                {<BsArrowCounterclockwise />} Back
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button theme_btn" htmlType="submit">
                {<BsPlusCircle />} {props.selected ? "Update" : "Add"}
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </Form>
  );
};

export default AddSubType;