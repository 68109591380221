import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import { adminService, paidApiUrl } from "../../../../configs/constants";
// const assignedBaseURL = `${adminService}/admin/getAllAssignedTechnicians`;
// const unassignedBaseURL = `${adminService}/admin/getAllUnassignedTechnicians`;
const zoneUrl = `${adminService}/admin/getAllZones`;
const unAssignedURL = `${paidApiUrl}/admin/getAllUnassignedTechnicians?pageSize=50&page=`;
const assignedURL = `${paidApiUrl}/admin/getAllAssignedTechnicians?pageSize=50&page=`;

export const getAssignedList = (token, page = 1) => {
  return async (dispatch) => {
    try {
      let data = {};
      await fetch(assignedURL + page, {
        method: "GET",
        headers: {
          Authorization: `${token?.token}`,
        },
      })
        .then((response) => response.json())
        .then((rest) => {
          data = rest.result;
        });
      dispatch({
        type: action_types.GET_TECH_ASSIGNED_LIST,
        data: data,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getUnAssignedList = (token, page = 1) => {
  return async (dispatch) => {
    try {
      let data = {};
      await fetch(unAssignedURL + page, {
        method: "GET",
        headers: {
          Authorization: `${token?.token}`,
        },
      })
        .then((response) => response.json())
        .then((rest) => {
          data = rest.result;
        });
      dispatch({
        type: action_types.GET_TECH_UNASSIGNED_LIST,
        data: data,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getZones = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(zoneUrl);
      dispatch({
        type: action_types.GET_ZONE_LIST,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};
