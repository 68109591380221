import { Button, Col, Row, Space, Input, Form, Typography, Divider  } from "antd";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "../../../../utils/axiosInceptor";
import { propertyService } from "../../../../configs/constants";
import { BsArrowCounterclockwise } from "react-icons/bs";
import { SaveOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const { Text } = Typography;

const RejectReason = (props) => {
  const [reason, setReason] = useState('');
  const [property, setProperty] = useState(props.selected);
  const [isError, setisError] = useState(false);

  const onFinish = () => {
    props.onSubmit(reason)
    setReason('')
  };

  const addText = (e) => {
    setReason(e.target.value)
  }

  return (
    <>
      <Row gutter={24} justify="space-between">
        <Col span={20}>
          <Form
          >
            <Form.Item>
              <Input
                value={reason}
                bordered={true}
                placeholder="Reason for reject"
                onChange={addText}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <Row gutter={24} justify="space-between">
        <Col>
          <Button
            className="ag-error theme_grey_btn"
            htmlType="button"
            onClick={() => {
              props.onCancel();
            }}
          >
            {<BsArrowCounterclockwise />} Cancel
          </Button>
        </Col>
        <Col>
          <Button className="ag-green-button theme_btn" onClick={() => {
              onFinish()
            }}>
            {<SaveOutlined />} Submit
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default RejectReason;
