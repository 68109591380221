import { ALL_SUBSCRIPTIONS, SUBSCRIPTIONS } from './constants'

const initialState = {
    getSubscriptions: [],
    getAllSubscriptions: [],
}
export default (state = initialState, action) => {
    const { type, data } = action
    switch (type) {
        case SUBSCRIPTIONS: return { ...state, getSubscriptions: data }
        case ALL_SUBSCRIPTIONS: return { ...state, getAllSubscriptions: data }       
        default: return state

    }
}