import { PROPERTIES, PROPERTY, PROPERTY_UPDATED } from "./constants";
var _ = require("lodash");

const initialState = {
  getLandlordProperties: [],
  property: {}
};

export default (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case PROPERTIES:
      return { ...state, getLandlordProperties: data };
    case PROPERTY:
      return { ...state, getLandlordProperty: data };
    case PROPERTY_UPDATED:
      return { ...state, updateLandlordProperty: data };
  

    default:
      return state;
  }
};
