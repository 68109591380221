import * as React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { authService } from "../../../configs/constants";
import {
  Row,
  Col,
  Typography,
  Form,
  Select,
  Button,
  Popover,
  Progress,
  message,
  Tabs,
} from "antd";
import { useForm } from "react-hook-form";
import axios from "../../../utils/axiosInceptor";
import LoginTemplate from "../Login/LoginTemplate";
import TermsAndCondition from "./components/TermsAndCondition";
import { Popup } from "../../atoms/Popup";
import { setCookie } from "../../../utils/helper";
import { InputField, SelectNormal } from "../../atoms/FormElement";
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";
import { SITEKEY } from "../../../configs/constants";

const { TabPane } = Tabs;
export const SignUp = () => {
  const history = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [tokenCapcha, setReCapcha] = React.useState();
  const [visible, setVisible] = React.useState(false);
  const [tabkey, setTabkey] = React.useState("1");
  const refReCaptcha = React.useRef(null);
  const dispatch:any = useDispatch();
  const {
    control,
    handleSubmit, formState: { errors },
    watch,
    formState,
    getValues,
    reset,
  } = useForm({ mode: "onChange" });
  const { isValid } = formState;
  let checkPassword = watch("password");

  const { Title, Paragraph, Text } = Typography;

  const verifyCallback = (token) => {
    // console.log("recaptch", token);
    setReCapcha(token);
  };

  React.useEffect(() => {
    loadReCaptcha(SITEKEY);
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    const headers = {
      application: 5,
    };
    let postJson = {};
    if (values.email) {
      postJson = {
        username: values.email,
        recapcha: tokenCapcha,
      };
    } else {
      postJson = {
        username: values.phone,
        recapcha: tokenCapcha,
      };
    }

    const url = `${authService}/user/send-otp`;
    try {
      const response = await axios.post(url, postJson, { headers: headers });
      setLoading(false);
      console.log(response);
      message.success("OTP sent successfully!");
      history("/otp",{
        state: { userEmail: values.email ? values.email : values.phone },
      });
    } catch (e) {
      setLoading(false);
      refReCaptcha.current.execute();
      const { response } = e;
      //message.error(response?.data?.result?.error)
      setTimeout(() => {
        history("/register",{
          state: {
            userEmail: values.email || "",
            userPhone: values.phone || "",
          },
        });
      }, 2000);
    }
  };

  const prefixSelector = (
    <SelectNormal
      control={control}
      fieldname="prefix"
      initValue="971"
      noStyle
      selectOption={
        <>
          <Select.Option value="971">+971</Select.Option>
          <Select.Option value="92">+92</Select.Option>
        </>
      }
    />
  );

  const content = (
    <div>
      <Progress
        percent={30}
        size="small"
        status="exception"
        showInfo={false}
        style={{ width: "272px" }}
      />
      <ul className="ag-unordered-list">
        <li className="ag-success">One Upper Case letter</li>
        <li>One Lower Case letter</li>
        <li>One number</li>
      </ul>
    </div>
  );

  const popupProps = {
    closable: false,
    visibility: visible,
    content: (
      <TermsAndCondition
        onCancel={() => setVisible(false)}
        onAccept={() => setVisible(false)}
      />
    ),
    width: 1200,
    onCancel: () => setVisible(false),
  };

  return (
    <>
      <LoginTemplate load={loading}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Text className="defaultFont fontWeight600 green-color">
              Sign up with an account
            </Text>
          </Col>
          <Col span={24}>
            <Form name="normal_signup" onFinish={handleSubmit(onFinish)}>
              <Tabs activeKey={tabkey} onChange={(e) => setTabkey(e)}>
                <TabPane tab="Email Address" className="mt-2" key="1">
                  {tabkey === "1" && (
                    <InputField
                      isRequired={true}
                      fieldname="email"
                      control={control}
                      initValue=""
                      iProps={{ placeholder: "Email Address", size: "large" }}
                      rules={{
                        pattern: {
                          value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                          message: "Enter valid Email Address",
                        },
                        required: "Please enter Email Address",
                      }}
                      validate={errors.email && "error"}
                      validMessage={errors.email && errors.email.message}
                    />
                  )}
                </TabPane>
                <TabPane className="mt-2" tab="Mobile Number" key="2">
                  {tabkey === "2" && (
                    <InputField
                      isRequired={true}
                      fieldname="phone"
                      control={control}
                      validate={errors.phone && "error"}
                      validMessage={errors.phone && errors.phone.message}
                      initValue=""
                      rules={{
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Value should be a Number",
                        },
                        minLength: { value: 9, message: "Minimum 9 digits" },
                        maxLength: { value: 9, message: "Maximum 9 digits" },
                        required: "Please enter valid Mobile No.",
                        setValueAs: (value) =>
                          `+${getValues("prefix")}${value}`,
                      }}
                      iProps={{
                        size: "large",
                        addonBefore: prefixSelector,
                        placeholder: "Mobile Number",
                      }}
                    />
                  )}
                </TabPane>
              </Tabs>
              <Form.Item className="mb-0 mt-1 text-center">
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!isValid}
                  className="login-form-button"
                  size="large"
                >
                  Sign up
                </Button>
              </Form.Item>
              <ReCaptcha
                action="main"
                ref={refReCaptcha}
                sitekey={SITEKEY}
                verifyCallback={verifyCallback}
              />
            </Form>
          </Col>

          <Col span={24}>
            <Title level={5} className="text-center">
              Already have an account? <Link to="/login">Sign in here.</Link>
            </Title>
            <Paragraph className="copyright">Copyright © 2022</Paragraph>
          </Col>
        </Row>
      </LoginTemplate>
      <Popup {...popupProps} />
    </>
  );
};

export default SignUp;
