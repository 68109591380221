import { LANDLORDS } from "./constants";
var _ = require("lodash");

const initialState = {
  getIndividualLandlords: [],
};

export default (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case LANDLORDS:
      return { ...state, getIndividualLandlords: data };

    default:
      return state;
  }
};
