import axios from "../../../../utils/axiosInceptor";
import { adminService } from "../../../../configs/constants";
import * as action_types from "./constants";

const category = `${adminService}/maintenance/category`;
const subCategory = `${adminService}/maintenance/subcategory`;

export const getMaintenanceTypes = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(category);
      dispatch({
        type: action_types.GET_MAINTENANCE_TYPE,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getMaintenanceSubTypes = (categoryId: string) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(`${subCategory}/${categoryId}`);
      dispatch({
        type: action_types.GET_MAINTENANCE_SUB_TYPE,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};
