import React, { FC } from 'react'

import {Spin } from 'antd';

export const Loader = ({height}) => {
  return (
    <div 
    style={{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    height:height ? `${height}vh` : '70vh',
    margin:'auto',
    width:'100vw',
    backgroundColor:'white'
    }}>
      <div>
      <Spin style={{color:'#184461'}} size="large" />
      </div>
    </div>
   )
 }
